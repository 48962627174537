import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import {
  forwardRef,
  type ComponentPropsWithoutRef,
  type Ref,
  type ChangeEvent,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  Card,
  ConfiguredField,
  ConfiguredRadioGroup,
  requiredField,
} from "common/transaction_creation/v3/common";
import { OrganizationTransactionContactRoleType } from "graphql_globals";
import type { UseForm } from "common/transaction_creation/v3/form";
import type { Config } from "common/transaction_creation/v3/config";
import { SpacedMultipartFormRow } from "common/core/form/layout";
import { TextInput } from "common/core/form/text";
import { useController, useWatch } from "common/core/form";
import { type AriaRequired, emailPatternValidation } from "common/core/form/error";
import PhoneNumberInput from "common/form/inputs/phone/number";
import useTitleAgencyLookup from "common/mortgage/transactions/edit/title_agency_service";
import { useTitleAgencyAutocompleteService } from "common/mortgage/transactions/edit/title_collaborator/title_agency_autocomplete_service";
import Icon from "common/core/icon";
import { LENDER_TRANSACTION_TYPES } from "constants/transaction";

import { FORM_FIELDS, CONFIGS } from "..";
import ContactRoleSelectField from "./contact_role_field";
import { CardWrapper } from "../../common";
import RequirementsAndPermissionsFields from "./requirements_and_permissions_fields";
import { OrganizationSelection } from "./organization_selection";
import type {
  AdditionalContacts,
  AdditionalContacts_titleAgency as TitleOrg,
} from "../transaction_fragment.graphql";
import Styles from "./index.module.scss";

export const MESSAGES = defineMessages({
  title: {
    id: "a9edcad7-082e-4a32-ac39-227295ff7083",
    defaultMessage: "Additional contacts",
  },
  firstName: {
    id: "f58306b4-ef9d-40a3-8354-76fb310df4b0",
    defaultMessage: "First name",
  },
  lastName: {
    id: "0edbdeb4-8ff8-456d-88aa-9b46f072c139",
    defaultMessage: "Last name",
  },
  email: {
    id: "365ce26c-5824-4531-adb1-7e5af73ed01d",
    defaultMessage: "Email address",
  },
  phoneNumber: {
    id: "bae807d5-9e37-450a-83d0-53fc49fe7834",
    defaultMessage: "Phone number",
  },
  visibleToSigner: {
    id: "a792ed54-5c1d-4f2f-80b9-be2656149088",
    defaultMessage: "Visible to signer",
  },
  emailOrPhoneRequired: {
    id: "1be18a59-7e8e-4253-acb1-b42ef648accf",
    defaultMessage: "An email or phone number is required",
  },
});

export const COLLABORATOR_MESSAGES = defineMessages({
  title: {
    id: "20c2ee00-5d0a-4dd7-bc96-3bca916c40aa",
    defaultMessage: "Closing collaborator",
  },
  description: {
    id: "5a29055a-923b-47ec-b9a6-4d64b300a422",
    defaultMessage:
      "Closing collaborators can upload documents to the closing package and view completed documents.",
  },
  remove: {
    id: "2fcccbc7-5bf6-4ca8-9366-37cd34ee72ae",
    defaultMessage: "Remove closing collaborator {number}",
  },
  role: {
    id: "ec17f1af-fe3b-423f-b1b2-536e3e6b3774",
    defaultMessage: "Collaborator {number} role",
  },
  organizationName: {
    id: "09ba8822-9ace-4d84-9e29-51c70cd06b3a",
    defaultMessage: "Title agency or business",
  },
});

export const CONTACT_MESSAGES = defineMessages({
  title: {
    id: "c46286ed-75ed-40d6-a7fb-dec123be832b",
    defaultMessage: "Closing contact",
  },
  description: {
    id: "c5dd7b49-b8a5-40b5-b11d-31ce2b4d0aab",
    defaultMessage:
      "Closing contacts will be notified via email during every step of the signing process.",
  },
  remove: {
    id: "368edb51-4340-432c-8a2f-30f45f4c8363",
    defaultMessage: "Remove closing contact {number}",
  },
  role: {
    id: "01ff6c8a-c3cb-4870-bda3-0296415f7561",
    defaultMessage: "Contact role",
  },
  titleField: {
    id: "b7d40f18-93e7-4251-ba40-3f3cb00f98ad",
    defaultMessage: "Title",
  },
});

// Defining this here because ideally this is temporary and the hackathon will bring us
// a better phone number input that can handle an existing phone number
type ContactPhoneNumberProps = {
  control: UseForm["control"];
  index: number;
} & Omit<AriaRequired<ComponentPropsWithoutRef<"text">>, "aria-invalid">;

function ContactPhoneNumber(props: ContactPhoneNumberProps, ref: Ref<HTMLInputElement>) {
  const { control, index } = props;
  const {
    field: { onChange, onBlur, value },
  } = useController({ control, name: `contacts.${index}.phoneNumber` });

  return (
    <PhoneNumberInput
      {...props}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      isInternational={false}
      useCoreFormInput
      useStyledInputs={false}
      ref={ref}
    />
  );
}

const ContactPhoneNumberWithRef = forwardRef(ContactPhoneNumber);

function useCollaboratorOrganizationInput({
  form,
  initialTitleAgency,
  isFullRon,
  recordingLocationStateId,
  titleAgentEmail,
}: {
  form: UseForm;
  initialTitleAgency: TitleOrg | null;
  isFullRon: boolean;
  recordingLocationStateId: string | null;
  titleAgentEmail: string;
}) {
  const fieldPrefix: "contacts.0" = `${FORM_FIELDS.contacts}.0`;
  const { control, setValue } = form;

  const [showCollaboratorFound, setShowCollaboratorFound] = useState(false);
  const { onEmailChange, titleAgentLookup } = useTitleAgencyLookup(titleAgentEmail);
  const {
    selectedTitleAgency,
    autocompleteLoading,
    autocompleteOptions,
    handleAutocompleteChange,
    handleTitleAgencyChange,
    setAutocompleteInput,
    setSelectedTitleAgency,
  } = useTitleAgencyAutocompleteService(
    initialTitleAgency,
    isFullRon,
    recordingLocationStateId ?? null,
  );

  const collaboratorEmail = useWatch({
    control,
    name: `${fieldPrefix}.email`,
  });

  function setTitleAgencyId(id: string | null) {
    setValue(`${fieldPrefix}.titleAgency`, id);
  }

  function setCollaboratorName(firstName: string | null, lastName: string | null) {
    setValue(`${fieldPrefix}.firstName`, firstName);
    setValue(`${fieldPrefix}.lastName`, lastName);
  }

  useEffect(() => {
    if (!titleAgentLookup) {
      setShowCollaboratorFound(false);
    } else if (titleAgentLookup.email) {
      setShowCollaboratorFound(true);
      setTitleAgencyId(titleAgentLookup.titleAgencyId ?? null);
      setAutocompleteInput(titleAgentLookup.titleAgencyName ?? "");
      setSelectedTitleAgency({
        id: titleAgentLookup.titleAgencyId ?? "",
        name: titleAgentLookup.titleAgencyName ?? null,
        address: {
          line1: titleAgentLookup.address?.line1 ?? "",
          city: titleAgentLookup.address?.city ?? "",
          state: titleAgentLookup.address?.state ?? "",
        },
        isSetup: {
          eligibilityComplete: titleAgentLookup.isSetup ?? false,
        },
      });

      if (titleAgentLookup.firstName && titleAgentLookup.lastName) {
        setCollaboratorName(titleAgentLookup.firstName, titleAgentLookup.lastName);
        setValue(`${fieldPrefix}.phoneNumber`, titleAgentLookup.phoneNumber ?? null);
        setValue(`${fieldPrefix}.organizationName`, null);
        setValue(`${fieldPrefix}.organizationAddress`, null);
      }
    }
  }, [titleAgentLookup]);

  const isSharedInboxEmail = useMemo(() => {
    return Boolean(
      titleAgentLookup &&
        !titleAgentLookup.error &&
        titleAgentLookup.inboxEmail?.toLowerCase() === collaboratorEmail?.toLowerCase(),
    );
  }, [titleAgentLookup]);

  return {
    autocompleteLoading,
    autocompleteOptions,
    isSharedInboxEmail,
    handleAutocompleteChange,
    handleTitleAgencyChange,
    onEmailChange,
    selectedTitleAgency,
    setAutocompleteInput,
    setSelectedTitleAgency,
    showCollaboratorFound,
    titleAgentLookup,
  };
}

export type CollaboratorOrganizationInputReturn = ReturnType<
  typeof useCollaboratorOrganizationInput
>;

export function CollaboratorCard({
  index,
  config,
  form,
  disabled,
  remove,
  transaction,
}: {
  index: number;
  config: Config;
  form: UseForm;
  disabled?: true;
  remove?: (index: number) => void;
  transaction: AdditionalContacts;
}) {
  const intl = useIntl();
  const { control, setValue } = form;
  const { contacts, recordingLocation, titleAgency, transactionType } = transaction;
  const collaboratorOrganizationInteraction = useCollaboratorOrganizationInput({
    form,
    isFullRon: [
      LENDER_TRANSACTION_TYPES.REFINANCE,
      LENDER_TRANSACTION_TYPES.PURCHASE_BUYER_LOAN,
    ].includes(transactionType!),
    initialTitleAgency: titleAgency,
    titleAgentEmail: contacts[0]?.email ?? "",
    recordingLocationStateId: recordingLocation?.usState.id ?? null,
  });
  const { isSharedInboxEmail, onEmailChange, showCollaboratorFound } =
    collaboratorOrganizationInteraction;
  const isPrimary = index === 0;
  const fieldNamePrefix = `${FORM_FIELDS.contacts}.${index}` as const;
  const automationPrefix = `pointsOfContact.${index}`;

  // For collab, if no contact is added in setup, we populate the form with an unremoveable
  // blank default collaborator. If an existing title agency is on the transaction at this point,
  // the user is not required to fill it out. However, if they fill out any parts of the collaborator,
  // they have to fill out all of the info to prevent partial info being sent back. Any other collaborator
  // they choose to add, they'll be required to fill out or remove, just like contact cards.
  const formTitleAgency = useWatch({ control, name: "titleAgency" });
  const primaryCollaboratorRequired = !isPrimary || !formTitleAgency ? undefined : false;
  const validateEmail = (value: unknown) => {
    if (isPrimary && !formTitleAgency && !value) {
      return false;
      // Do normal validation for every other instance
    } else if (
      primaryCollaboratorRequired === undefined &&
      requiredField(config, CONFIGS.contactEmail) &&
      !value
    ) {
      return false;
    }
    return true;
  };
  const collaboratorEmail = useWatch({ control, name: `${fieldNamePrefix}.email` });
  const validateCollaboratorName = (value: unknown) => {
    if (!isSharedInboxEmail && collaboratorEmail && !value) {
      return false;
    }

    return true;
  };

  return (
    <CardWrapper data-automation-id="point-of-contact">
      <Card
        iconName="work-outline"
        title={intl.formatMessage(COLLABORATOR_MESSAGES.title)}
        subtitle={intl.formatMessage(COLLABORATOR_MESSAGES.description)}
        removeButtonProps={
          remove
            ? {
                label: intl.formatMessage(COLLABORATOR_MESSAGES.remove, {
                  number: index + 1,
                }),
                onClick: () => {
                  remove(index);
                },
                disabled,
                "data-automation-id": `${automationPrefix}.remove`,
              }
            : undefined
        }
      >
        <ConfiguredRadioGroup
          aria-labelledby={intl.formatMessage(COLLABORATOR_MESSAGES.role, { number: index + 1 })}
          horizontal
          config={config}
          configField={CONFIGS.collaboratorRole}
          form={form}
          name={`${fieldNamePrefix}.role`}
          choiceChips={[
            {
              label: (
                <FormattedMessage
                  id="5b9c3e3d-0b0f-490b-b413-fac89f2d67a7"
                  defaultMessage="Title agent"
                />
              ),
              value: OrganizationTransactionContactRoleType.TITLE_AGENT,
              disabledOverride: disabled,
            },
            {
              label: (
                <FormattedMessage
                  id="adedae9c-3f5e-4d71-a29e-d62513004791"
                  defaultMessage="Escrow officer"
                />
              ),
              value: OrganizationTransactionContactRoleType.ESCROW_OFFICER,
              disabledOverride: disabled,
            },
            {
              label: (
                <FormattedMessage
                  id="47408fee-9ae0-4f60-ac31-d300fb9f937d"
                  defaultMessage="Attorney"
                />
              ),
              value: OrganizationTransactionContactRoleType.ATTORNEY,
              disabledOverride: disabled,
            },
          ]}
        />
        <ConfiguredField
          config={config}
          configField={CONFIGS.contactEmail}
          data-automation-id={`${automationPrefix}.poc-email-field`}
          form={form}
          registerOptions={{
            validate: validateEmail,
            onChange: (evt: ChangeEvent<HTMLInputElement>) => {
              const newValue = evt.target.value;
              setValue(`${fieldNamePrefix}.email`, newValue);
              if (isPrimary) {
                onEmailChange(newValue, index);
              }
            },
          }}
          requiredOverride={primaryCollaboratorRequired}
          name={`${fieldNamePrefix}.email`}
          label={intl.formatMessage(MESSAGES.email)}
          helperText={{
            text: isPrimary && showCollaboratorFound && (
              <FormattedMessage
                id="ca10d30e-aba4-4aa3-88d8-22cfb466d3bc"
                defaultMessage="{checkIcon} We found a title agency or business associated with this email!"
                values={{ checkIcon: <Icon className={Styles.checkIcon} name="success-filled" /> }}
              />
            ),
            placement: "below",
          }}
          as={TextInput}
        />
        <SpacedMultipartFormRow>
          <ConfiguredField
            config={config}
            configField={CONFIGS.contactFirstName}
            data-automation-id={`${automationPrefix}.poc-first-name-field`}
            disabledOverride={disabled}
            form={form}
            registerOptions={{ validate: validateCollaboratorName }}
            requiredOverride={primaryCollaboratorRequired}
            name={`${fieldNamePrefix}.firstName`}
            label={intl.formatMessage(MESSAGES.firstName)}
            as={TextInput}
          />
          <ConfiguredField
            config={config}
            configField={CONFIGS.contactLastName}
            data-automation-id={`${automationPrefix}.poc-last-name-field`}
            disabledOverride={disabled}
            form={form}
            registerOptions={{ validate: validateCollaboratorName }}
            requiredOverride={primaryCollaboratorRequired}
            name={`${fieldNamePrefix}.lastName`}
            label={intl.formatMessage(MESSAGES.lastName)}
            as={TextInput}
          />
          <ConfiguredField
            config={config}
            configField={CONFIGS.contactPhoneNumber}
            data-automation-id={`${automationPrefix}.poc-phone-number-field`}
            disabledOverride={disabled}
            form={form}
            registerOptions={{
              minLength: 10,
              maxLength: 10,
            }}
            name={`${fieldNamePrefix}.phoneNumber`}
            label={intl.formatMessage(MESSAGES.phoneNumber)}
          >
            <ContactPhoneNumberWithRef control={form.control} index={index} />
          </ConfiguredField>
        </SpacedMultipartFormRow>
        {isPrimary && (
          <OrganizationSelection
            config={config}
            form={form}
            organizationInteraction={collaboratorOrganizationInteraction}
          />
        )}
        <RequirementsAndPermissionsFields
          configFields={{
            shownToSigner: CONFIGS.collaboratorShownToSigner,
            accessToTransaction: CONFIGS.collaboratorAccessToTransaction,
          }}
          config={config}
          disabledOverride={disabled}
          form={form}
          index={index}
        />
      </Card>
    </CardWrapper>
  );
}

export function ContactCard({
  index,
  collabEnabled,
  config,
  disabled,
  form,
  remove,
}: {
  index: number;
  collabEnabled: boolean;
  config: Config;
  disabled?: true;
  form: UseForm;
  remove?: (index: number) => void;
}) {
  const intl = useIntl();
  const fieldNamePrefix = `${FORM_FIELDS.contacts}.${index}` as const;
  const role = useWatch({ control: form.control, name: `${fieldNamePrefix}.role` });
  const showContactTitle = role === OrganizationTransactionContactRoleType.OTHER;
  const automationPrefix = `pointsOfContact.${index}`;

  return (
    <CardWrapper data-automation-id="point-of-contact">
      <Card
        iconName="user-filled"
        title={intl.formatMessage(CONTACT_MESSAGES.title)}
        subtitle={intl.formatMessage(CONTACT_MESSAGES.description)}
        removeButtonProps={
          remove
            ? {
                label: intl.formatMessage(CONTACT_MESSAGES.remove, {
                  number: index + 1,
                }),
                onClick: () => {
                  remove(index);
                },
                disabled,
                "data-automation-id": `${automationPrefix}.remove`,
              }
            : undefined
        }
      >
        <SpacedMultipartFormRow>
          <ConfiguredField
            config={config}
            configField={CONFIGS.contactRole}
            data-automation-id={`${automationPrefix}.poc-role-field`}
            disabledOverride={disabled}
            form={form}
            name={`${fieldNamePrefix}.role`}
            label={intl.formatMessage(CONTACT_MESSAGES.role)}
          >
            <ContactRoleSelectField aria-invalid="false" collabEnabled={collabEnabled} />
          </ConfiguredField>
          <ConfiguredField
            config={config}
            configField={CONFIGS.contactFirstName}
            data-automation-id={`${automationPrefix}.poc-first-name-field`}
            disabledOverride={disabled}
            form={form}
            name={`${fieldNamePrefix}.firstName`}
            label={intl.formatMessage(MESSAGES.firstName)}
            as={TextInput}
          />
          <ConfiguredField
            config={config}
            configField={CONFIGS.contactLastName}
            data-automation-id={`${automationPrefix}.poc-last-name-field`}
            disabledOverride={disabled}
            form={form}
            name={`${fieldNamePrefix}.lastName`}
            label={intl.formatMessage(MESSAGES.lastName)}
            as={TextInput}
          />
        </SpacedMultipartFormRow>
        {showContactTitle && (
          <ConfiguredField
            config={config}
            configField={CONFIGS.contactTitle}
            data-automation-id={`${automationPrefix}.poc-title-field`}
            disabledOverride={disabled}
            form={form}
            name={`${fieldNamePrefix}.title`}
            label={intl.formatMessage(CONTACT_MESSAGES.titleField)}
            as={TextInput}
          />
        )}
        <SpacedMultipartFormRow>
          <ConfiguredField
            config={config}
            configField={CONFIGS.contactEmail}
            data-automation-id={`${automationPrefix}.poc-email-field`}
            disabledOverride={disabled}
            form={form}
            name={`${fieldNamePrefix}.email`}
            label={intl.formatMessage(MESSAGES.email)}
            as={TextInput}
            registerOptions={{
              pattern: emailPatternValidation(intl),
              validate: (value, formValues) => {
                return value || formValues.contacts[index]?.phoneNumber
                  ? true
                  : intl.formatMessage(MESSAGES.emailOrPhoneRequired);
              },
            }}
          />
          <ConfiguredField
            config={config}
            configField={CONFIGS.contactPhoneNumber}
            data-automation-id={`${automationPrefix}.poc-phone-number-field`}
            disabledOverride={disabled}
            form={form}
            registerOptions={{
              minLength: 10,
              maxLength: 10,
              deps: `${fieldNamePrefix}.email`,
            }}
            name={`${fieldNamePrefix}.phoneNumber`}
            label={intl.formatMessage(MESSAGES.phoneNumber)}
          >
            <ContactPhoneNumberWithRef control={form.control} index={index} />
          </ConfiguredField>
        </SpacedMultipartFormRow>
        <RequirementsAndPermissionsFields
          configFields={{
            shownToSigner: CONFIGS.contactShownToSigner,
            accessToTransaction: CONFIGS.contactAccessToTransaction,
          }}
          config={config}
          disabledOverride={disabled}
          form={form}
          index={index}
        />
      </Card>
    </CardWrapper>
  );
}
