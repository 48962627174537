import {
  memo,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
  type ReactElement,
  type ReactNode,
  type Ref,
  forwardRef,
} from "react";
import { defineMessage, FormattedMessage, useIntl } from "react-intl";
import {
  catchError,
  combineLatest,
  concat,
  defer,
  distinctUntilChanged,
  from,
  fromEvent,
  map,
  of,
  startWith,
  switchMap,
  throwError,
  type Observable,
} from "rxjs";
import classNames from "classnames";

import { CURRENT_PORTAL } from "constants/app_subdomains";
import { Select } from "common/core/form/select";
import { captureException } from "util/exception";
import { getMediaErrorNameFromException, type MediaError } from "common/video_conference/exception";
import { useId } from "util/html";
import Button from "common/core/button";
import PopoutMenu from "common/core/popout_menu";
import { PopoutMenuMultilineItem } from "common/core/popout_menu/multiline";
import Icon from "common/core/icon";
import SROnly from "common/core/screen_reader";
import { useMobileScreenClass } from "common/core/responsive";
import { Paragraph } from "common/core/typography";

import Styles from "./device_picker.module.scss";

type PhoneSetupState = "closed" | "prompt" | "configure";
type StreamDispatchAction = { type: string; payload: MediaStream };
type Device = {
  value: string;
  label: string | ReactElement;
};
type DevicesEnumResult = Readonly<
  | {
      outcome: "success";
      devices: MediaDeviceInfo[];
      defaultDevice?: MediaDeviceInfo;
    }
  | { outcome: "failure"; errorType: ReturnType<typeof getMediaErrorNameFromException> }
>;
type FromPermissionResult =
  | { permission: "allowed" }
  | { permission: "denied"; errorType: ReturnType<typeof getMediaErrorNameFromException> };
type Props = {
  onDeviceSelect: (deviceId: string | null, options?: { autoSelected: boolean }) => void;
  onDeviceMissing?: () => void;
  onDisable?: () => void;
  onDeviceError?: (errorType: MediaError | null) => void;
  requiredMessaging?: string;
  selectedDeviceId: string | null | undefined;
  deviceKind: MediaDeviceKind;
  placeholder?: string;
  label?: ReactNode;
  defaultDeviceLabel?: string | null | undefined;
  "aria-describedby"?: string;
  autoFocus?: boolean;
};
type DropDownProps = Props & {
  children?: ReactNode;
  phoneSetupState?: PhoneSetupState;
};

const IS_NOT_SIGNER_PORTAL = CURRENT_PORTAL !== "customer";
const DEFAULT_PLACEHOLDER = defineMessage({
  id: "f0e5386c-4372-40b4-8be5-9a43601292b3",
  defaultMessage: "Select a device",
});
const NO_DEVICE_MESSAGE = (
  <FormattedMessage id="cfc9e067-b3b9-4103-8d8d-c18ee81d44bb" defaultMessage="No devices found" />
);
const PHONE_DEVICE_MESSAGE = (
  <FormattedMessage id="82ec94b5-95e9-4513-a45c-6e3e614993cc" defaultMessage="Phone" />
);
const DEVICE_FAILURE: DevicesEnumResult = Object.freeze({
  errorType: null,
  outcome: "failure" as const,
});
const DEFAULT_LISTING = Object.freeze([
  Object.freeze({
    label: <FormattedMessage id="8879294f-5b31-4ae3-a869-1cfd9efb5ed4" defaultMessage="Default" />,
    value: "def",
  }),
]);
const DEFAULT_DEVICE_LABELS = Object.freeze({
  audioinput: (
    <FormattedMessage
      id="13aa9914-43f8-4e4d-8d78-12acc822052f"
      description="Default microphone label in tech check picker"
      defaultMessage="Microphone"
    />
  ),
  videoinput: (
    <FormattedMessage
      id="d83083ff-ceaa-4dbf-b2dc-0a0ba846e73c"
      description="Default webcam label in tech check picker"
      defaultMessage="Webcam"
    />
  ),
  audiooutput: (
    <FormattedMessage
      id="d885e1b3-434c-4af4-97c6-bae2d5765709"
      description="Default speaker label in tech check picker"
      defaultMessage="Speakers"
    />
  ),
});
const noop = () => {};

function logDeviceNames(
  kind: MediaDeviceKind,
  defaultDevice: MediaDeviceInfo | undefined,
  devices: MediaDeviceInfo[],
) {
  if (IS_NOT_SIGNER_PORTAL || !kind.endsWith("input")) {
    return;
  }
  const deviceNames = devices.map((d) => d.label).filter(Boolean);
  if (deviceNames.length) {
    // eslint-disable-next-line no-console
    console.log("[AV Devices]", {
      kind,
      defaultName: defaultDevice?.label || undefined,
      names: deviceNames,
    });
  }
}

function fromDeviceEnumerations(
  deviceKind: MediaDeviceKind,
  defaultDeviceLabel: Props["defaultDeviceLabel"],
): Observable<DevicesEnumResult> {
  return defer(() =>
    from(
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          const deduppedDevices = devices.filter(
            // Remove 'default' device since duplicate https://stackoverflow.com/a/53308831
            (device) =>
              device.kind === deviceKind &&
              device.deviceId !== "" && // Filter out 'fake' devices given when permissions are not yet
              device.deviceId !== "default" &&
              device.deviceId !== "communications",
          );
          const defaultDeviceDup = devices.find(
            (device) =>
              device.kind === deviceKind &&
              (device.deviceId === "default" || device.deviceId === "communications"),
          );
          // Note: only Chrome reveals default OS device
          const defaultDevice = defaultDeviceDup?.groupId
            ? deduppedDevices.find((device) => device.groupId === defaultDeviceDup.groupId)
            : defaultDeviceLabel
              ? deduppedDevices.find((device) =>
                  // Will only on ios devices that are localized to english. Should work on all android devices
                  // https://stackoverflow.com/a/65586409
                  device.label.toLocaleLowerCase().includes(defaultDeviceLabel),
                )
              : undefined;

          if (process.env.NODE_ENV === "production") {
            logDeviceNames(deviceKind, defaultDevice, deduppedDevices);
          }

          return {
            outcome: "success" as const,
            devices: deduppedDevices,
            defaultDevice,
          };
        })
        .catch(() => DEVICE_FAILURE),
    ),
  );
}

function fromCurrentDevicePermissions(
  deviceKind: MediaDeviceKind,
  streamListDispatch: (streamAction: StreamDispatchAction) => void,
): Observable<FromPermissionResult> {
  const kind = deviceKind === "videoinput" ? "video" : "audio";
  return defer(() =>
    // We want to avoid making too many calls to getUserMedia
    // Here we assume that audiooutput is accompanied by audioinput
    // picker on the same page, so we need to prompt only for audioinput
    deviceKind === "audiooutput"
      ? of({ permission: "allowed" as const })
      : from(
          navigator.mediaDevices
            .getUserMedia({ [kind]: true })
            .then((stream) => {
              // add new media stream to list of media streams
              streamListDispatch({ type: "addStream", payload: stream });
            })
            .then(() => ({ permission: "allowed" as const }))
            .catch((error: Error | null) => {
              const errorType = error?.name ? getMediaErrorNameFromException(error) : null;
              if (error && errorType) {
                // eslint-disable-next-line no-console
                console.warn(`DevicePicker device error: ${error.name} - ${error.message}`);
              } else if (error) {
                captureException(error);
              }
              return { permission: "denied" as const, errorType };
            }),
        ),
  );
}

function fromQueriedPermissions(deviceKind: MediaDeviceKind): Observable<PermissionStatus> {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!window.navigator?.permissions?.query) {
    return throwError(() => new Error("Missing Permissions API"));
  }
  return defer(() =>
    from(
      window.navigator.permissions.query({
        name:
          deviceKind === "videoinput"
            ? // the unknown cast is becauase "camera" is at risk single implementation
              ("camera" as unknown as PermissionName)
            : ("microphone" as PermissionName),
      }),
    ),
  );
}

function fromChangingDevicePermissions(
  deviceKind: MediaDeviceKind,
): Observable<PermissionState | "unknown"> {
  return fromQueriedPermissions(deviceKind).pipe(
    switchMap((status) =>
      fromEvent(status, "change").pipe(
        map(() => status.state),
        startWith(status.state),
      ),
    ),
    catchError(() => {
      // Lots of browsers (including Firefox, safari) do not support query for
      // camera/microphone so we fallback to checking if there are any matching enumerated devices,
      // and if not, we then fallback again to prompting.
      return of("unknown" as const);
    }),
    distinctUntilChanged(),
  );
}

function fromDevices(
  deviceKind: MediaDeviceKind,
  defaultDeviceLabel: Props["defaultDeviceLabel"],
  streamListDispatch: (streamList: StreamDispatchAction) => void,
): Observable<DevicesEnumResult> {
  return combineLatest([
    fromChangingDevicePermissions(deviceKind),
    fromEvent(navigator.mediaDevices, "devicechange").pipe(startWith(null)),
  ]).pipe(
    switchMap(([permissionState], index) => {
      const isUnknown = permissionState === "unknown";
      const isPrompt = permissionState === "prompt";
      const promptedState$: Observable<FromPermissionResult> =
        isPrompt && index === 0
          ? fromCurrentDevicePermissions(deviceKind, streamListDispatch)
          : isPrompt
            ? concat(
                of({ permission: "denied" as const, errorType: null }),
                fromCurrentDevicePermissions(deviceKind, streamListDispatch),
              )
            : isUnknown
              ? fromDeviceEnumerations(deviceKind, defaultDeviceLabel).pipe(
                  switchMap((result) => {
                    if (result.outcome === "failure" || result.devices.length === 0) {
                      return fromCurrentDevicePermissions(deviceKind, streamListDispatch);
                    }
                    return of({ permission: "allowed" as const });
                  }),
                )
              : of(
                  permissionState === "denied"
                    ? { permission: "denied" as const, errorType: "NotAllowed" }
                    : { permission: "allowed" as const },
                );
      return promptedState$.pipe(
        switchMap((result) => {
          return result.permission === "allowed"
            ? fromDeviceEnumerations(deviceKind, defaultDeviceLabel)
            : of({
                ...DEVICE_FAILURE,
                errorType: result.errorType,
              });
        }),
      );
    }),
  );
}

const streamReducer = (streamList: MediaStream[], action: StreamDispatchAction) => {
  switch (action.type) {
    case "addStream":
      return [...streamList, action.payload];
    default:
      return streamList;
  }
};

function useDevices({
  deviceKind,
  selectedDeviceId,
  onDeviceSelect,
  requiredMessaging,
  onDeviceError,
  onDeviceMissing = noop,
  onDisable = noop,
  defaultDeviceLabel,
}: Props) {
  const [devices, setDevices] = useState<Device[] | null>(null);
  const [streamListState, streamListDispatch] = useReducer(streamReducer, []);
  const [showDefaultSelection, setShowDefaultSelection] = useState(false);
  const nextCallback = useRef<(result: DevicesEnumResult) => void>(noop);
  // We _always_ replace the next callback handler when we render. This is so
  // we dont need a complex dep list for the device permissions effect.
  useEffect(() => {
    nextCallback.current = (result) => {
      if (result.outcome === "failure") {
        setDevices([]);
        requiredMessaging && onDeviceMissing();
        result.errorType &&
          onDeviceError?.({
            kind: deviceKind === "videoinput" ? "video" : "audio",
            name: result.errorType,
          });
        onDeviceSelect(null, { autoSelected: true });
        return;
      }
      onDeviceError?.(null);
      const { devices, defaultDevice } = result;
      setDevices(
        devices.map((device) => ({
          value: device.deviceId,
          // Some browsers hide the device labels when a stream is not running or persisent
          // permissions have not been given. As a result, we need to return some kind of label.
          label: device.label || DEFAULT_DEVICE_LABELS[device.kind],
        })),
      );

      const selectedDeviceListed = devices.some((d) => d.deviceId === selectedDeviceId);
      if (selectedDeviceId && selectedDeviceListed) {
        onDeviceSelect(selectedDeviceId, { autoSelected: true });
        return;
      }

      if (devices.length === 0 && requiredMessaging) {
        onDeviceMissing();
        return;
      }
      if (devices.length === 0 && !requiredMessaging) {
        // If there are no devices, but we don't show a warning about it,
        // then we select the "default." (like speakers on Firefox)
        onDisable();
        onDeviceSelect("def", { autoSelected: true });
        setShowDefaultSelection(true);
        return;
      }

      if (defaultDevice) {
        onDeviceSelect(defaultDevice.deviceId, { autoSelected: true });
      } else {
        onDeviceSelect(devices[0].deviceId, { autoSelected: true });
      }
    };
  });
  useEffect(() => {
    const sub = fromDevices(deviceKind, defaultDeviceLabel, streamListDispatch).subscribe({
      next: (result) => nextCallback.current(result),
    });
    return () => {
      sub.unsubscribe();
    };
  }, [deviceKind]);

  useEffect(() => {
    return () => {
      // stop any media stream tracks we started
      streamListState.forEach((stream) => stream.getTracks().forEach((track) => track.stop()));
    };
  }, [streamListState]);

  return { showDefaultSelection, devices };
}

function DevicePicker(props: Props) {
  const ref = useRef<HTMLSelectElement | null>(null);
  const intl = useIntl();
  const notFoundId = useId();
  const requiredMessagingId = useId();
  const {
    onDeviceSelect,
    requiredMessaging,
    selectedDeviceId,
    deviceKind,
    label,
    placeholder,
    "aria-describedby": ariaDescribedby,
  } = props;
  const handleDeviceSelection = useCallback(
    (deviceId: string) =>
      selectedDeviceId !== deviceId && onDeviceSelect(deviceId, { autoSelected: false }),
    [onDeviceSelect, selectedDeviceId],
  );
  const { devices, showDefaultSelection } = useDevices(props);
  const showMissingError = devices && !devices.length && requiredMessaging;
  const items = !devices ? [] : showDefaultSelection ? DEFAULT_LISTING : devices;
  const itemsWithPlaceholder = [
    { value: "", label: placeholder || intl.formatMessage(DEFAULT_PLACEHOLDER) },
    ...items,
  ];

  const getPickerAriaDescribedyByIds = () => {
    if (items.length === 0 || ariaDescribedby || showMissingError) {
      return `${ariaDescribedby ? ariaDescribedby : ""} ${items.length === 0 ? notFoundId : ""} ${
        showMissingError ? requiredMessagingId : ""
      }`;
    }
  };

  useEffect(() => {
    if (ref.current && props.autoFocus) {
      setTimeout(() => ref.current?.focus(), 100);
    }
  }, []);
  const value = (() => {
    if (!devices) {
      return "";
    }
    if (showDefaultSelection) {
      return DEFAULT_LISTING[0].value;
    }
    if (selectedDeviceId) {
      return selectedDeviceId;
    }
    return "";
  })();

  return (
    <div className={Styles.picker} data-automation-id={`select-${deviceKind}`}>
      <Select
        items={itemsWithPlaceholder}
        value={value}
        label={label}
        onChange={(event) => handleDeviceSelection(event.target.value)}
        aria-invalid={Boolean(showMissingError)}
        aria-describedby={getPickerAriaDescribedyByIds()}
        data-automation-id={`select-${deviceKind}-combobox`}
        ref={ref}
      />
      {items.length === 0 && (
        <div role="alert" className={Styles.error} id={notFoundId}>
          {NO_DEVICE_MESSAGE}
        </div>
      )}
      {showMissingError && (
        <div role="alert" className={Styles.error} id={requiredMessagingId}>
          {requiredMessaging}
        </div>
      )}
    </div>
  );
}

const DeviceDropDown = forwardRef((props: DropDownProps, ref: Ref<HTMLButtonElement>) => {
  const mobileScreenClass = useMobileScreenClass();
  const {
    onDeviceSelect,
    requiredMessaging,
    selectedDeviceId,
    deviceKind,
    "aria-describedby": ariaDescribedby,
    autoFocus,
    label,
    placeholder,
    children,
    phoneSetupState,
  } = props;
  const handleDeviceSelection = useCallback(
    (deviceId: string) =>
      selectedDeviceId !== deviceId && onDeviceSelect(deviceId, { autoSelected: false }),
    [onDeviceSelect, selectedDeviceId],
  );
  const { devices, showDefaultSelection } = useDevices(props);
  const items = !devices ? [] : showDefaultSelection ? DEFAULT_LISTING : devices;

  const value =
    phoneSetupState === "configure"
      ? PHONE_DEVICE_MESSAGE
      : (selectedDeviceId && devices?.find((d) => d.value === selectedDeviceId)?.label) || "";
  const iconName =
    deviceKind === "videoinput"
      ? "video-chat"
      : deviceKind === "audioinput"
        ? "microphone"
        : "audio";

  const isValid = (requiredMessaging ? items.length : true) || value === PHONE_DEVICE_MESSAGE;

  const displayValue = (function getDisplayValue() {
    if (mobileScreenClass) {
      // show 'Default', value name, or '' if no value set (since a label is shown on mobile)
      return !value && showDefaultSelection ? DEFAULT_LISTING[0].label : value;
    }
    // show Default, value name, or label if no value set (since a label is not otherwise shown on desktop and we don't want to show a blank value)
    return !value && showDefaultSelection ? DEFAULT_LISTING[0].label : value || label;
  })();
  function DropDownTarget(open: boolean) {
    return (
      <Button
        variant="tertiary"
        buttonColor={isValid ? "action" : "danger"}
        automationId={`select-${deviceKind}`}
        aria-invalid={Boolean(isValid)}
        aria-describedby={ariaDescribedby}
        autoFocus={autoFocus}
        className={classNames(Styles.dropdownButton, !isValid && Styles.dropdownError)}
        ref={ref}
      >
        <Icon name={iconName} />
        <div className={Styles.buttonText}>
          {mobileScreenClass && label ? (
            <Paragraph textColor="subtle">{label}</Paragraph>
          ) : (
            <SROnly>{placeholder || label}</SROnly>
          )}
          <Paragraph>{displayValue}</Paragraph>
        </div>
        <Icon name={open ? "caret-up" : "caret-down"} className={Styles.caret} />
      </Button>
    );
  }

  return (
    <>
      <PopoutMenu
        className={Styles.popoutMenu}
        wrapperClassName={Styles.popoutMenuWrapper}
        target={DropDownTarget}
        selfManageVerticalAlignment
        verticalAlignmentPadding={128} // accounts for footer and various margin/padding
        placement="bottomLeft"
        listRole="presentation"
      >
        {() => (
          <>
            {items.map((item) => (
              <PopoutMenuMultilineItem
                key={item.value}
                onClick={() => {
                  handleDeviceSelection(item.value);
                }}
                primaryContent={item.label}
              />
            ))}
            {children}
          </>
        )}
      </PopoutMenu>
    </>
  );
});

const MemoizedDropDown = memo(DeviceDropDown);

export default memo(DevicePicker);
export { MemoizedDropDown as DeviceDropDown };
