import { memo } from "react";
import { FormattedMessage } from "react-intl";

import type { VideoProvider } from "graphql_globals";
import { DeprecatedDetailGridRow } from "common/details/grid/row";

type Props = {
  meeting: { video: null | { id: string; provider: VideoProvider } };
};

const UNAVAILABLE = (
  <FormattedMessage id="1d8b7a2f-8f38-453a-bc85-6144ab005db6" defaultMessage="Unavailable" />
);

function VideoSessionId({ meeting }: Props) {
  const { video } = meeting;
  return (
    <div>
      <DeprecatedDetailGridRow title="Video Service Provider">
        <span className="DetailGrid--scrolling-row DetailGrid--scrolling-row--single">
          {video?.provider ? video.provider : UNAVAILABLE}
        </span>
      </DeprecatedDetailGridRow>
      <DeprecatedDetailGridRow title="Video Session ID">
        <span className="DetailGrid--scrolling-row DetailGrid--scrolling-row--single">
          {video?.id ? video.id : UNAVAILABLE}
        </span>
      </DeprecatedDetailGridRow>
    </div>
  );
}

export default memo(VideoSessionId);
