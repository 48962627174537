import { useState } from "react";

import { BulkActionBar } from "common/core/bulk_actions";
import Button from "common/core/button";
import { Substyle } from "common/core/typography";

import Styles from "./merge_documents_toolbar.module.scss";

type MergeDocumentsToolbarProps = {
  numOfDocuments: number;
  numOfSelectedDocuments: number;
  isMergeToolActive: boolean;
  setIsMergeToolActive: (status: boolean) => void;
  setShowMergeDocumentsModal: (status: boolean) => void;
};

const MergeDocumentsToolbar = ({
  numOfDocuments,
  numOfSelectedDocuments,
  isMergeToolActive,
  setIsMergeToolActive,
  setShowMergeDocumentsModal,
}: MergeDocumentsToolbarProps) => {
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const reqNumOfSelectedDocuments = 2;

  const toggleMergeTool = () => {
    setIsMergeToolActive(!isMergeToolActive);
    setIsInitialLoad(false);
  };

  const content = isMergeToolActive ? (
    <BulkActionBar itemCount={numOfSelectedDocuments}>
      <Button
        variant="tertiary"
        className={Styles.activeButton}
        buttonSize="condensed"
        onClick={toggleMergeTool}
        withIcon={{ placement: "left", name: "x-filled" }}
        autoFocus
      >
        Close
      </Button>
      <div className={Styles.activeButtonContainer}>
        <Button
          className={Styles.activeButton}
          variant="tertiary"
          buttonSize="condensed"
          buttonColor="action"
          onClick={() => setShowMergeDocumentsModal(true)}
          disabled={numOfSelectedDocuments < reqNumOfSelectedDocuments}
          disabledHint="Select two or more documents in the table to merge."
          disabledHintPlacement="bottom"
          withIcon={{ placement: "left", name: "merge" }}
          automationId="merge-documents-button"
        >
          Merge documents
        </Button>
      </div>
    </BulkActionBar>
  ) : (
    <div className={Styles.inactiveToolbar}>
      <Substyle size="defaultSize">{`Total Documents: ${numOfDocuments}`}</Substyle>
      <div className={Styles.defaultButtonContainer}>
        <Button
          className={Styles.defaultButton}
          variant="tertiary"
          buttonSize="condensed"
          buttonColor="action"
          onClick={() => setIsMergeToolActive(!isMergeToolActive)}
          automationId="open-merge-tool-button"
          autoFocus={!isInitialLoad}
        >
          Open merge tool
        </Button>
      </div>
    </div>
  );

  return (
    <div className={Styles.mergeDocumentsToolbar} data-automation-id="merge-documents-toolbar">
      {content}
    </div>
  );
};

export default MergeDocumentsToolbar;
