import { useEffect } from "react";
// eslint-disable-next-line no-restricted-imports
import isEqual from "lodash/isEqual";

import { useSelector, useDispatch } from "redux/util";
import { setAnalyticsContext, removeFromAnalyticsContext } from "redux/actions/analytics";

type ContextRecord = { [k in (typeof ALL_KEYS)[number]]?: unknown };

const BUNDLE_KEYS = [
  "transaction_type",
  "transaction_state",
  "guest_enabled",
  "proofing_required",
  "notarization_required",
  "kba_required",
  "signer_can_annotate_some_documents",
  "witness_required_for_some_documents",
  "transaction_creator_role",
  "transaction_creator_id",
  "transaction_creator_first_name",
  "transaction_creator_last_name",
  "transaction_requires_nsa_meeting",
  "organization_transaction_type",
  "lender_agency_id",
  "title_agency_id",
  "org_id",
  "org_transaction_id",
  "payer",
  "signer_type",
  "signer_count",
  "document_count",
  "activation_time",
  "expiration_time",
  "activation_timezone",
  "expiration_timezone",
  "meeting_id",
  "cosigner_user_id",
  "notary_id",
  "document_bundle_id",
  "state_abbr",
  "region",
  "sign_ahead",
  "sign_ahead_actionable",
  "phone_number_provided",
] as const;
const ALL_KEYS = [
  "user_id",
  "account_type",
  "business_role",
  "org_member_id",
  "org_member_role",
  ...BUNDLE_KEYS,
] as const;
const ALLOWED_KEYS = new Set(ALL_KEYS);

export function useRemoveBundleKeysFromAnalytics() {
  const dispatch = useDispatch();
  useEffect(
    () => () => {
      dispatch(removeFromAnalyticsContext(BUNDLE_KEYS));
    },
    [],
  );
}

export function useAnalyticsContext(
  contextSetter: (prevContext: ContextRecord) => ContextRecord | undefined,
) {
  const dispatch = useDispatch();
  const analytics = useSelector((state) => state.analytics) as ContextRecord;
  useEffect(() => {
    const incomingContext = contextSetter(analytics) || {};
    const newContext: ContextRecord = {};
    let hasValues = false;
    for (const key of Object.keys(incomingContext) as (typeof ALL_KEYS)[number][]) {
      if (ALLOWED_KEYS.has(key) && !isEqual(analytics[key], incomingContext[key])) {
        newContext[key] = incomingContext[key];
        hasValues = true;
      }
    }
    if (hasValues) {
      dispatch(setAnalyticsContext({ ...analytics, ...newContext }));
    }
  });
}
