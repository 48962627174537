import { Navigate, Routes, Route } from "react-router-dom";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import type { MouseEvent } from "react";

import { useFeatureFlag } from "common/feature_gating";
import SROnly from "common/core/screen_reader";
import { SidebarV2BackButton, SidebarV2 } from "common/sidebar";
import { userFullName } from "util/user";
import {
  ProfileSettings,
  SettingsSidebarProfileLink,
  PROFILE_ROUTE,
} from "common/settingsv2/sidebar_settings/profile";
import {
  ProfileOverview,
  ProfileOverviewTab,
  PROFILE_OVERVIEW_ROUTE,
} from "common/settingsv2/sidebar_settings/profile/overview";
import {
  PersonalDetails,
  PersonalDetailsTab,
  PROFILE_PERSONAL_DETAILS_ROUTE,
} from "common/settingsv2/sidebar_settings/profile/personal_details";
import {
  PersonalPayment,
  PersonalPaymentTab,
  PROFILE_PERSONAL_PAYMENTS_ROUTE,
} from "common/settingsv2/sidebar_settings/profile/personal_payment";
import {
  TermsSettings,
  SettingsSidebarTermsLink,
  TERMS_ROUTE,
} from "common/settingsv2/sidebar_settings/terms";
import { TermsAndConditions } from "common/settingsv2/sidebar_settings/terms/terms";
import { useViewer } from "util/viewer_wrapper";
import { handleKeyDown } from "common/core/tabs";
// eslint-disable-next-line import/no-restricted-paths
import type { SignerRouterViewer_viewer_user as User } from "signer_portal/router/viewer_query.graphql";
import { CONSUMER_CERTIFICATES, USER_SETTINGS_PRIVACY_TAB } from "constants/feature_gates";

import NotarySettings, {
  SettingsSidebarNotaryProfileLink,
  NOTARY_PROFILE_ROUTE,
} from "../sidebar_settings/notary";
import { NOTARY_SECTION_ROUTES } from "../sidebar_settings/notary/section";
import { Privacy, PrivacyTab, PROFILE_PRIVACY_ROUTE } from "../sidebar_settings/profile/privacy";
import {
  PROFILE_PROOF_CERTIFICATE_ROUTE,
  ProofCertificate,
  ProofCertificateTab,
} from "../sidebar_settings/profile/proof_certificate";

export const INBOX_ROUTE = "/bundle/records?filter=all";

const LABELS = defineMessages({
  menu: { id: "2a14f78b-becc-4657-bf4e-9a94e7cc0523", defaultMessage: "Account menu" },
});

function SignerSidebar() {
  const { viewer } = useViewer();
  const intl = useIntl();
  const user = viewer.user as User | null;
  const userName = {
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
  };
  const isNotary = Boolean(user?.notaryProfile);

  const handleClick = (e: MouseEvent) => {
    // if the event doesnt have a clientX we assume its coming from a keyboard
    if (!e.clientX) {
      const siblingElement = (e.target as HTMLElement).parentElement?.parentElement?.nextSibling as
        | HTMLElement
        | undefined;
      const focusableElement = siblingElement?.querySelector(
        'a[href="/settings/profile/overview"]',
      ) as HTMLElement | undefined;
      focusableElement?.focus();
    }
  };

  return (
    <SidebarV2
      title={
        <FormattedMessage id="355d2030-e09f-4bae-8c36-ae22d616f003" defaultMessage="My account" />
      }
      subtitle={
        <FormattedMessage
          id="6ebc53bd-0a77-4ac5-85e0-a86a96f4584b"
          defaultMessage="{subtitle}"
          values={{ subtitle: userFullName(userName) }}
        />
      }
      backButton={
        <SidebarV2BackButton to={INBOX_ROUTE}>
          <FormattedMessage id="ecee6832-5871-439b-9f41-e8517171e6f4" defaultMessage="Back" />
          <SROnly>
            <FormattedMessage
              id="ecee6832-5871-439b-9f41-e8517171e6f4"
              defaultMessage="to dashboard"
            />
          </SROnly>
        </SidebarV2BackButton>
      }
    >
      <div
        aria-label={intl.formatMessage(LABELS.menu)}
        onKeyDown={(e) => {
          handleKeyDown(e.key, e.target);
        }}
        role="menu"
      >
        <SettingsSidebarProfileLink handleClick={handleClick} isMenuItem />
        {isNotary && <SettingsSidebarNotaryProfileLink user={user!} isMenuItem />}
        <SettingsSidebarTermsLink isMenuItem />
      </div>
    </SidebarV2>
  );
}

export default function SignerSettings() {
  const userSettingsPrivacyTabEnabled = useFeatureFlag(USER_SETTINGS_PRIVACY_TAB);
  const userSettingsProofCertificateTabEnabled = useFeatureFlag(CONSUMER_CERTIFICATES);
  return (
    <>
      <SignerSidebar />
      <Routes>
        <Route
          path={PROFILE_ROUTE}
          element={
            <ProfileSettings
              tabs={
                <>
                  <ProfileOverviewTab enableKeyboardNavigation />
                  <PersonalDetailsTab enableKeyboardNavigation />
                  <PersonalPaymentTab enableKeyboardNavigation />
                  {userSettingsPrivacyTabEnabled && <PrivacyTab enableKeyboardNavigation />}
                  {userSettingsProofCertificateTabEnabled && (
                    <ProofCertificateTab enableKeyboardNavigation />
                  )}
                </>
              }
              enableKeyboardNavigation
            />
          }
        >
          <Route path={PROFILE_OVERVIEW_ROUTE} element={<ProfileOverview />} />
          <Route path={PROFILE_PERSONAL_DETAILS_ROUTE} element={<PersonalDetails />} />
          <Route path={PROFILE_PERSONAL_PAYMENTS_ROUTE} element={<PersonalPayment />} />
          {userSettingsPrivacyTabEnabled && (
            <Route path={PROFILE_PRIVACY_ROUTE} element={<Privacy />} />
          )}
          {userSettingsProofCertificateTabEnabled && (
            <Route path={PROFILE_PROOF_CERTIFICATE_ROUTE} element={<ProofCertificate />} />
          )}
          <Route index element={<Navigate to={PROFILE_OVERVIEW_ROUTE} replace />} />
        </Route>

        <Route path={NOTARY_PROFILE_ROUTE} element={<NotarySettings />}>
          {NOTARY_SECTION_ROUTES}
          <Route index element={<Navigate to="personal-info" replace />} />
        </Route>

        <Route path={TERMS_ROUTE} element={<TermsSettings />}>
          <Route index element={<TermsAndConditions />} />
        </Route>

        <Route
          index
          element={<Navigate replace to={`${PROFILE_ROUTE}/${PROFILE_OVERVIEW_ROUTE}`} />}
        />
      </Routes>
    </>
  );
}
