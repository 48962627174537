import { memo } from "react";
import { FormattedMessage } from "react-intl";

import { CompletionRequirement, type NotarialActs } from "graphql_globals";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import CompletionRequirementsText from "util/completion_requirements/completion_requirements_text";
import { notarialActLabel } from "util/document";

type Props = {
  meeting: {
    documentBundle: { completionRequirements: (CompletionRequirement | null)[] | null } | null;
    notarialActs: (NotarialActs | null)[] | null;
  };
};

function NotarialActsItem({ meeting: { documentBundle, notarialActs } }: Props) {
  const { completionRequirements } = documentBundle!;

  let notarialAct = null;
  let agentAct = null;

  const rowTitle = (
    <CompletionRequirementsText
      stringId="signingType"
      completionRequirements={[CompletionRequirement.NOTARIZATION]}
    />
  );

  const noActs = !notarialActs?.length;
  const listItems = noActs ? (
    <FormattedMessage id="3aabcc31-1b3d-44e3-bc72-d81c2ea0e1a1" defaultMessage="Not Specified" />
  ) : (
    notarialActs.map((act) => <div key={act}>{notarialActLabel(act!)}</div>)
  );

  notarialAct = <DeprecatedDetailGridRow title={rowTitle}>{listItems}</DeprecatedDetailGridRow>;

  if (noActs && completionRequirements?.includes(CompletionRequirement.PROOFING)) {
    const proofingRowTitle = (
      <CompletionRequirementsText
        stringId="signingType"
        completionRequirements={[CompletionRequirement.PROOFING]}
      />
    );

    notarialAct = null;
    agentAct = (
      <DeprecatedDetailGridRow title={proofingRowTitle}>
        <FormattedMessage
          id="a1ed0f17-851c-4459-b615-f2dcbf63a329"
          description="identityConfirmation"
          defaultMessage="Identity Confirmation"
        />
      </DeprecatedDetailGridRow>
    );
  }

  return (
    <span>
      {notarialAct}
      {agentAct}
    </span>
  );
}

export default memo(NotarialActsItem);
