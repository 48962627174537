export const PAYMENT_CARD = "CARD";
export const PAYMENT_ACH = "ACH";

export const AVAILABLE_FEATURES = Object.freeze({
  SHOW_PICTURE_IDS: "show_picture_ids",
  CONCURRENT_SIGNING: "concurrent_signing",
  WAITING_ROOM: "waiting_room",
});

export const BRANDING = Object.freeze({
  GUEST_UPLOAD_FOOTER: "guest_upload_footer",
});
