import { useEffect, useState } from "react";
import classnames from "classnames";
import { defineMessages, useIntl, FormattedMessage } from "react-intl";

import { useTagUserMutation } from "common/user/tag";
import Button from "common/core/button";
import { segmentTrack } from "util/segment";
import { EVENT } from "constants/analytics";

import type {
  UserDiscountsCheck_viewer_user as User,
  UserDiscountsCheck_viewer_user_displayableDiscounts_edges_node as Discount,
} from "./discount_query.graphql";
import Overlay from "../overlay";
import Styles from "./index.module.scss";

type Props = {
  user: User;
  className?: string;
};

const MESSAGES = defineMessages({
  dialogLabel: {
    id: "3204b709-73e6-413a-a50e-b7c47b5ffb58",
    defaultMessage: "Discounts",
  },
});

function DiscountModal(props: Props) {
  const { id: userId, displayableDiscounts } = props.user;
  const [shownDiscount, setShownDiscount] = useState<null | Discount>(() => {
    return displayableDiscounts.edges.find(({ node }) => node.messagingTag)?.node || null;
  });
  const intl = useIntl();
  const tagUserMutateFn = useTagUserMutation();

  useEffect(() => {
    if (shownDiscount) {
      segmentTrack(EVENT.SIGNER_DISCOUNT_MODAL_SHOWN);
    }
  }, [Boolean(shownDiscount)]);

  if (!shownDiscount) {
    return null;
  }

  const acknowledgeDiscount = () => {
    tagUserMutateFn({
      variables: {
        userTagList: [],
        input: { userId, tag: shownDiscount.messagingTag! },
      },
    })
      .then(() => {
        setShownDiscount(null);
        segmentTrack(EVENT.SIGNER_DISCOUNT_MODAL_CLOSED);
      })
      .catch((error) => {
        segmentTrack(EVENT.SIGNER_DISCOUNT_MODAL_ERROR, error);
      });
  };

  return (
    <Overlay>
      <div
        role="dialog"
        aria-label={intl.formatMessage(MESSAGES.dialogLabel)}
        className={Styles.container}
      >
        <div className={classnames(Styles.main, props.className)}>
          <div
            className={Styles.description}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: shownDiscount.description! }}
          />
          {shownDiscount.terms && (
            <div
              className={Styles.terms}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: shownDiscount.terms }}
            />
          )}
          <div className={Styles.button}>
            <Button onClick={acknowledgeDiscount} buttonColor="action" variant="primary" fullwidth>
              <FormattedMessage
                id="79389dd2-b209-464c-afd4-54297a58a978"
                defaultMessage="Get Started"
              />
            </Button>
          </div>
        </div>
      </div>
    </Overlay>
  );
}

export default DiscountModal;
