import { defineMessages, useIntl } from "react-intl";

import DevicePicker from "../device_picker";

const MESSAGING = defineMessages({
  microPlaceholder: {
    id: "f8fbb952-8c42-4d55-be70-443ec6db7eb0",
    description: "Microphone selection placeholder in tech check",
    defaultMessage: "Select microphone",
  },
  microLabel: {
    id: "7a17ddc1-7fa7-453c-8b12-651fc376a5d9",
    description: "Microphone selection label in tech check",
    defaultMessage: "Microphone",
  },
  microRequired: {
    id: "2ff5fab6-f23d-4c96-9ec7-36c915b38afc",
    description: "microphone_required_text",
    defaultMessage: "A microphone is required.",
  },
  speakerPlaceholder: {
    id: "bcd75364-ae95-4df4-a3a1-4a6ff38feac1",
    description: "Speaker selection placeholder in tech check",
    defaultMessage: "Select speakers",
  },
  speakerLabel: {
    id: "0405de8f-e513-4953-8c9f-152d80371f3b",
    description: "Speaker selection label in tech check",
    defaultMessage: "Speakers",
  },
});

type PickerProps = {
  onDeviceMissing: () => void;
  selectedDeviceId: string | null | undefined;
  onDisable?: () => void;
  autoFocus?: boolean;
  "aria-describedby"?: string;
};

type MicrophonePickerProps = PickerProps & {
  onDeviceSelect: (microphone: string | null) => void;
};

type SpeakerPickerProps = PickerProps & {
  onDeviceSelect: (speaker: string | null) => void;
};

export function MicrophonePicker({
  onDeviceSelect,
  onDeviceMissing,
  selectedDeviceId,
  autoFocus,
  "aria-describedby": ariaDescribedBy,
}: MicrophonePickerProps) {
  const intl = useIntl();
  return (
    <DevicePicker
      placeholder={intl.formatMessage(MESSAGING.microPlaceholder)}
      label={intl.formatMessage(MESSAGING.microLabel)}
      deviceKind="audioinput"
      selectedDeviceId={selectedDeviceId}
      onDeviceSelect={onDeviceSelect}
      onDeviceMissing={onDeviceMissing}
      requiredMessaging={intl.formatMessage(MESSAGING.microRequired)}
      autoFocus={autoFocus}
      aria-describedby={ariaDescribedBy}
    />
  );
}

export function SpeakerPicker({
  onDeviceSelect,
  onDeviceMissing,
  onDisable,
  selectedDeviceId,
}: SpeakerPickerProps) {
  const intl = useIntl();
  return (
    <DevicePicker
      placeholder={intl.formatMessage(MESSAGING.speakerPlaceholder)}
      label={intl.formatMessage(MESSAGING.speakerLabel)}
      deviceKind="audiooutput"
      selectedDeviceId={selectedDeviceId}
      onDeviceSelect={onDeviceSelect}
      onDeviceMissing={onDeviceMissing}
      onDisable={onDisable}
    />
  );
}
