import { type MutableRefObject } from "react";

import type { SigningAsset } from "common/signer/utils";
import { SignatureMethod } from "graphql_globals";
import type { VectorGraphicSubtype } from "common/pdf/interaction";
import { findMaxRemSize, centerAndAlignText } from "util/canvas_text";
import { MAX_SIGNATURE_FONT_SIZE, SIGNATURE_COLOR_HEX } from "constants/globals";
import { type FontConfig } from "util/signature_fonts";

export const ENABLE_SIGNATURE_OPTIONS = "enable-signature-options";
export const ENABLE_SIGNATURE_OPTIONS_FONTS = "enable-signature-options-fonts";

type SignatureOptions = {
  allowAll: boolean;
  allowHandwritten: boolean;
  allowTextbased: boolean;
};

export type SignatureOptionsOrganization = {
  id: string;
  signatureOptions: {
    allowHandwritten: boolean;
    allowTextbased: boolean;
    enabled: boolean;
  };
};

export function getSignatureOptions(organization?: SignatureOptionsOrganization): SignatureOptions {
  let allowAll = true;
  let allowHandwritten = true;
  let allowTextbased = true;
  if (organization?.signatureOptions.enabled) {
    allowHandwritten = organization.signatureOptions.allowHandwritten;
    allowTextbased = organization.signatureOptions.allowTextbased;
    allowAll = allowHandwritten && allowTextbased;
  }
  return { allowAll, allowHandwritten, allowTextbased };
}

export function forceAssetRecreation({
  organization,
  signingAssets,
  type,
}: {
  organization?: SignatureOptionsOrganization;
  signingAssets?: {
    initialsAsset: SigningAsset | null;
    signatureAsset: SigningAsset | null;
  } | null;
  type: VectorGraphicSubtype;
}): boolean {
  const { allowAll, allowHandwritten, allowTextbased } = getSignatureOptions(organization);
  if (!allowAll) {
    // first gather the current assetMethod based on type, default is REUSED
    const assetMethod =
      (type === "INITIALS"
        ? signingAssets?.initialsAsset?.method
        : signingAssets?.signatureAsset?.method) || SignatureMethod.REUSED;
    if (
      // if we allowHandwritten then the curent assetMethod must be DRAWN, if not force recreate
      (allowHandwritten && assetMethod !== SignatureMethod.DRAWN) ||
      // if we allowTextbased then the curent assetMethod must be TYPED, if not force recreate
      (allowTextbased && assetMethod !== SignatureMethod.TYPED)
    ) {
      return true;
    }
  }
  return false;
}

export function drawSignatures(
  fonts: readonly FontConfig[],
  canvasRefs: MutableRefObject<(HTMLCanvasElement | null)[]>,
  text?: string,
) {
  if (!text) {
    return;
  }

  fonts.forEach(({ font, padding }, index) => {
    const canvas = canvasRefs.current[index]!;
    const ctx = canvas.getContext("2d")!;
    const { offsetWidth, offsetHeight } = canvas;

    const ratio = Math.max(window.devicePixelRatio, 1);
    const width = offsetWidth * ratio;
    const height = offsetHeight * ratio;

    canvas.width = width;
    canvas.height = height;

    const maxFontSize = findMaxRemSize(
      text,
      font,
      canvas,
      padding * ratio,
      MAX_SIGNATURE_FONT_SIZE * ratio,
    );

    ctx.clearRect(0, 0, width, height);
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.font = `normal ${maxFontSize}rem ${font}`;
    ctx.fillStyle = SIGNATURE_COLOR_HEX;
    ctx.strokeStyle = SIGNATURE_COLOR_HEX;
    ctx.lineWidth = ratio;

    // We draw the text and stroke in the middle of the canvas before shifting it down and to the left
    // since fonts designed to mimic handwriting have strange overhangs that aren't accounted for with
    // context.measureText();
    ctx.strokeText(text, width / 2, height / 2);
    ctx.fillText(text, width / 2, height / 2);

    // We draw the canvas once with all of our desired text and strokes, but we want to make sure
    // none of the text is cut off when we left align it.
    centerAndAlignText(text, canvas, ctx);
  });
}
