import { useEffect, type ReactNode } from "react";
import { useLocation } from "react-router-dom";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";

import { EnvironmentWarningHeader } from "common/account/login/components";
import { pendoInitialize } from "util/pendo";
import type { CustomOrgHeadersEnum } from "graphql_globals";
import Link from "common/core/link";

import Styles from "./index.module.scss";
import { LogoNav } from "../logo_nav";

type Props = {
  children: ReactNode;
  orgLogo?: string | null;
  orgName?: string | null;
  header?: CustomOrgHeadersEnum;
  readOnly?: boolean;
  hideWarningHeader?: boolean;
  headerClassName?: string;
  isMobileSignUp?: boolean;
};

export default function UnauthenticatedAppFrame({
  children,
  orgLogo,
  orgName,
  header,
  readOnly,
  headerClassName,
  hideWarningHeader,
  isMobileSignUp,
}: Props) {
  const { pathname } = useLocation();

  useEffect(() => {
    pendoInitialize();
  }, []);

  return (
    <div className={Styles.container}>
      {!hideWarningHeader && <EnvironmentWarningHeader />}
      <div className={classnames(Styles.containerHeader, headerClassName)}>
        <LogoNav
          noWrapper
          noInnerWrapper
          orgName={orgName}
          logoUrl={orgLogo}
          headerStyle={header}
          readOnly={readOnly || pathname === "/login"}
        />
        {isMobileSignUp && (
          <div className={Styles.logInLink}>
            <FormattedMessage
              id="80d78e79-ec8e-456d-ab8d-46eecc9498fd"
              defaultMessage="Have an account? <link>Log in</link>"
              values={{
                link: (text) => (
                  <Link openInCurrentTab to="/login">
                    {text}
                  </Link>
                ),
              }}
            />
          </div>
        )}
      </div>
      <div className="AppContainer">{children}</div>
    </div>
  );
}
