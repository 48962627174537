import "./index.scss";

import { Component } from "react";
import PropTypes from "prop-types";
import { Observable } from "rxjs";
import classnames from "classnames";

import DocumentUploadHandler from "common/document/multidoc_uploader/document_upload_handler";
import { Feature } from "graphql_globals";

import AddDocumentModal from "./add_modal";
import DocumentUploaderManager from "./manager";
import {
  DOCUMENT_PROPTYPE,
  SUPPORTED_FILE_EXTENSIONS,
  isDocX,
  softWarningOnDocxUpload,
} from "./document_item_util";
import { AnnotateModal } from "./annotate_modal";
import DocumentUploaderHeader from "./header";

/**
 * This component abstracts an uploader/manager for _documents_. It has management CRUD operations
 * but is stateless (the document objects themselves are passed as props).
 */
class DocumentUploader extends Component {
  state = {
    addModalOpen: false,
    // This keeps track of the files a user may have dragged onto the dropzone
    // Technically, it doesn't need to be in state because it doesn't directly
    // affect rendering since its used as an "initializer prop" to the modal
    // but its here for consistence (or in case this changes in the future.
    droppedFiles: [],
    selectedDocuments: {},
    preSelectedAnnotateDocumentId: null,
    isAnnotating: false,
    matchedDocumentId: null,
  };

  openAddModal = () => {
    this.changeAddModalOpen(true);
  };

  updateTransactionAndOpenModal = () => {
    const { onOpenAddDocumentModal } = this.props;
    if (onOpenAddDocumentModal) {
      this.openModalWrapper(onOpenAddDocumentModal(), this.openAddModal);
    } else {
      this.openAddModal();
    }
  };

  closeAddModal = (addedDocuments = []) => {
    this.changeAddModalOpen(false);
    this.props.onCancelUpload(addedDocuments);
  };

  openAnnotateModal = (selectedDocumentId = null, skipSave = false) => {
    if (this.props.cannotEditDocs) {
      this.setState({
        isAnnotating: true,
        preSelectedAnnotateDocumentId: selectedDocumentId,
      });
    } else {
      this.openModalWrapper(this.props.onOpenAnnotateModal(skipSave), () => {
        this.setState({
          isAnnotating: true,
          preSelectedAnnotateDocumentId: selectedDocumentId,
        });
      });
    }
  };

  openModalWrapper = (result, callback) => {
    // result can be false so need to check before chaining
    if (result) {
      result.then(callback).catch(() => {});
    }
  };

  closeAnnotateModal = () => {
    this.props.onCloseAnnotateModal();
    this.setState({
      isAnnotating: false,
      preSelectedAnnotateDocumentId: null,
      matchedDocumentId: null,
    });
  };

  handleAllDocumentSelect = () => {
    this.setState(({ selectedDocuments }) => {
      const { documents } = this.props;
      // If there are any unselected docs, toggling turns all of them on.
      const atLeastOneUnselected = documents.some(({ id }) => !selectedDocuments[id]);
      return {
        selectedDocuments: documents.reduce(
          (accum, { id }) => ({
            ...accum,
            [id]: atLeastOneUnselected,
          }),
          {},
        ),
      };
    });
  };

  handleChangeDocumentProperties = (propValues, changedDocument) => {
    this.props.onChangeDocumentProperties(
      this.singleOrSelectedDocumentIds(changedDocument),
      propValues,
    );
  };

  handleDeleteDocuments = (deletedDocument) => {
    return this.props.onDeleteDocuments(this.singleOrSelectedDocumentIds(deletedDocument));
  };

  containsDocxFile = (documentItems) => {
    return documentItems.some((item) => isDocX(item.name));
  };

  handleUploadComplete = (documentItems, templates) => {
    return new Observable((observer) => {
      let lastVal;
      return this.props.onUploadComplete(documentItems, templates).subscribe({
        next: (val) => {
          lastVal = val;
          observer.next(val);
        },
        error: (err) => {
          observer.error(err);
        },
        complete: () => {
          this.changeAddModalOpen(false);
          observer.complete();

          if (this.containsDocxFile(documentItems)) {
            softWarningOnDocxUpload();
          }

          const addDocNode =
            lastVal?.data.addDocumentsToTransaction?.transaction.document_bundle.documents.edges.find(
              ({ node: { id } }) => id === documentItems[0]?.id,
            )?.node;

          // We don't want to open the annotate modal for documents that are in the middle of processing.
          // The url may not be available in the future. Documents may enter processing state after
          // adding docs to the bundle and template splitting is triggered. The fix approach is not great, but alternatives
          // not much better right now. Needs to wait on a revamp of document splitting.
          if (
            addDocNode &&
            addDocNode.processing_state === "DONE" &&
            this.props.openAnnotateModalAfterDocumentsUploaded
          ) {
            this.setState({ matchedDocumentId: addDocNode.id });
            this.openAnnotateModal(addDocNode.id, true);
          } else if (!addDocNode && this.props.openAnnotateModalAfterDocumentsUploaded) {
            this.openAnnotateModal(documentItems[0]?.id, true);
          }
        },
      });
    });
  };

  handleSelectDocument = ({ id }) => {
    this.setState(({ selectedDocuments }) => ({
      selectedDocuments: {
        ...selectedDocuments,
        [id]: !selectedDocuments[id],
      },
    }));
  };

  handleDropFiles = (files) => {
    this.setState(() => ({ droppedFiles: files }));
    this.changeAddModalOpen(true);
    this.props.onDropFiles(files);
  };

  handleSelectedFiles = (onSelectFilesFn) => {
    return (files) => {
      this.props.onDropFiles(files);
      onSelectFilesFn(files);
    };
  };

  stopDefault = (event) => {
    // We want to prevent the default of drop and dragover events
    // so the browser doesn't navigate to a file or anything. We also have to make this
    // a class property so its a unique function to _this_ instance of DocumentUploader.
    event.preventDefault();
  };

  singleOrSelectedDocumentIds(singleDocument) {
    // Sometimes we are applying something to a single document, but sometimes we are applying it
    // to many documents.
    const filteredDocuments = this.props.documents
      .filter((doc) => !doc.isConsentForm && !doc.isEnote)
      .map((doc) => doc.id);
    return singleDocument ? [singleDocument.id] : filteredDocuments;
  }

  changeAddModalOpen(newOpenValue) {
    this.setState((prevState) => ({
      // Everytime we close the modal, always make sure to clear any dropped files
      // so the next open is "clean."
      droppedFiles: newOpenValue ? prevState.droppedFiles : [],
      addModalOpen: newOpenValue,
    }));
  }

  componentDidMount() {
    const { body } = window.document;
    body.addEventListener("drop", this.stopDefault);
    body.addEventListener("dragover", this.stopDefault);
  }

  componentWillUnmount() {
    const { body } = window.document;
    body.removeEventListener("drop", this.stopDefault);
    body.removeEventListener("dragover", this.stopDefault);
  }

  render() {
    const {
      documents,
      transaction,
      organization,
      viewer,
      supportedFileTypes,
      onReorderDocument,
      onRenameDocument,
      onResubmitDocument,
      onOpenAnnotateModal,
      uploadStrategy,
      addButtonContent,
      canRequireProofing,
      className,
      canSetDocRequirements,
      canSetDocPermissions,
      canRequireMeeting,
      showWitnessRequired,
      hasCosigner,
      disableTemplateUpload,
      readOnly,
      cannotEditDocs,
      allowDownload,
      canRequireEsign,
      defaultDocRequirements,
      showAcceptedDocuments,
      annotatingByPrompt,
      textTagSyntaxManager,
      showUploaderMessaging,
      disableSplittingManager,
      onSplitDocuments,
      documentBundleId,
    } = this.props;

    const {
      addModalOpen,
      selectedDocuments,
      droppedFiles,
      isAnnotating,
      preSelectedAnnotateDocumentId,
      matchedDocumentId,
    } = this.state;

    const cx = classnames("DocumentUploader", className);
    const modalToUse = (
      <DocumentUploadHandler uploadStrategy={uploadStrategy} initUploadedDocuments={droppedFiles}>
        {({ uploadedDocuments$, onSelectFiles, onDocumentDelete }) => (
          <AddDocumentModal
            uploadedDocuments$={uploadedDocuments$}
            documents={documents}
            organization={organization}
            supportedFileTypes={supportedFileTypes}
            onCancel={this.closeAddModal}
            addButtonContent={addButtonContent}
            onSelectedFiles={this.handleSelectedFiles(onSelectFiles)}
            onDocumentDelete={onDocumentDelete}
            onUploadComplete={this.handleUploadComplete}
            disableSplittingManager={disableSplittingManager}
            hasCosigner={hasCosigner}
            disableTemplateUpload={disableTemplateUpload}
            defaultDocRequirements={defaultDocRequirements}
            showAcceptedDocuments={showAcceptedDocuments}
            textTagSyntaxManager={textTagSyntaxManager}
          />
        )}
      </DocumentUploadHandler>
    );

    const splitAndTagDocuments = organization.featureList.includes(Feature.SPLIT_AND_TAG_DOCUMENTS);

    return (
      <div className={cx}>
        {(isAnnotating || annotatingByPrompt) && (
          <AnnotateModal
            splitAndTagDocuments={splitAndTagDocuments}
            readOnly={readOnly}
            cannotEditDocs={cannotEditDocs}
            transactionId={transaction.id}
            onClose={this.closeAnnotateModal}
            matchedDocumentId={matchedDocumentId}
            defaultDocumentId={preSelectedAnnotateDocumentId}
          />
        )}

        <DocumentUploaderHeader
          hasDocuments={documents.length > 0}
          onAddDocuments={this.updateTransactionAndOpenModal}
          isAnnotating={isAnnotating}
          hideAnnotateButton={!onOpenAnnotateModal}
          requestOpenAnnotate={this.openAnnotateModal}
          readOnly={readOnly}
          cannotEditDocs={cannotEditDocs}
          supportedFileTypes={supportedFileTypes}
          showAcceptedDocuments={showAcceptedDocuments}
        />

        {addModalOpen && modalToUse}
        {documents.length > 0 && (
          <DocumentUploaderManager
            documents={documents}
            documentBundleId={documentBundleId}
            transactionId={transaction.id}
            viewer={viewer}
            selectedDocuments={selectedDocuments}
            onReorderDocument={onReorderDocument}
            onSelectDocument={this.handleSelectDocument}
            onToggleAll={this.handleAllDocumentSelect}
            onChangeDocumentProperties={this.handleChangeDocumentProperties}
            hideAnnotateButton={!onOpenAnnotateModal}
            onDeleteDocuments={this.handleDeleteDocuments}
            onRenameDocument={onRenameDocument}
            onResubmitDocument={onResubmitDocument}
            onAddDocuments={this.updateTransactionAndOpenModal}
            canRequireProofing={canRequireProofing}
            canSetDocRequirements={canSetDocRequirements}
            canSetDocPermissions={canSetDocPermissions}
            canRequireMeeting={canRequireMeeting}
            showWitnessRequired={showWitnessRequired}
            openAnnotateModal={this.openAnnotateModal}
            isAnnotating={isAnnotating}
            readOnly={readOnly}
            cannotEditDocs={cannotEditDocs}
            allowDownload={allowDownload}
            canRequireEsign={canRequireEsign}
            isMortgage={transaction?.isMortgage}
            transactionType={transaction?.transactionType}
            customerSigners={transaction?.customerSigners}
            showUploaderMessaging={showUploaderMessaging}
            evaultEnabled={organization.evaultEnabled}
            placeAnOrderEnabled={organization.placeAnOrderEnabled}
            placeAnOrderLenderEnabled={organization.placeAnOrderLenderEnabled}
            organization={organization}
            onSplitDocuments={onSplitDocuments}
          />
        )}
      </div>
    );
  }
}

DocumentUploader.propTypes = {
  /**
   * List of file extensions to support within an instance of the uploader.
   * Must be a subset of the list of the all supported types (SUPPORTED_FILE_EXTENSIONS).
   */
  supportedFileTypes: PropTypes.arrayOf(PropTypes.oneOf(SUPPORTED_FILE_EXTENSIONS)).isRequired,
  /**
   * Called with an array of document IDs to change and an object of the desired prop states:
   * `{ notarizationRequired, signerCanAnnotate, witnessRequired }`
   */
  onChangeDocumentProperties: PropTypes.func.isRequired,
  /** Called with an array of document IDs to delete */
  onDeleteDocuments: PropTypes.func.isRequired,
  /** Called with document ID of _moving_ document and ID of the documenat at desired new index */
  onReorderDocument: PropTypes.func,
  /** Called with document object to rename and the new desired name */
  onRenameDocument: PropTypes.func.isRequired,
  /**
   * Called with a `File` object, this must return an observable on first value emission indicates
   * a succesful upload. The value must be an observable of "document objects" that will be later
   * passed back `onUploadComplete`. An error indicates a failed upload. The document objects
   * must have `.mimeType` and `.name` properties.
   */
  onResubmitDocument: PropTypes.func,
  /** Called with document object to reupload a document to a transaction */
  uploadStrategy: PropTypes.func.isRequired,
  /** Given a count of sucessful uploads, return a react node of button content */
  addButtonContent: PropTypes.func.isRequired,
  /**
   * This function will be passed an array of all sucessful uploads. Each item in the array will
   * correspond to a value emitted in `uploadStrategy`. It must return an observable that completes
   * when the uploads from the add modal have been handled.
   */
  onDropFiles: PropTypes.func.isRequired,
  onCancelUpload: PropTypes.func.isRequired,
  onUploadComplete: PropTypes.func.isRequired,
  onOpenAnnotateModal: PropTypes.func.isRequired,
  onCloseAnnotateModal: PropTypes.func,
  onOpenAddDocumentModal: PropTypes.func,
  documents: PropTypes.arrayOf(DOCUMENT_PROPTYPE),
  transaction: PropTypes.object,
  organization: PropTypes.object,
  viewer: PropTypes.shape({
    user: PropTypes.shape({
      organizationMembership: PropTypes.shape({
        id: PropTypes.string,
        role: PropTypes.string,
      }),
    }).isRequired,
  }).isRequired,
  canRequireProofing: PropTypes.bool,
  canRequireEsign: PropTypes.bool,
  showAcceptedDocuments: PropTypes.bool,
  annotatingByPrompt: PropTypes.bool,
  /**
   * Provides checkboxes allowing user to modify multiple documents at once.
   */
  className: PropTypes.string,
  canSetDocRequirements: PropTypes.bool,
  canSetDocPermissions: PropTypes.bool,
  canRequireMeeting: PropTypes.bool,
  showWitnessRequired: PropTypes.bool,
  hasCosigner: PropTypes.bool,
  readOnly: PropTypes.bool,
  cannotEditDocs: PropTypes.bool,
  allowDownload: PropTypes.bool,
  showUploaderMessaging: PropTypes.bool,

  /**
   * Flag to indicate if the annotate modal should open after document upload
   */
  openAnnotateModalAfterDocumentsUploaded: PropTypes.bool,

  defaultDocRequirements: PropTypes.shape({
    notarizationRequired: PropTypes.bool,
    proofingRequired: PropTypes.bool,
    esign: PropTypes.bool,
    signingRequiresMeeting: PropTypes.bool,
  }),
  textTagSyntaxManager: PropTypes.object,
  disableSplittingManager: PropTypes.object,
  onSplitDocuments: PropTypes.func,
};

DocumentUploader.defaultProps = {
  onCloseAnnotateModal: () => {},
  documents: [],
  supportedFileTypes: SUPPORTED_FILE_EXTENSIONS,
  canRequireProofing: false,
  canSetDocRequirements: true,
  canSetDocPermissions: true,
  canRequireMeeting: false,
  showWitnessRequired: true,
  hasCosigner: false,
  openAnnotateModalAfterDocumentsUploaded: false,
  canRequireEsign: false,
  showAcceptedDocuments: true,
  annotatingByPrompt: false,
  onSplitDocuments: () => {},
};

export default DocumentUploader;
