import { ClientPlatform } from "graphql_globals";

export const TRANSACTION_TYPE_BUSINESS = "business";

export const SIGNER_TYPE_PRIMARY = "primary signer";
export const TERMS_OF_USE_CONSENT_VERSION = 20170724;
export const PRIVACY_POLICY_CONSENT_VERSION = 1;

export const PRE_MEETING_STEP = "pre_meeting";
export const IN_MEETING_STEP = "in_meeting";

export const PLATFORM = ClientPlatform.WEB;

// A place for all segmentTrack events:
export const SEGMENT_EVENTS = Object.freeze({
  SESSION_EXPIRED: "session_expired",

  SIGNER_QUESTION_SET: "Signer Received Question Set",
  SIGNER_ATTEMPTED_DOWNLOAD: "Signer Attempted Downloading Completed Doc",
  SIGNER_FAILED_DOWNLOAD: "Signer Failed to Download Completed Doc",
  SIGNER_COMPLETED_DOWNLOAD: "Signer Downloaded Completed Doc",
  SIGNER_ATTEMPTED_EMAIL: "Signer Attempted to Share Completed Doc - Email",
  SIGNER_FAILED_EMAIL: "Signer Failed to Share Completed Doc - Email",
  SIGNER_SHARED_EMAIL: "Signer Shared Completed Doc - Email",
  SIGNER_FAILED_POSTAL: "Signer Failed to Share Completed Doc - Postal Mail",
  SIGNER_SHARED_POSTAL: "Signer Shared Completed Doc - Postal Mail",

  // Signer Tou Modal
  TOU_MODAL_CONTINUE_CLICKED: "Signer clicked Agree and continue on TOU modal",
  TOU_MODAL_LOGOUT_CLICKED: "Signer clicked logout on TOU modal",
  TOU_MODAL_ACCEPT_CHECKBOX_CLICKED: "Signer clicked the accept checkbox on TOU modal",
  TOU_MODAL_SHOWN: "Signer shown TOU modal",
  TOU_MODAL_LINKS_CLICKED: "Signer clicked links on TOU modal",

  // ID Capture
  PHOTO_ID_TYPE_SELECTED: "Signer Selected Photo ID Type",
  SECONDARY_ID_TYPE_SELECTED: "Signer Selected Secondary ID Type",
  PHOTO_RETAKE: "Signer Clicks Retake Photo",
  PHOTO_CONFIRMED: "Photo Confirmed",
  PHOTO_CAPTURED: "ID Credential Captured",

  // Blur detection
  BLUR_DETECTION: "Blur Detection",
  BLUR_DETECTION_SKIPPED: "Blur Detection Skipped",

  // Meeting Request
  MEETING_REQUESTED: "Meeting Requested",
  MEETING_SOCKET_RECEIVED: "Meeting ready socket received",
  MEETING_REDIRECT: "Signer redirected into meeting",

  // Annotations
  ADD_ANNOTATION_CLICKED_SIGNER: "Add Annotation Clicked - Signer",
  ADD_ANNOTATION_CLICKED_NOTARY: "Add Annotation Clicked - Notary",
  ADD_ANNOTATION_SUCCESSFUL: "Add Annotation Successful",
  ANNOTATION_RECEIVED: "Annotation Received",

  // Org Transactions
  ORG_TRANSACTION_INITIATED: "Org Transaction Initiated",
  ORG_BATCH_TRANSACTION_INITIATED: "Batch Transaction Started",
  ORG_TRANSACTION_CANCELED_MODAL: "Org Transaction Canceled Modal",

  // In Meeting
  NOTARY_LOST_SERVER_CONNECTION: "Connection To Server Lost - Notary",
  NOTARY_RECEIVED_WITNESS_ADDED: "Notary Received Witness Added",
  NOTARY_REQUESTS_PHOTO_RETAKE: "Notary Requests Photo Retake",
  NOTARY_SELECTS_POINTER: "Notary Selects Pointer",
  NOTARY_SENT_CREATE_WITNESS: "Notary Sent Create Witness",
  NOTARY_SENT_RESET_CONNECTION: "Notary Sent Reset Connection",
  NOTARY_SENT_REFRESH_DOCUMENT: "Notary Sent Refresh Document",
  NOTARY_SENT_PUSH: "Notary Sent Push",
  NOTARY_SENT_SCROLL_TO_ANNOTATION: "Notary Sent Scroll To Annotation",
  NOTARY_SWITCHES_DOC_VIEW: "Notary Switches Doc View",
  NOTARY_TERMINATED_MEETING: "Notary Terminated Meeting",
  NOTARY_VIEWED_MISSING_MEETING_REQUIREMENTS_MODAL:
    "Notary Viewed Missing Meeting Requirements Modal",
  NOTARY_CLOSED_MISSING_MEETING_REQUIREMENTS_MODAL:
    "Notary Closed Missing Meeting Requirements Modal",
  NOTARY_VIEWED_OVERRIDE_MEETING_REQUIREMENTS_REASONS_MODAL:
    "Notary Viewed Override Meeting Requirements Reasons Modal",
  NOTARY_CLOSED_OVERRIDE_MEETING_REQUIREMENTS_REASONS_MODAL:
    "Notary Closed Override Meeting Requirements Reasons Modal",
  NOTARY_FORCE_COMPLETED_MEETING: "Notary Force Completed Meeting",
  NOTARY_COMPLETED_MEETING: "Notary Completed Meeting",
  SIGNER_RECEIVED_CHANGE_PAGE: "Signer Received Change Page",
  SIGNER_RECEIVED_CHANGE_SIGNER: "Signer Received Change Signer",
  SIGNER_RECEIVED_MEETING_TERMINATED: "Signer Received Meeting Terminated",
  SIGNER_RECEIVED_PHOTO_REQUEST: "Signer Received Photo Request",
  SIGNER_RECEIVED_SCROLL_ANNOTATION: "Signer Received Scroll To Annotation",
  SIGNER_RECEIVED_RESET_CONNECTION: "Signer Received Reset Connection Request [web]",
  SIGNER_MEETING_LOAD_COMPLETE: "Signer completed load of meeting",
  TERMINATE_MEETING_FAILED: "Terminate Meeting Failed",

  // Signer - Onboarding
  SIGNER_CANCELLED_ONBOARDING: "Signer Document Upload Cancelled During Onboarding",

  // Signer - Save Credentials after BIT
  SIGNER_CREDENTIALS_OPTION: "Presented Save Credentials Option",
  SIGNER_SKIPPED_SAVE_CREDENTIALS: "Skipped Save Credentials",
  SIGNER_SAVED_CREDENTIALS: "Saved Credentials",

  // Doc Prep / Sign Ahead
  SIGNER_PLACED_ANNOTATION: "Signer placed annotation",
  SIGNER_CLICKED_ZOOM_IN: "Signer clicked PSPDFKIT Zoom In button",
  SIGNER_CLICKED_ZOOM_OUT: "Signer clicked PSPDFKIT Zoom Out button",
  SIGNER_CLICKED_FOCUS: "Signer clicked PSPDFKIT Focus button",
  SIGNER_CLICKED_PAGE_NAV: "Signer clicked PSPDFKIT Page Navigation button",
  SIGNER_ROTATED_PAGE: "Signer clicked PSPDFKIT Rotate button",

  // Sign Ahead
  SIGNER_WRONGLY_ENTERED_SIGN_AHEAD: "Signer Wrongly Entered Sign Ahead",
  STARTED_GENERATING_SIGNING_ASSETS_FOR_USER: "Started Generating Signing Assets For User",
  FAILED_GENERATING_SIGNING_ASSESTS: "Failed Generating Signing Assets For User",
  SIGNER_CLICKED_BACK_TO_SIGN: "Sign Ahead: Signer Clicked Back To Sign Current Document",
  SIGNER_SKIPPED_SIGNING_ENTIRE_DOCUMENT: "Sign Ahead: Signer Skipped Signing Entire Document",
  SIGNER_CLICKED_FIND_UNSIGNED_DOCUMENT:
    "Sign Ahead: Signer Clicked To Find First Unsigned Document",
  SIGNER_CLICKED_COME_BACK_LATER: "Sign Ahead: Signer Clicked To Come Back Later",
  SIGNER_CLOSED_SIGN_AHEAD_UNFINISHED_MODAL: "Sign Ahead: Signer Closed Unfinished Modal",
  SIGNER_CLICKED_PREVIEW_SIGN_AHEAD_DOCUMENTS: "Sign Ahead: Signer Clicked Preview Documents",
  SIGNER_SHOWN_COMPLETED_AND_UNFINISHED_MODAL:
    "Sign Ahead: Signer Shown Completed And Unfinished Modal",

  // Hybrid Transactions
  SIGNER_INDICATED_IN_PERSON_NOTARY_MEETING_COMPLETE:
    "Signer Indicated In-person Notary Meeting Complete",
  SIGNER_INDICATED_IN_PERSON_NOTARY_MEETING_FUTURE:
    "Signer Indicated In-person Notary Meeting In Future",

  // NSA Hours
  MEETING_REQUEST_OUTSIDE_OF_NSA_HOURS: "Signer Requested Meeting Outside of NSA Hours",

  // Title Access Events
  DOC_ACCESS_PORTAL_LOGOUT: "Document Access Portal Logout Button Clicked",
  DOC_ACCESS_PORTAL_RETURN_TO_DASHBOARD:
    "Document Access Portal Return To Dashboard Button Clicked",
  DOC_ACCESS_PORTAL_OPEN_DOCUMENT: "Document Access Portal Document Clicked",
  DOC_ACCESS_PORTAL_CONVERT_MODAL_OPENED: "Document Access Portal Convert To Wetsign Modal Opened",
  DOC_ACCESS_PORTAL_CONVERT_MODAL_CLOSED: "Document Access Portal Convert To Wetsign Modal Closed",
  DOC_ACCESS_PORTAL_CONVERT_CONFIRM_CLICKED: "Document Access Portal Convert Confirm Clicked",
  DOC_ACCESS_PORTAL_DOCUMENT_UPLOADED: "Document Access Portal Document Uploaded",
  DOC_ACCESS_PORTAL_PROCESSING_ERROR_MODAL_CLOSED:
    "Document Access Portal Processing Error Modal Closed",
  DOC_ACCESS_PORTAL_PREVIEW_MODAL_CONFIRM_CLICKED:
    "Document Access Portal PDF Preview Modal Confirm Button Clicked",
  DOC_ACCESS_PORTAL_PREVIEW_MODAL_CANCEL_CLICKED:
    "Document Access Portal PDF Preview Modal Cancel Button Clicked",
  DOC_ACCESS_PORTAL_OPENED: "Document Access Portal Opened",
  DOC_ACCESS_PORTAL_SIGNUP: "Doc Access Portal Signup Clicked",
  DOC_ACCESS_PORTAL_PRINT_ALL_BUTTON_CLICKED: "Doc Access Print Button Clicked - All",
  DOC_ACCESS_PORTAL_PRINT_WET_SIGN_BUTTON_CLICKED: "Doc Access Print Button Clicked - Wet Sign",
  DOC_ACCESS_PORTAL_PRINT_ESIGN_BUTTON_CLICKED: "Doc Access Print Button Clicked - eSign",
  DOC_ACCESS_PORTAL_PRINT_WET_SIGN_COMPLETE_BUTTON_CLICKED:
    "Doc Access Print Button Clicked - Wet Sign Complete",
  DOC_ACCESS_PORTAL_PRINT_ESIGN_COMPLETE_BUTTON_CLICKED:
    "Doc Access Print Button Clicked - eSign Complete",

  // Wet Sign Transactions
  WET_SIGN_COMPLETE_BUTTON_CLICKED: "Wet Sign Complete Button Clicked",

  // Get Started Page
  GET_STARTED_PAGE_RENDERED: "Get Started Page Rendered",

  // Onboarding Tasks
  ONBOARDING_CARD_PRIMARY_ACTION_CLICKED: "Onboarding Card Primary Action Clicked",

  // New Twilio Tech Check
  TWILIO_TECH_CHECK_INTRO_STEP: "Twilio Tech Check - Step - Intro",
  TWILIO_TECH_CHECK_CONNECTED_TO_ROOM: "Twilio Tech Check - Step - Connected to Room",
  TWILIO_TECH_CHECK_DEVICE_SELECTION_STEP: "Twilio Tech Check - Step - Device Selection",
  TWILIO_TECH_CHECK_TEST_COMPLETED: "Twilio Tech Check - Step - Tech Check Completed",
  TWILIO_TECH_CHECK_DEVICE_REUSED: "Twilio Tech Check - Media Device Selected - Reused previous",
  TWILIO_TECH_CHECK_DEVICE_AUTO_SELECTED: "Twilio Tech Check - Media Device Selected - Auto",
  TWILIO_TECH_CHECK_DEVICE_MANUALLY_SELECTED:
    "Twilio Tech Check - Media Device Selected - Manually",
  TWILIO_TECH_CHECK_NETWORK_TEST_AUTO_STARTED: "Twilio Tech Check - Network Test Started - Auto",
  TWILIO_TECH_CHECK_NETWORK_TEST_MANUALLY_STARTED:
    "Twilio Tech Check - Network Test Started - Manually",
  TWILIO_TECH_CHECK_NETWORK_TEST_FAILURE_NO_VIDEO: "Twilio Tech Check - Test Result - No Video",
  TWILIO_TECH_CHECK_NETWORK_TEST_FAILURE_NO_AUDIO: "Twilio Tech Check - Test Result - No Audio",
  TWILIO_TECH_CHECK_NETWORK_TEST_SUCCESS: "Twilio Tech Check - Test Result - Connection Strong",
  TWILIO_TECH_CHECK_NETWORK_TEST_FAILURE: "Twilio Tech Check - Test Result - Connection Broken",
  TWILIO_TECH_CHECK_NETWORK_TEST_WEAK: "Twilio Tech Check - Test Result - Connection Weak",
  TWILIO_TECH_CHECK_CLICKED_SUPPORT_LINK: "Twilio Tech Check - Step - Clicked Support Link",
  TWILIO_TECH_CHECK_DEVICE_ERROR: "Twilio Tech Check - Media Device Error",
  // Join now tech check experiment
  SIGNER_JOIN_NOW_TECH_CHECK_NO_DEVICES: "Tech check - no devices - more info clicked",

  // Title Agency Onboarding
  TITLE_AGENCY_ACCOUNT_SETUP_PREFIX: "Title Agency Account Setup",
  TITLE_AGENCY_ACCOUNT_SETUP_SURVEY_SKIPPED: "Title Agency Account Setup Survey Skipped",
  TITLE_AGENCY_ACCOUNT_SETUP_COMPLETED: "Title Agency Account Setup Completed",
  TITLE_AGENCY_PLACE_ORDER_ONBOARDING_PREFIX: "Title Agency Place Order Onboarding",
  TITLE_AGENCY_PLACE_ORDER_ONBOARDING_CLOSE_BUTTON_CLICKED:
    "Title Agency Place Order Onboarding Close Button Clicked",
  TITLE_AGENCY_PLACE_ORDER_ONBOARDING_COMPLETED: "Title Agency Place Order Onboarding Completed",
  // V2 Events
  TITLE_AGENCY_ELIGIBILITY_ONBOARDING_SUCCEEDED: "Title eligibility onboarding succeeded",
  TITLE_AGENCY_PAYMENT_ONBOARDING_SUCCEEDED: "Title payment onboarding succeeded",
  TITLE_AGENCY_IHN_ONBOARDING_SUCCEEDED: "Title IHN onboarding succeeded",
  TITLE_AGENCY_INVITE_ONBOARDING_SUCCEEDED: "Title invite coworker onboarding succeeded",

  // Dashboard Filters
  DETAIL_STATUS_FILTER_SELECTED: "Detail Status Filter Selected",
  TRANSACTION_TYPE_FILTER_SELECTED: "Transaction Type Filter Selected",
  CLOSING_DATE_FILTER_SELECTED: "Closing Date Filter Selected",
  SEARCH_TERM_FILTER_CHANGED: "Search Term Filter Changed",
  CONTACT_FILTER_SELECTED: "Transaction Contact Filter Selected ",
  SCOPE_FILTER_SELECTED: "Transaction Scope Filter Selected",

  // Banner Events
  SHOWN_INELIGIBLE_DOC_CATEGORY_BANNER: "Doc category warning banner shown",
  DISMISSED_INELIGIBLE_DOC_CATEGORY_BANNER: "Doc category warning banner dismissed",
  SHOWN_INELIGIBLE_DOC_LANGUAGE_BANNER: "Doc language warning banner shown",
  DISMISSED_INELIGIBLE_DOC_LANGUAGE_BANNER: "Doc language warning banner dismissed",

  // Spanish Language Preference Prompt
  SHOWN_SPANISH_LANGUAGE_PREFERENCE_PROMPT: "Spanish speaking notary modal shown",
  DISMISSED_SPANISH_LANGUAGE_PREFERENCE_PROMPT: "Spanish speaking notary modal - no selected",
  CONFIRMED_SPANISH_LANGUAGE_PREFERENCE: "Spanish speaking notary modal - yes selected",

  // Support Chat
  OPENED_SUPPORT_CHAT: "SupportChatOpened [Web]",

  // Fullfill Designations
  SIGNER_BATCH_SIGN: "Signer clicked batch sign",
  SIGNER_FULFILLED_DESIGNATION_BATCH_UNAVAILABLE:
    "Signer clicked fulfill designation [batch sign unavailable]",
  SIGNER_FULFILLED_DESIGNATION_BATCH_AVAILABLE:
    "Signer clicked fulfill designation [batch sign available]",

  // Signer steps v2
  UNKNOWN_NEXT_STEP: "Signer Steps V2 - Unknown next step",
  NEXT_STEP_MISMATCH: "Signer Steps V2 - Next step mismatch",
  STEP_FAILED_TO_UPDATE: "Signer Steps V2 - Step failed to update",
  CURRENT_STEP_NOT_FOUND:
    "Signer Steps V2 - Expected current step ID not found in steps list anymore",
  CURRENT_STEP_NOT_SET: "Signer Steps V2 - Current step ID not set",

  // Signer mortgage pre-meeting
  HYBRID_SIGNING_COMPLETE: "Hybrid mortgage transaction completed [Web]",
  MORTGAGE_BUNDLE_ACCESSED: "Mortgage signer premeeting accessed [Web]",

  // Member management
  MEMBER_INVITED: "Member invitation sent",

  // Business onboarding
  BUSINESS_ONBOARDING_SHOWN: "Business Onboarding Shown",
  BUSINESS_ONBOARDING_INVITE_SKIPPED: "Business Onboarding Member Invite Skipped",
  BUSINESS_ONBOARDING_INDUSTRY: "Business Onboarding Industry Slide",

  // Guest upload / easylink
  GUEST_UPLOAD_LOGIN_PREVIEW_PAGE_VIEW: "Guest Upload - Login and Doc Preview Shows",
  GUEST_UPLOAD_LOGIN_PREVIEW_PREVIEW_CLICK: "Guest Upload - Document Preview",
  GUEST_UPLOAD_LOGIN_PREVIEW_BACK_CLICK: "Guest Upload - Back to Upload",

  // Document upload events
  CLICKED_DROPZONE: "Document Upload Click",
  SAVED_DOCUMENT_NAME: "Update Document Name",
  DELETED_UPLOADED_DOCUMENT: "Delete Document",

  // Add cosigner
  ADD_COSIGNER_MODAL_SHOWN: "Additional Parties - Modal Shown",
  ADD_COSIGNER_MODAL_SIGNER_CLICKED_YES_WITNESS: "Additional Parties - Signer Clicked Yes Witness",
  ADD_COSIGNER_MODAL_SIGNER_CLICKED_NO_WITNESS: "Additional Parties - Signer Clicked No Witness",
  ADD_COSIGNER_MODAL_SIGNER_CLICKED_YES_COSIGNER:
    "Additional Parties - Signer Clicked Yes Second Signer",
  ADD_COSIGNER_MODAL_SIGNER_CLICKED_NO_COSIGNER:
    "Additional Parties - Signer Clicked No Second Signer",
  ADD_COSIGNER_MODAL_SIGNER_CLICKED_CONTINUE: "Additional Parties - Signer Clicked Continue",
  ADD_COSIGNER_MODAL_SIGNER_CLICKED_CANCEL: "Additional Parties - Signer Clicked Cancel",

  // Active session blocking modal
  ACTIVE_SESSION_BLOCKING_MODAL_CONTINUE_CLICKED:
    "Active Session Blocking Modal  - Signer Clicked Continue",
  ACTIVE_SESSION_BLOCKING_MODAL_CANCEL_CLICKED:
    "Active Session Blocking Modal  - Signer Clicked Cancel",

  // API keys page
  API_LEARN_MORE_CLICK: "Gate API Link Click",

  // Payments
  SIGNER_ENTERED_CARD: "Setup Payment - Signer entered card",
  SIGNER_CLICKED_GPAY: "Setup Payment - Signer clicked Google Pay",
});

// metadata on analytics events, used for storybook
const EVENT_DATA = Object.freeze({
  // Global
  SITEMAP_FOOTER_CLICKED_LINK: {
    event: "Clicked a link in the sitemap footer",
    where: "Sitemap footer",
    action: "User clicks a link listed in the sitemap footer",
  },

  // Signer Document
  SIGNER_COMPLETED_DOCUMENT_REVIEW: {
    event: "Signer Completed Document Review",
    where: "pre-meeting signer flow document preparation step (after confirming personal details)",
    action: "user presses Complete Review button in header bar",
  },
  SIGNER_SAW_DOCUMENT_REVIEW_DOWNLOAD_MODAL: {
    event: "Signer Saw Document Review Download Modal",
    where: "pre-meeting signer flow document preparation step (after confirming personal details)",
    action: "user presses Download Now button in header bar",
  },
  SIGNER_CLICKED_DOCUMENT_REVIEW_DOWNLOAD_NOW_BUTTON: {
    event: "Signer Clicked Document Review Download Now Button",
    where: "pre-meeting signer flow document preparation step (after confirming personal details)",
    action: "user presses Download Now button in download documents warning modal",
  },
  SIGNER_CLICKED_DOCUMENT_REVIEW_CLOSE_BUTTON: {
    event: "Signer Clicked Document Review Close Button",
    where: "pre-meeting signer flow document preparation step (after confirming personal details)",
    action: "user presses Return to Review or close button in download documents warning modal",
  },

  // Document Bundle
  LOG_DESIGNATION_PREPARATION_TIME: {
    event: "Log Designation Preparation Time",
    where: "annotate modal that loads document bundle",
    action: "user closes the annotate modal when they're done",
  },

  // Signup
  INVALID_PASSWORD_SUBMITTED: {
    event: "Invalid Password Submitted",
    where: "currently only on signup page",
    action: "user submitted form with invalid password",
  },
  FIELD_FOCUSED: {
    event: "Field Focused",
    where: "currently only on signup page email/password",
    action: "user focused field in form",
  },

  // In Meeting
  NOTARY_SENT_CHANGE_SIGNER: {
    event: "Notary Sent Change Signer",
    where: "In meeting, where there is more than 1 participant who can sign",
    action: "notary clicks on user name to change user",
  },
  NOTARY_OPENED_CREDENTIAL_ANALYSIS: {
    event: "Notary Opened Credential Analysis",
    where: "In meeting",
    action: "When notary clicks ID check to open credential analysis",
  },
  NOTARY_CLICKED_ZOOM_ON_ID: {
    event: "Notary Clicked Zoom On ID",
    where: "In meeting, credential analysis",
    action: "When notary clicks the zoom in/out button during credential analysis",
  },
  NOTARY_CHANGED_VIEWER_MODE: {
    event: "Notary Changed Viewer Mode",
    where: "In meeting, credential analysis",
    action: "When notary clicks maximize/minimize during credential analysis",
  },
  NOTARY_CLICKED_ROTATE_ON_ID: {
    event: "Notary Clicked Rotate On ID",
    where: "In meeting, credential analysis",
    action: "When notary clicks the rotate (counter)clockwise button during credential analysis",
  },
  NOTARY_SWITCHED_ID_VIEW: {
    event: "Notary Switched ID View",
    where: "In meeting, credential analysis",
    action: "When notary clicks PRIMARY/SECONDARY/BACK/FRONT to view different IDs for signer",
  },
  NOTARY_VERIFIED_IDENTIFICATION: {
    event: "Notary Verified Identification",
    where: "In meeting, credential analysis",
    action: "When notary clicks the verify button during credential analysis",
  },
  NOTARY_UPDATED_IDENTIFICATION_VERIFICATION: {
    event: "Notary Updated Identification Verification",
    where: "In meeting, credential analysis",
    action: "When notary clicks the verify button after having already verified ID before",
  },
  SIGNER_RECEIVED_WITNESS_REQUEST: {
    event: "Signer Received Witness Request",
    where: "In meeting",
    action: "Signer got socket event for witness",
  },
  SIGNER_CLOSED_WITNESS_MODAL: {
    event: "Signer Closed Witness Modal",
    where: "In meeting",
    action: "Signer closes modal or adds witness",
  },
  SIGNER_ADDED_WITNESS: {
    event: "Signer Added Witness",
    where: "In meeting",
    action: "Signer clicks add witness button",
  },
  NOTARY_CLICKED_REFRESHED_CREDENTIAL_STATUS: {
    event: "Notary Clicked Refresh Credential Analysis Status",
    where: "In meeting, credential analysis",
    action: "When notary clicks Refresh in the credential viewer",
  },

  // Post Meeting
  SKIPPED_PRICE_SURVEY: {
    event: "Skipped price survey",
    where: "Post-notarization on the price survey page",
    action: "Clicked skip button for the price survey",
  },

  SUBMITTED_PRICE_SURVEY: {
    event: "Submitted price survey",
    where: "Post-notarization on the price survey page",
    action: "Clicked submit button for the price survey",
  },

  SHARED_FACEBOOK: {
    event: "Clicked Share for Facebook",
    where: "Post-notarization on the document preview page",
    action: "New tab opens in FB where user can share a link to Notarize",
  },
  SHARED_TWITTER: {
    event: "Clicked Share for Twitter",
    where: "Post-notarization on the document preview page",
    action: "New tab opens in Twitter where user can share a link to Notarize",
  },
  SHARED_LINKEDIN: {
    event: "Clicked Share for LinkedIn",
    where: "Post-notarization on the document preview page",
    action: "New tab opens in LinkedIn where user can share a link to Notarize",
  },

  SHARE_DISCOUNT_OFFER_IMPRESSION: {
    event: "Share Discount Offer Impression",
    where: "Post-notarization on the setup payment screen",
    action: "Share discount offer presented",
  },
  SHARE_DISCOUNT_OFFER_CLAIM_IMPRESSION: {
    event: "Share Discount Offer Claim Impression",
    where: "Post-notarization on the setup payment screen",
    action:
      "Share discount offer claim button presented after signer selected one platform to share",
  },
  SHARE_DISCOUNT_OFFER_SUCCESS_IMPRESSION: {
    event: "Share Discount Offer Success Impression",
    where: "Post-notarization on the setup payment screen",
    action: "Share discount offer success message presented",
  },
  SHARE_DISCOUNT_OFFER_ERROR_IMPRESSION: {
    event: "Share Discount Offer Error Impression",
    where: "Post-notarization on the setup payment screen",
    action: "Share discount offer error message presented",
  },
  SHARE_DISCOUNT_OFFER_PROCESSING_IMPRESSION: {
    event: "Share Discount Offer Processing Impression",
    where: "Post-notarization on the setup payment screen",
    action: "Share discount offer processing message presented",
  },
  SHARE_DISCOUNT_OFFER_CLAIM_CLICKED: {
    event: "Share Discount Offer Claim Clicked ",
    where: "Post-notarization on the setup payment screen",
    action: "Signer clicked on claim button",
  },
  SHARE_DISCOUNT_OFFER_FACEBOOK_CLICKED: {
    event: "Share Discount Offer Facebook Clicked",
    where: "Post-notarization on the setup payment screen",
    action: "New tab opens in Facebook where user can share a link to Notarize",
  },
  SHARE_DISCOUNT_OFFER_TWITTER_CLICKED: {
    event: "Share Discount Offer Twitter Clicked",
    where: "Post-notarization on the setup payment screen",
    action: "New tab opens in Twitter where user can share a link to Notarize",
  },
  SHARE_DISCOUNT_OFFER_LINKEDIN_CLICKED: {
    event: "Share Discount Offer LinkedIn Clicked",
    where: "Post-notarization on the setup payment screen",
    action: "New tab opens in LinkedIn where user can share a link to Notarize",
  },

  // Common
  SEND_APP_LINK_DISPLAYED: {
    event: "Send App Link Displayed",
    where:
      "common component (AppLinkSender) so used in several places (ex: signer tech check explainer screen)",
    action: "user is shown AppLinkSender",
  },
  SEND_APP_LINK_ATTEMPTED: {
    event: "Send App Link Attempted",
    where:
      "common component (AppLinkSender) so used in several places (ex: signer tech check explainer screen)",
    action: "user enters phone number and presses Send to Phone",
  },
  SEND_APP_LINK_SUCCEEDED: {
    event: "Send App Link Succeeded",
    where:
      "common component (AppLinkSender) so used in several places (ex: signer tech check explainer screen)",
    action: "app link successfully sent",
  },
  SEND_APP_LINK_FAILED: {
    event: "Send App Link Failed",
    where:
      "common component (AppLinkSender) so used in several places (ex: signer tech check explainer screen)",
    action: "app link failed to send",
  },
  TEST_VIDEO_CALL_SUCCEEDED: {
    event: "Test Video Call Succeeded",
    where: "during connection test",
    action: "twilio test call successful",
  },
  TEST_VIDEO_CALL_FAILED: {
    event: "Test Video Call Failed",
    where: "during connection test",
    action: "twilio test call failed",
  },

  TRUSTED_ITEM_CLICKED: {
    event: "Trusted banner item clicked",
    where: "Landing pages (Guest Upload or BIT landing)",
    action: "Trusted banner item clicked",
  },

  // Signer Onboarding/Upload Document
  GUEST_UPLOAD_HOME_LOGO_CLICKED: {
    event: "Guest upload home logo clicked",
    where: "Guest upload",
    action: "Guest upload home logo clicked",
  },

  GUEST_TRANSACTION_LOADED: {
    event: "Guest transaction page loaded",
    where: "Guest transaction screen",
    action: "User visited the guest transaction page",
  },
  GUEST_TRANSACTION_CREATED: {
    event: "Guest transaction created",
    where: "Guest transaction screen",
    action:
      "User stuck around on guest transaction screen long enough to successfully create a transaction",
  },
  SIGNER_DOCUMENT_UPLOAD_UPLOAD_TO_BROWSER_ATTEMPTED: {
    event: "Signer Document Upload - Uplod to Browser Attempted",
    where: "retail upload screen",
    action: "retail user attempted to upload a document",
  },
  SIGNER_DOCUMENT_UPLOAD_UPLOAD_TO_BROWSER_SUCCEEDED: {
    event: "Signer Document Upload - Uplod to Browser Succeeded",
    where: "retail upload screen",
    action: "retail user successfully uploaded a document",
  },
  SIGNER_DOCUMENT_UPLOAD_UPLOAD_TO_BROWSER_FAILED: {
    event: "Signer Document Upload - Uplod to Browser Failed",
    where: "retail upload screen",
    action: "retail user experienced an error/failure when trying to upload a document",
  },
  SIGNER_DOCUMENT_UPLOAD_UPLOAD_TO_BACKEND_ATTEMPTED: {
    event: "Signer Document Upload - Uplod to Backend Attempted",
    where: "retail upload screen",
    action: "retail user attempted to continue to document",
  },
  SIGNER_DOCUMENT_UPLOAD_UPLOAD_TO_BACKEND_SUCCEEDED: {
    event: "Signer Document Upload - Uplod to Backend Succeeded",
    where: "retail upload screen",
    action: "retail user successfully to continue to document",
  },
  SIGNER_DOCUMENT_UPLOAD_UPLOAD_TO_BACKEND_FAILED: {
    event: "Signer Document Upload - Uplod to Backend Failed",
    where: "retail upload screen",
    action: "retail user experienced an error/failure when trying to continue to document",
  },
  SIGNER_DOCUMENT_UPLOAD_ACCEPTED_DOCS_LINK_CLICKED: {
    event: "Accepted Docs Link Clicked",
    where: "retail upload screen",
    action: "retail user clicked on the accepted docs link",
  },
  SIGNER_SELECTED_LIBRARY_FILE: {
    event: "Signer Selected Library Document",
    where: "retail upload screen",
    action: "user clicked on one of the documents we're hosting for them",
  },
  SIGNER_CLOSED_LIBRARY_PREVIEW: {
    event: "Signer Closed Library Document Preview",
    where: "retail upload screen",
    action:
      "user clicked on one of the documents we're hosting for them, then closed it without uploading",
  },
  SIGNER_DOWNLOADED_LIBRARY_FILE: {
    event: "Signer Selected Library Document for Upload",
    where: "retail upload screen",
    action:
      "user has clicked to download one of our hosted files, so we're now attempting the download",
  },
  SIGNER_UPLOAD_LIBRARY_FILE_ATTEMPTED: {
    event: "Signer Attempted to Upload Library Document",
    where: "retail upload screen",
    action:
      "user downloaded one of our hosted files and we're now attempting to upload their personal copy",
  },
  SIGNER_SELECTED_VIEW_MORE_DOCUMENTS: {
    event: "Signer Selected to View More Documents",
    where: "retail upload screen",
    action: "user clicked on link to go to support page and see more examples of files to upload",
  },

  ANNOTATION_OPTION_CLICKED: {
    event: "Annotation Option Clicked",
    where: "while viewing pdf",
    action: "user clicked on annotation option",
  },

  // Business New Transaction
  ORGANIZATION_TRANSACTION_EDITOR_ADD_SIGNER_ADDR: {
    event: "Organization Transaction Editor Added Signer Address",
    where: "new transaction screen",
    action: "owner clicked on add signer address link",
  },
  ORGANIZATION_TRANSACTION_EDITOR_REMOVE_SIGNER_ADDR: {
    event: "Organization Transaction Editor Removed Signer Address",
    where: "new transaction screen",
    action: "owner clicked on remove signer address link",
  },
  ORGANIZATION_TRANSACTION_EDITOR_ADD_COSIGNER: {
    event: "Organization Transaction Editor Added CoSigner",
    where: "new transaction screen",
    action: "owner clicked on add cosigner button",
  },
  ORGANIZATION_TRANSACTION_EDITOR_REMOVE_COSIGNER: {
    event: "Organization Transaction Editor Removed CoSigner",
    where: "new transaction screen",
    action: "owner clicked on remove cosigner link",
  },
  ORGANIZATION_TRANSACTION_EDITOR_VIEW_ACCEPTED_DOCUMENTS: {
    event: "Organization Transaction Editor View Accepted Documents",
    where: "new transaction screen",
    action: "owner clicked on link to view list of accepted documents",
  },
  ORGANIZATION_TRANSACTION_EDITOR_UPLOAD_DOCUMENTS_ATTEMPTED: {
    event: "Organization Transaction Editor Upload Documents Attempted",
    where: "new transaction document uploader screen",
    action: "owner clicked on drag drop or click action to upload",
  },
  ORGANIZATION_TRANSACTION_EDITOR_UPLOAD_DOCUMENTS_SUCCEEDED: {
    event: "Organization Transaction Editor Upload Documents Succeeded",
    where: "new transaction document uploader screen",
    action: "owner clicked on add documents to transaction and successfully added documents",
  },
  ORGANIZATION_TRANSACTION_EDITOR_UPLOAD_DOCUMENTS_CANCELLED: {
    event: "Organization Transaction Editor Upload Documents Cancelled",
    where: "new transaction document uploader screen",
    action: "owner clicked on cancel and quit document upload screen",
  },
  ORGANIZATION_TRANSACTION_EDITOR_ADD_TEMPLATES_SUCCEEDED: {
    event: "Organization Transaction Editor Add Templates Succeeded",
    where: "new transaction document uploader screen",
    action: "owner clicked on template upload",
  },
  ORGANIZATION_TRANSACTION_EDITOR_ANNOTATE_DOCUMENTS_ATTEMPTED: {
    event: "Organization Transaction Editor Annotate Documents Attempted",
    where: "new transaction screen",
    action: "owner clicked on fill out document",
  },
  ORGANIZATION_TRANSACTION_EDITOR_ANNOTATE_DOCUMENTS_FINISHED: {
    event: "Organization Transaction Editor Annotate Documents Finished",
    where: "new transaction prepare documents screen",
    action: "owner clicked on save and close and finished filling out documents",
  },
  ORGANIZATION_TRANSACTION_EDITOR_RENAME_DOCUMENT: {
    event: "Organization Transaction Editor Rename Document",
    where: "new transaction screen",
    action: "owner clicked on rename link to modify document name",
  },
  ORGANIZATION_TRANSACTION_EDITOR_DELETE_DOCUMENT: {
    event: "Organization Transaction Editor Delete Document",
    where: "new transaction screen",
    action: "owner clicked on link to remove document from uploaded list",
  },
  ORGANIZATION_TRANSACTION_EDITOR_CHANGE_DOCUMENT_PROPERTIES: {
    event: "Organization Transaction Editor Change Document Properties",
    where: "new transaction screen",
    action: "owner clicked on toggle button to change document properties",
  },
  ORGANIZATION_TRANSACTION_EDITOR_PREVIEW_EMAIL: {
    event: "Organization Transaction Editor Preview Email",
    where: "new transaction screen",
    action: "owner click on link to preview email content",
  },
  ORGANIZATION_TRANSACTION_EDITOR_SAVE_EXIT: {
    event: "Organization Transaction Editor Save Exit",
    where: "new transaction screen",
    action: "owner click on save and exit button to back to transactions screen",
  },
  ORGANIZATION_TRANSACTION_EDITOR_SEND: {
    event: "Organization Transaction Editor Send",
    where: "new transaction screen",
    action: "owner click on send transaction button to save transaction and send BIT email",
  },
  ORGANIZATION_TRANSACTION_EDITOR_SEND_CLOSING_OPS: {
    event: "Organization Transaction Editor Send To Closing Ops",
    where: "edit transaction screen",
    action: "owner click on send transaction button to save transaction and send email",
  },

  // Discounts
  SIGNER_DISCOUNT_MODAL_SHOWN: {
    event: "Signer Shown a Discount Modal",
    where: "First screen seen when logging in or signing up (Dashboard or Uploader)",
    action: "User logged in (or signed up) and was shown a discount they were retargetted for",
  },
  SIGNER_DISCOUNT_MODAL_CLOSED: {
    event: "Signer Closed a Discount Modal",
    where: "First screen seen when logging in or signing up (Dashboard or Uploader)",
    action:
      "User logged in (or signed up) and was shown a discount they were retargetted for, which they then closed",
  },
  SIGNER_DISCOUNT_MODAL_ERROR: {
    event: "Signer Received Error on Discount Modal",
    where: "First screen seen when logging in or signing up (Dashboard or Uploader)",
    action: "We attempted to show a discount modal to a user, which then errored",
  },
  CREATE_SIGNER_ORGANIZATION_FROM_HEADER: {
    event: "User Created Organization from Sign/Send Header",
    where: "header of signer portal",
    action: "User clicked the send button",
  },
  CLICKED_TO_ENTER_SIGN_FLOW: {
    event: "User Clicked to Enter Sign Flow",
    where: "top bar of business/signer app",
    action: "user presses SIGN button in top app frame",
  },
  CLICKED_TO_ENTER_SEND_FLOW: {
    event: "User Clicked to Enter Send Flow",
    where: "top bar of business/signer app",
    action: "user presses SEND button in top app frame",
  },
  CLICKED_GET_STARTED: {
    event: "User Clicked Get Started Button",
    where: "top bar of business/signer app",
    action: "user presses GET STARTED button in top app frame",
  },
  UNSIGNED_BUNDLE_REDIRECT: {
    event: "Signer Unsigned Bundle Redirect",
    where: "Post login check",
    action: "Signer logs in to portal",
  },

  // Settings
  ACCESSED_ACCOUNT_SETTINGS: {
    event: "User Clicked to Enter Account Settings",
    where: "top bar of business/signer app",
    action: "user clicks Account option in header dropdown",
  },
  ACCESSED_PROFILE_SETTINGS: {
    event: "User Clicked to Enter Profile Settings",
    where: "top bar of business/signer app",
    action: "user clicks Profile option in header dropdown",
  },
  ACCESSED_NOTARY_PROFILE_SETTINGS: {
    event: "User Clicked to Enter Notary Profile Settings",
    where: "top bar of business/signer app",
    action: "user clicks Notary Profile option in header dropdown",
  },
  NOTARY_SETTINGS_SEAL_GENERATOR_UNUSABLE: {
    event: "Notary Was Not Able to Use Generated Seal",
    where: "Notary settings wizard",
    action: "Notary clicks 'Seal not working for you?' link",
  },

  // Guest Upload
  GUEST_UPLOAD_LOGIN_CLICKED: {
    event: "Guest Upload - Signer Clicked Login",
    where: "guest upload flow",
    action: "signer clicked sign in",
  },
  GUEST_UPLOAD_FORGOT_PASSWORD_CLICKED: {
    event: "Guest Upload - Signer Clicked Forgot Password",
    where: "guest upload flow after entering email associated with signer that has password set",
    action: "signer clicked forgot password",
  },
  GUEST_UPLOAD_PASSWORD_RESET_EMAIL_SENT: {
    event: "Guest Upload - Signer Clicked Reset Email Password",
    where:
      "guest upload flow after entering email associated with signer that doesn't have password",
    action: "signer entered email and clicked continue",
  },
  GUEST_UPLOAD_FORGOT_PASSWORD_RESET_EMAIL_SENT: {
    event: "Guest Upload - Reset Password Sent",
    where:
      "guest upload flow after entering email associated with signer and clicking forgot password",
    action: "signer entered email and clicked continue",
  },
  GUEST_UPLOAD_UPLOAD_ATTEMPTED: {
    event: "Guest Upload - Upload Attempted",
    where:
      "guest upload flow after uploading doc or coming from template or guest upload verify link",
    action:
      "signer authenticates via email/google, is already authenticated and presses continue, " +
      "or clicks on guest upload verify link",
  },
  GUEST_UPLOAD_UPLOAD_FAILED: {
    event: "Guest Upload - Upload Failed",
    where:
      "guest upload flow after uploading doc or coming from template or guest upload verify link",
    action: "upload failed",
  },
  GUEST_UPLOAD_UPLOAD_SUCEEDED: {
    event: "Guest Upload - Upload Succeeded",
    where:
      "guest upload flow after uploading doc or coming from template or guest upload verify link",
    action: "upload succeeded",
  },
  GUEST_UPLOAD_UPLOAD_TO_BACKEND_ATTEMPTED: {
    event: "Guest Upload - Upload To Backend Attempted",
    where: "guest upload flow",
    action:
      "signer uploads a doc to browser via the uploader or clicks link in common docs, " +
      "doc is then uploaded to backend",
  },
  GUEST_UPLOAD_UPLOAD_TO_BACKEND_FAILED: {
    event: "Guest Upload - Upload To Backend Failed",
    where: "guest upload flow",
    action: "upload failed",
  },
  GUEST_UPLOAD_UPLOAD_TO_BACKEND_SUCCEEDED: {
    event: "Guest Upload - Upload To Backend Succeeded",
    where: "guest upload flow",
    action: "upload succeeded",
  },
  GUEST_UPLOAD_AUTHENTICATION_ATTEMPTED: {
    event: "Guest Upload - Authentication Attempted",
    where:
      "guest upload flow after uploading doc or coming from template or guest upload verify link",
    action: "signer authenticates via email/google",
  },
  GUEST_UPLOAD_AUTHENTICATION_FAILED: {
    event: "Guest Upload - Authentication Failed",
    where:
      "guest upload flow after uploading doc or coming from template or guest upload verify link",
    action:
      "authentication failed (this includes the 'failure' when user enters email for existing signer)",
  },
  GUEST_UPLOAD_AUTHENTICATION_SUCCEEDED: {
    event: "Guest Upload - Authentication Succeeded",
    where:
      "guest upload flow after uploading doc or coming from template or guest upload verify link",
    action: "authentication succeeded",
  },
  GUEST_UPLOAD_AUTHENTICATION_SUCCEEDED_CREATE_PASSWORD: {
    event: "Guest Upload - Authentication Succeeded - Create Password",
    where:
      "guest upload flow after uploading doc or coming from template or guest upload verify link",
    action: "authentication succeeded",
  },
  GUEST_UPLOAD_AUTHENTICATION_SKIPPED: {
    event: "Guest Upload - Authentication Skipped",
    where: "guest upload flow after coming from template link and signer is already logged in",
    action: "signer presses continue",
  },
  GUEST_UPLOAD_AUTHENTICATION_MODAL_OPENED: {
    event: "Guest Upload - Authentication Modal Opened",
    where: "guest upload flow when previewing document",
    action: "signer presses continue",
  },
  GUEST_UPLOAD_AUTHENTICATION_MODAL_CLOSED: {
    event: "Guest Upload - Authentication Modal Closed",
    where: "guest upload flow when authentication modal open",
    action: "signer presses close button",
  },
  GUEST_UPLOAD_AUTHENTICATION_FILE_REMOVED: {
    event: "Guest Upload - File Removed",
    where: "guest upload flow when previewing document",
    action: "signer presses back",
  },
  GUEST_UPLOAD_UPLOAD_TO_BROWSER_ATTEMPTED: {
    event: "Guest Upload - Upload To Browser Attempted",
    where: "guest upload flow",
    action: "signer uploads a doc via the uploader",
  },
  GUEST_UPLOAD_UPLOAD_TO_BROWSER_FAILED: {
    event: "Guest Upload - Upload To Browser Failed",
    where: "guest upload flow",
    action: "upload failed",
  },
  GUEST_UPLOAD_UPLOAD_TO_BROWSER_SUCCEDED: {
    event: "Guest Upload - Upload To Browser Succeeded",
    where: "guest upload flow",
    action: "upload succeeded",
  },
  GUEST_UPLOAD_HANDOFF_ATTEMPTED: {
    event: "Guest Upload - Handoff Attempted",
    where: "guest upload flow when coming from template or guest upload verify link",
    action:
      "authenticate after coming from template link or automatic after guest upload verify link",
  },
  GUEST_UPLOAD_HANDOFF_FAILED: {
    event: "Guest Upload - Handoff Failed",
    where: "guest upload flow when coming from template or guest upload verify link",
    action: "handoff link couldn't be created",
  },
  GUEST_UPLOAD_HANDOFF_SUCCEEDED: {
    event: "Guest Upload - Handoff Succeeded",
    where: "guest upload flow when coming from template or guest upload verify link",
    action: "handoff link created successfully",
  },

  // Mobile Retake Flow / Capture Portal
  NOTARY_SENT_MOBILE_RETAKE: {
    event: "Mobile Id Handoff - Notary Sent Request",
    where: "In meeting, credential analysis",
    action: "Notary clicks the mobile retake button to start flow",
  },
  SIGNER_ENTERED_CAPTURE_APP: {
    event: "Mobile Id Handoff - Signer Entered Capture App",
    where: "signer hit capture.notarize.com",
    action: "signer went to link on their phone",
  },
  SIGNER_CAPTURE_AUTHORIZATION_ATTEMPTED: {
    event: "Mobile Id Handoff - Signer Entered PIN",
    where: "Capture portal, authorization page",
    action: "signer entered pin and tried to authorize",
  },
  SIGNER_CAPTURE_AUTHORIZATION_SUCCESS: {
    event: "Mobile Id Handoff - Signer Successfully Authorized",
    where: "Capture portal, authorization page",
    action: "signer successfully authorized",
  },
  SIGNER_START_CAPTURING_ID: {
    event: "Mobile Id Handoff - Signer Started Capturing Id",
    where: "Capture portal, taking photo id",
    action: "signer clicked button to start camera",
  },
  SIGNER_CAPTURED_ID: {
    event: "Mobile Id Handoff - Signer Captured Id",
    where: "Capture portal, taking photo id",
    action: "signer clicked button to capture id",
  },
  SIGNER_RETAKE_ID: {
    event: "Mobile Id Handoff - Signer Retake ID",
    where: "Capture portal, taking photo id",
    action: "signer clicked retake id button",
  },
  SIGNER_CONFIRMED_ID: {
    event: "Mobile Id Handoff - Signer Confirmed ID",
    where: "Capture portal, taking photo id",
    action: "signer clicked button to confirm id",
  },
  SIGNER_SUBMIT_ID_SUCCESSFUL: {
    event: "Mobile Id Handoff - Signer Submit Id Successful",
    where: "Capture portal, taking photo id",
    action: "signer clicked button to confirm id on back id",
  },
  SIGNER_UPDATE_MEETING_IDS: {
    event: "Mobile Id Handoff - Signer Updated Meeting IDs",
    where: "Capture portal, taking photo id",
    action: "signer clicked button to confirm id on back id",
  },
  SIGNER_SKIP_SECONDARY_ID: {
    event: "Mobile Id Handoff - Signer Skip Secondary ID",
    where: "Capture portal, secondary id",
    action: "signer clicked SKIP button to skip secondary id",
  },
  CAPTURE_PORTAL_CAMERA_ERROR: {
    event: "Mobile Id Handoff - Camera Error",
    where: "Capture portal, taking photo id",
    action: "signer clicked button to take a picture",
  },
  MOBILE_HANDOFF_SMS_ATTEMPTED: {
    event: "Mobile Id Handoff - SMS Attempted",
    where: "Signer portal, mobile handoff modal",
    action: "signer clicked on the button to send himself the mobile handoff SMS",
  },
  MOBILE_HANDOFF_SMS_SUCCESSFUL: {
    event: "Mobile Id Handoff - SMS Successful",
    where: "Signer portal, mobile handoff modal",
    action: "signer received the PIN to access mobile handoff capture app",
  },
  MOBILE_HANDOFF_SMS_FAILED: {
    event: "Mobile Id Handoff - SMS Failed",
    where: "Signer portal, mobile handoff modal",
    action: "signer did not receive the PIN to access mobile handoff capture app",
  },
  MOBILE_HANDOFF_SMS_CANCELLED: {
    event: "Mobile Id Handoff - SMS Cancelled",
    where: "Signer portal, mobile handoff modal",
    action: "signer cancelled the modal to send himself the mobile handoff SMS",
  },

  // Forms
  BUSINESS_SOLUTION_SIGNUP_CLICKED: {
    event: "Business Solutions Create Account Clicked",
    where: "Business Solutions box",
    action: "user clicked on sign up button in business solutions box",
  },
  TRUST_PILOT_BOX_SIGNUP_CLICKED: {
    event: "Trust Pilot Create Account Clicked",
    where: "TrustPilot box",
    action: "user clicked on sign up button in trust pilot box",
  },

  // Points of Contact
  POINT_OF_CONTACT_CALL_ATTEMPTED: {
    event: "Signer Attempted to Call Point of Contact",
    where: "Signer Meeting",
    action: "User clicked on the call button on the Point of Contact Caller",
  },
  POINT_OF_CONTACT_HANG_UP_ATTEMPTED: {
    event: "Signer Attempted to Hang Up on Point of Contact",
    where: "Signer Meeting",
    action: "User clicked on the hang up button on the Point of Contact Caller",
  },
  POINT_OF_CONTACT_CALL_FAILED: {
    event: "Signer Failed to Call Point of Contact",
    where: "Signer Meeting",
    action: "Mutation to call point of contact failed",
  },
  POINT_OF_CONTACT_CALL_STATUS_CHANGE: {
    event: "Signer Called Point of Contact",
    where: "Signer Meeting",
    action: "User should see new status of call",
  },
  POINT_OF_CONTACT_ADDED_TO_TRANSACTION: {
    event: "Org Member Added Point of Contact",
    where: "Organization Transaction Creation",
    action: "User clicked on button to add point of contact",
  },
  POINT_OF_CONTACT_REMOVED_FROM_TRANSACTION: {
    event: "Org Member Removed Point of Contact",
    where: "Organization Transaction Creation",
    action: "User clicked on button to remove point of contact",
  },

  // Tech Check
  SIGNER_TECH_CHECK_FAST_TRACKED: {
    event: "Signer Tech Check Fast Tracked",
    where: "Tech Check",
    action: "Signer fast tracked through tech check",
  },

  // Title Access Events
  DOC_ACCESS_PORTAL_OPENED: {
    event: "Document Access Portal Opened",
    where: "Document Access Portal",
    action: "User opened the access link page",
  },

  // Title Access (Each time one of the print buttons are clicked)
  DOC_ACCESS_PORTAL_PRINT_ALL_BUTTON_CLICKED: {
    event: "Doc Access Print Button Clicked - All",
    where: "Document Access Portal",
    action: "User clicked on button to print all the documents",
  },
  DOC_ACCESS_PORTAL_PRINT_WET_SIGN_BUTTON_CLICKED: {
    event: "Doc Access Print Button Clicked - Wet Sign",
    where: "Document Access Portal",
    action: "User clicked on button to print only the wet sign documents",
  },
  DOC_ACCESS_PORTAL_PRINT_ESIGN_BUTTON_CLICKED: {
    event: "Doc Access Print Button Clicked - eSign",
    where: "Document Access Portal",
    action: "User clicked on button to print only the eSign documents",
  },

  TITLE_AGENCY_ADDRESS_AND_RECORDING_LOCATION_FORM_UPDATED: {
    event: "Address and recording location form updated",
    where: "Title Portal New Transaction",
    action: "User typed or in some other way updated form",
  },
  TITLE_AGENCY_LOOKED_UP_RECORDING_LOCATIONS: {
    event: "Looked up recording locations",
    where: "Title Portal New Transaction",
    action: "User caused form to fetch recording locations",
  },

  // Mobile Handoff
  MOBILE_HANDOFF_ATTEMPTED: {
    event: "Mobile Handoff Attempted",
    where: "On /mobile-handoff route",
    action: "User clicked continue in app button",
  },
  MOBILE_HANDOFF_SUCCEEDED: {
    event: "Mobile Handoff Succeeded",
    where: "On /mobile-handoff route",
    action: "User clicked continue in app button",
  },
  MOBILE_HANDOFF_FAILED: {
    event: "Mobile Handoff Failed",
    where: "On /mobile-handoff route",
    action: "User clicked continue in app button",
  },

  // Lender v2
  UPDATE_PAPER_NOTE_CONSENT: {
    event: "Update Paper Note Consent",
    where: "lender transaction edit v2 page",
    action: "User clicked accept for wet-sign paper note",
  },
  LENDER_V2_TOGGLE_PROMISSORY_NOTE: {
    event: "Toggle Promissory Note",
    where: "lender transaction edit v2 page",
    action: "User toggles to use esign or wetsign for eNote",
  },

  // Video conference events
  VIDEO_CONFERENCE_INITIALIZED: {
    event: "Initialized video conference",
    where: "Meeting",
    action: "Meeting page rendered",
  },

  LENDER_TITLE_INVOICE_RENDERED: {
    event: "modal rendered",
    where: "title transaction edit page",
    action: "no action",
  },
  LENDER_TITLE_INVOICE_ACCEPTED: {
    event: "invoice accepted",
    where: "lender title fee collab invoice modal",
    action: "clicked accept and continue",
  },
});

// transform EVENT_DATA so analytics event names can be used like EVENT[key] instead of EVENT_DATA[key].event
export const EVENT = (Object.keys(EVENT_DATA) as (keyof typeof EVENT_DATA)[]).reduce(
  (events, event) => ({
    ...events,
    [event]: EVENT_DATA[event].event,
  }),
  {},
) as Record<keyof typeof EVENT_DATA, (typeof EVENT_DATA)[keyof typeof EVENT_DATA]["event"]>;
