import type { ReactNode, AriaAttributes } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import Env from "config/environment";
import { loginReset } from "redux/actions/authentication";
import Button from "common/core/button";
import { EnvironmentIndicator } from "common/app_frame/header/dev";
import { Heading } from "common/core/typography";

import Styles from "./index.module.scss";
import type { SsoProvider } from "./proof/screens/saml";

const { hasTestingEnvironmentWarning } = Env;
const CURRENT_PORTAL_SUBDOMAIN = AppSubdomains[CURRENT_PORTAL];

export function SamlLoginHeader({ ssoProvider }: { ssoProvider: SsoProvider }) {
  const logo = ssoProvider.organization.logoUrl;

  return (
    <div aria-live="polite" className={Styles.flexColumn}>
      {logo && (
        <img className={Styles.ssoLogo} src={logo} alt={`${ssoProvider.organization.name} logo`} />
      )}
      <Heading level="h1" textStyle="headingTwo" className={Styles.ssoHeading}>
        <FormattedMessage
          id="43999515-4960-4dc7-895a-0bbb9841ab3a"
          defaultMessage="Single sign-on to"
          tagName="span"
        />
        &nbsp;
        <span className={Styles.ssoCompanyName}>
          <FormattedMessage
            id="9465308a-218c-4373-ba6e-bf538044c898"
            defaultMessage={`<b>{name}</b>`}
            values={{
              b: (msg: ReactNode[]) => <strong>&nbsp;{msg}</strong>,
              name: ssoProvider.organization.name,
            }}
          />
        </span>
      </Heading>
      <Heading level="h2" textStyle="headingFive" className={Styles.ssoSecondaryHeading}>
        <FormattedMessage
          id="8d6e35d8-c10f-48d9-bd46-86229c08c3df"
          defaultMessage={`Authenticate your account by logging into {name}’s single sign-on provider`}
          values={{ name: ssoProvider.organization.name }}
        />
      </Heading>
    </div>
  );
}

export function ForgotPasswordHeader({ liveRegion }: { liveRegion?: AriaAttributes["aria-live"] }) {
  return (
    <>
      <Heading
        level="h1"
        textStyle="headingFour"
        textAlign="center"
        className={Styles.mainHeading}
        aria-live={liveRegion}
      >
        <FormattedMessage
          id="4bd5ba52-9543-400e-90ec-3d5d389d62a1"
          defaultMessage="Reset password"
        />
      </Heading>
    </>
  );
}

type ContinueButtonProps = {
  onClick?: () => void;
  disabled?: boolean;
};

export function Continue({ onClick, disabled }: ContinueButtonProps) {
  return (
    <Button
      className={Styles.marginBottom}
      onClick={onClick}
      type="submit"
      disabled={disabled}
      buttonColor="action"
      buttonSize="large"
      variant="primary"
      fullwidth
    >
      <FormattedMessage id="75cf03e0-80cc-4f7b-b287-a25f03dbbaed" defaultMessage="Continue" />
    </Button>
  );
}

type BackButtonProps = {
  className?: string;
  onBack: () => void;
  ["aria-label"]?: string;
};

export function BackButton({ className, onBack, "aria-label": ariaLabel }: BackButtonProps) {
  return (
    <Button
      buttonColor="action"
      variant="tertiary"
      className={className}
      onClick={onBack}
      aria-label={ariaLabel}
    >
      <FormattedMessage id="c57f3d08-d718-438d-92ac-aa0ac0391845" defaultMessage="Back" />
    </Button>
  );
}

export function EnvironmentWarningHeader() {
  if (!hasTestingEnvironmentWarning) {
    return null;
  }
  return (
    <header className={Styles.envHeader}>
      <EnvironmentIndicator />
      {CURRENT_PORTAL_SUBDOMAIN !== AppSubdomains.admin && (
        <div className={Styles.envMsg}>
          <FormattedMessage
            id="1e864c47-cb62-4aef-ad25-dd503c97505c"
            defaultMessage="This is a testing site. You will not be able to do a valid notarization or eSign transaction here. Please visit <a>this link</a> instead."
            values={{
              a: (text) => (
                <a
                  href={`https://${CURRENT_PORTAL_SUBDOMAIN}.proof.com`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {text}
                </a>
              ),
            }}
          />
        </div>
      )}
    </header>
  );
}

type BackToPasswordScreenProps = {
  backToPasswordScreen: () => void;
};

export function BackToPasswordScreen({ backToPasswordScreen }: BackToPasswordScreenProps) {
  return (
    <Button
      variant="tertiary"
      buttonColor="action"
      className={Styles.samlBackBtn}
      onClick={backToPasswordScreen}
    >
      <FormattedMessage
        id="e9b64870-2d20-4a3b-93d9-3174a819dbf6"
        defaultMessage="Use password instead"
      />
    </Button>
  );
}

export function BackToLoginLink({ redirect }: { redirect?: string | null }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const backToLogin = () => {
    dispatch(loginReset());
    navigate(redirect ? `/login?redirect=${redirect}` : "/login");
  };
  return (
    <Button buttonColor="action" variant="tertiary" onClick={backToLogin}>
      <FormattedMessage id="15b92c91-325b-4795-a8e5-c604d93ba2c2" defaultMessage="Back to login" />
    </Button>
  );
}

type ResetPasswordSubmitButtonProps = {
  submitting: boolean;
  invalid?: boolean;
};

export function ResetPasswordSubmitButton({ submitting, invalid }: ResetPasswordSubmitButtonProps) {
  return (
    <Button
      fullwidth
      buttonColor="action"
      buttonSize="large"
      variant="primary"
      type="submit"
      data-automation-id="set-password-button"
      disabled={invalid}
      className={Styles.marginBottom}
    >
      {submitting ? (
        <FormattedMessage
          id="fbdb639a-6bb6-4bc1-8922-176090423052"
          description="Currently saving text password reset"
          defaultMessage="Updating password..."
        />
      ) : (
        <FormattedMessage
          id="00e9b153-1db1-4d3b-913c-a3765d68f4b7"
          description="Submit password reset button"
          defaultMessage="Update password"
        />
      )}
    </Button>
  );
}
