import classnames from "classnames";
import type { ReactNode } from "react";

import Icon from "common/core/icon";

import Styles from "./index.module.scss";

export type Kind = "infoSubtle" | "infoBlue" | "success" | "danger" | "warning" | "new";
export type BadgeProps = {
  /** Changes the style of the badge */
  kind?: Kind;
  className?: string;
  children: ReactNode;
  withIcon?: string;
  "data-automation-id"?: string;
};

export function Badge({
  kind = "infoBlue",
  children,
  withIcon,
  className,
  "data-automation-id": dataAutomationId,
}: BadgeProps) {
  const cx = classnames(Styles.badge, Styles[kind], className);

  return (
    <div className={cx} data-automation-id={dataAutomationId}>
      {withIcon && <Icon className={Styles.icon} name={withIcon} />}
      <span>{children}</span>
    </div>
  );
}
