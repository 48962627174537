import { useState, useCallback } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { useNavigate } from "react-router-dom";

import { useMutation } from "util/graphql";
import Button from "common/core/button";
import { transactionEditRoute } from "util/routes";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";
import { useTxnDetailsRedesign } from "util/feature_detection";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import PopoutMenuItem from "common/core/popout_menu/item";

import Styles from "./button.module.scss";
import DuplicateOrganizationTransactionMutation from "./duplicate_organization_transaction_mutation.graphql";

type Props = {
  transactionId: string;
  onCloseMenu?: () => void;
};

const messages = defineMessages({
  documentProcessingError: {
    id: "070a8921-6014-4072-afd2-9c963c7544d1",
    defaultMessage:
      "Duplication failed because some documents have not successfully finished processing. Try again soon.",
  },
  unauthorized: {
    id: "c12b063d-8dc2-4d88-b7c2-72a405ba57c4",
    defaultMessage: "Duplication failed due to an authorization error.",
  },
  genericError: {
    id: "b49c3f96-9ccb-43a4-8a74-52c04f34cc08",
    defaultMessage: "Duplication failed due to an unexpected error.",
  },
});

function errorToMessage(error: Error): { id: string; defaultMessage: string } {
  switch (error.message) {
    case "document_processing_error":
      return messages.documentProcessingError;
    case "unauthorized":
      return messages.unauthorized;
    default:
      return messages.genericError;
  }
}

const DuplicateTransactionButton = ({ transactionId, onCloseMenu }: Props) => {
  const [isDuplicating, setIsDuplicating] = useState(false);

  const duplicateOrganizationTransactionMutation = useMutation(
    DuplicateOrganizationTransactionMutation,
  );

  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);
  const intl = useIntl();
  const navigate = useNavigate();

  const duplicateTransaction = useCallback(() => {
    setIsDuplicating(true);
    onCloseMenu && onCloseMenu();

    duplicateOrganizationTransactionMutation({
      variables: {
        input: {
          id: transactionId,
        },
      },
    })
      .then((result) => {
        if (result.data?.duplicateOrganizationTransaction?.organizationTransaction.id) {
          navigate(
            transactionEditRoute({
              id: result.data.duplicateOrganizationTransaction.organizationTransaction.id,
            }),
          );
        } else {
          setIsDuplicating(false);
          pushNotification({
            type: NOTIFICATION_TYPES.DEFAULT,
            subtype: NOTIFICATION_SUBTYPES.ERROR,
            message: intl.formatMessage(messages.genericError),
          });
        }
      })
      .catch((error) => {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          subtype: NOTIFICATION_SUBTYPES.ERROR,
          message: intl.formatMessage(errorToMessage(error)),
        });
        setIsDuplicating(false);
      });
  }, [transactionId]);

  return isTxnDetailsRedesign ? (
    <PopoutMenuItem
      onClick={duplicateTransaction}
      data-automation-id="duplicate-transaction-button"
      loading={isDuplicating}
    >
      <FormattedMessage id="bb9fc25a-d252-40e2-87d0-5db3c95de3b6" defaultMessage="Duplicate" />
      <span className={Styles.button}>
        <FormattedMessage id="2bbf3bd8-0b2f-4888-8635-1fe5042e2709" defaultMessage="Internal" />
      </span>
    </PopoutMenuItem>
  ) : (
    <Button
      variant={!isDuplicating ? "secondary" : "primary"}
      isLoading={isDuplicating}
      onClick={duplicateTransaction}
      buttonColor="action"
      data-automation-id="duplicate-transaction-button"
    >
      <span>
        <FormattedMessage id="bb9fc25a-d252-40e2-87d0-5db3c95de3b6" defaultMessage="Duplicate" />
        <span className={Styles.button}>
          <FormattedMessage id="2bbf3bd8-0b2f-4888-8635-1fe5042e2709" defaultMessage="Internal" />
        </span>
      </span>
    </Button>
  );
};

export default DuplicateTransactionButton;
