import { v1 } from "uuid";

import { setCookie, getCookie } from "util/cookie";

export const MARKETING_SESSION_ID_COOKIE_KEY = "ntrssid";
export const MARKETING_SESSION_DATA_COOKIE_KEY = "ntrssdata";

let session;

function writeSessionData(identifier, data) {
  // session expires after 24 hours
  const expires = new Date(new Date().getTime() + 24 * 3600 * 1000);
  const options = { expires };
  setCookie(MARKETING_SESSION_ID_COOKIE_KEY, identifier, options);
  setCookie(MARKETING_SESSION_DATA_COOKIE_KEY, data, options);
}

const MARKETING_FLOW_COOKIE_KEY = "proof-mflow";

export function writeMarketingFlowData(flowType) {
  // cookie expires after 24 hours
  const expires = new Date(new Date().getTime() + 24 * 3600 * 1000);
  const options = { expires };
  setCookie(MARKETING_FLOW_COOKIE_KEY, flowType, options);
}

export function readMarketingFlowData() {
  return getCookie(MARKETING_FLOW_COOKIE_KEY);
}

// use short keys to make sure cookie length doesn't exceed limit
const defaultSessionData = {
  rs: false, // repeat session
  lp: "", // landing page
  ir: "", // initial referrer
  usrc: "", // utm source
  uca: "", // utm campaign
  ut: "", // utm term
  uco: "", // utm content
  ui: "", // utm id
  um: "", // utm medium
  gc: "", // gclid
};

export default class MarketingSession {
  constructor() {
    let identifier = getCookie(MARKETING_SESSION_ID_COOKIE_KEY);
    const sessionDataValue = getCookie(MARKETING_SESSION_DATA_COOKIE_KEY);
    const sessionData = JSON.parse(sessionDataValue || "{}");

    if (!identifier || !sessionData) {
      const params = new URLSearchParams(window.location.search);
      const marketingSiteRef = params.get("marketingSessionId");

      if (marketingSiteRef) {
        // If sent from the notarize.com marketing site, maintain marketing session ID since cookies don't transfer between domains
        identifier = marketingSiteRef;
      } else {
        identifier = v1(); // time based version
      }

      this.isNewSession = true;
      this.sessionData = defaultSessionData;
      // initialize ssid
      writeSessionData(identifier, JSON.stringify(defaultSessionData));
    } else {
      this.isNewSession = false;
      this.sessionData = Object.assign(defaultSessionData, sessionData);
    }
    this.identifier = identifier;
  }

  setSessionData(sessionData) {
    this.sessionData = sessionData;
    writeSessionData(this.identifier, JSON.stringify(sessionData));
  }

  static get instance() {
    // Re-create an instance if session id has changed.
    // Shouldn't happen in real use but it makes testing easier
    const identifier = getCookie(MARKETING_SESSION_ID_COOKIE_KEY);
    if (session && session.identifier === identifier) {
      return session;
    }
    session = new MarketingSession();
    return session;
  }
}
