import type { ReactElement } from "react";
import { useMatch, Outlet } from "react-router-dom";

import { CompletionRequirement } from "graphql_globals";
import {
  useAnalyticsContext,
  useRemoveBundleKeysFromAnalytics,
} from "common/core/manage_analytics_context";
import { useQuery } from "util/graphql";
import { SIGNER_TYPE_PRIMARY, TRANSACTION_TYPE_BUSINESS } from "constants/analytics";

import BundleAnalyticsContextQuery, {
  type BundleAnalyticsContext_documentBundle_DocumentBundle as DocumentBundle,
} from "./index_query.graphql";

export function getAnalyticsTransactionType(transaction: {
  isMortgage: boolean;
  isRetail: boolean;
}) {
  return transaction.isMortgage
    ? "mortgage"
    : !transaction.isRetail
      ? TRANSACTION_TYPE_BUSINESS
      : "retail";
}

function BundleAnalyticsContextRootContainer(props: { children?: ReactElement }) {
  const { documentBundleId } = useMatch({ path: "/bundle/:documentBundleId", end: false })!.params;
  const { data } = useQuery(BundleAnalyticsContextQuery, {
    variables: { documentBundleId: documentBundleId! },
    fetchPolicy: "no-cache",
  });
  const documentBundle = data?.documentBundle as DocumentBundle | undefined;
  useAnalyticsContext((prevContext) => {
    if (!documentBundle) {
      return;
    }
    const {
      id,
      organizationTransaction,
      payer,
      documents,
      signers,
      completionRequirements,
      kbaRequired,
      signAhead,
      signAheadActionable,
    } = documentBundle;

    return {
      document_bundle_id: id,
      transaction_type: getAnalyticsTransactionType(organizationTransaction),
      transaction_creator_first_name:
        organizationTransaction.transactionCreatorName.firstName || null,
      transaction_creator_last_name:
        organizationTransaction.transactionCreatorName.lastName || null,
      transaction_requires_nsa_meeting: organizationTransaction.requiresNsaMeeting,
      org_id: organizationTransaction.publicOrganization.id || null,
      org_transaction_id: organizationTransaction.id || null,
      document_count: documents.totalCount,
      signer_count: signers?.length,
      signer_type: prevContext.signer_type || SIGNER_TYPE_PRIMARY,
      payer,
      guest_enabled: organizationTransaction.guestEnabled || null,
      proofing_required: Boolean(completionRequirements?.includes(CompletionRequirement.PROOFING)),
      notarization_required: Boolean(
        completionRequirements?.includes(CompletionRequirement.NOTARIZATION),
      ),
      kba_required: kbaRequired,
      signer_can_annotate_some_documents: documentBundle.documents.edges.some(
        (docEdge) => docEdge.node.customerCanAnnotate,
      ),
      witness_required_for_some_documents: documentBundle.documents.edges.some(
        (docEdge) => docEdge.node.witnessRequired,
      ),
      sign_ahead: signAhead,
      sign_ahead_actionable: signAheadActionable,
      phone_number_provided: Boolean(organizationTransaction.customerSigners[0]?.phone),
    };
  });
  useRemoveBundleKeysFromAnalytics();
  return props.children || <Outlet />;
}

export default BundleAnalyticsContextRootContainer;
