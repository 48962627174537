import "./index.scss";

import { memo } from "react";
import { FormattedMessage, useIntl, type IntlShape } from "react-intl";

import { TerminationReasons, type TerminationAnecdotes } from "graphql_globals";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import {
  TERM_REASONS_MESSAGES,
  TERM_ANECDOTES_MESSAGES,
  getReasonForAnecdote,
} from "constants/meeting_termination";

type Props = {
  meeting: {
    failureAnecdote: string | null;
    terminationReasons: (TerminationReasons | null)[] | null;
    terminationAnecdotes: (TerminationAnecdotes | null)[] | null;
  };
};

function renderReason(
  intl: IntlShape,
  reason: TerminationReasons | null | undefined,
  allTerminationAnecdotes: Props["meeting"]["terminationAnecdotes"],
) {
  if (!reason) {
    return null;
  }
  return (
    <div key={reason}>
      <p className="TerminationInfo--header">{intl.formatMessage(TERM_REASONS_MESSAGES[reason])}</p>
      {allTerminationAnecdotes
        ?.filter((anecdote) => getReasonForAnecdote(anecdote!) === reason)
        .map((anecdote) => intl.formatMessage(TERM_ANECDOTES_MESSAGES[anecdote!]))
        .join(", ")}
    </div>
  );
}

function Info({ meeting }: Props) {
  const { terminationReasons, terminationAnecdotes, failureAnecdote } = meeting;
  const intl = useIntl();
  return (
    <DeprecatedDetailGridRow
      title={<FormattedMessage id="1afffc4e-5c88-49d7-8cdf-0e9fa858b436" defaultMessage="Issues" />}
      className="TerminationInfo"
    >
      {terminationReasons && (
        <>
          {/* Put MISC category at bottom of list so it is next to details */}
          {terminationReasons
            .filter((reason) => reason !== TerminationReasons.MISC)
            .map((reason) => renderReason(intl, reason, terminationAnecdotes))}
          {renderReason(
            intl,
            terminationReasons.find((reason) => reason === TerminationReasons.MISC),
            terminationAnecdotes,
          )}
        </>
      )}
      {failureAnecdote && (
        <div>
          <p className="TerminationInfo--header">
            <FormattedMessage id="e528e565-4566-40bd-bc48-9dddb026d608" defaultMessage="Details" />
          </p>
          {failureAnecdote}
        </div>
      )}
    </DeprecatedDetailGridRow>
  );
}

export default memo(Info);
