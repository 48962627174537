import { useQuery } from "util/graphql";

import AccountPaymentHistoryQuery from "./account_query.graphql";
import PersonalPaymentHistoryQuery from "./personal_query.graphql";
import type { Charge } from "./charge_fragment.graphql";

export type UseFetchPayments = (
  first: number,
  offset: number,
) => { loading: true } | { loading: false; payments: Charge[]; totalPayments: number };

const useFetchAccountPayments: UseFetchPayments = (first, offset) => {
  const { data, loading } = useQuery(AccountPaymentHistoryQuery, {
    variables: {
      first,
      offset,
    },
  });
  if (loading) {
    return { loading };
  }
  const payments = data!.viewer.user!.organization!.payments;
  return {
    loading,
    payments: payments.edges.map((edge) => edge.node),
    totalPayments: payments.totalCount,
  };
};

const useFetchPersonalPayments: UseFetchPayments = (first, offset) => {
  const { data, loading } = useQuery(PersonalPaymentHistoryQuery, {
    variables: {
      first,
      offset,
    },
  });
  if (loading) {
    return { loading };
  }
  const payments = data!.viewer.user!.payments;
  return {
    loading,
    payments: payments.edges.map((edge) => edge.node),
    totalPayments: payments.totalCount,
  };
};

export { useFetchAccountPayments, useFetchPersonalPayments };
