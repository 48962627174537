import { forwardRef, type ComponentPropsWithoutRef, type Ref } from "react";

type Props = Omit<ComponentPropsWithoutRef<"img">, "alt"> & { alt: string };

function Svg(props: Props, ref: Ref<HTMLImageElement>) {
  // Even though role="img" is implicit for the <img> tag, when the img src is a .svg,
  // it is announced by a screen reader as a "group",
  // sometimes several times before focus leaves the image.
  // With role="img" specifically applied, it does not do this.

  // eslint-disable-next-line jsx-a11y/no-redundant-roles
  return <img {...props} ref={ref} role="img" alt={props.alt} />;
}

export default forwardRef(Svg);
