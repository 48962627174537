import { FormattedMessage, useIntl } from "react-intl";

import Tab from "common/core/tabs/tab";
import { useQuery } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";
import { SettingsTitle } from "common/settingsv2/common";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";

import PersonalPaymentForm from "./payment_method";
import { FullPersonalPaymentHistory } from "../billing/recent_payments/full_payments";
import CustomerProfileQuery from "./customer_profile_query.graphql";

export const PROFILE_PERSONAL_PAYMENTS_ROUTE = "personal-payments";

export function PersonalPaymentTab({
  enableKeyboardNavigation,
}: {
  enableKeyboardNavigation?: boolean;
}) {
  return (
    <Tab to={PROFILE_PERSONAL_PAYMENTS_ROUTE} enableKeyboardNavigation={enableKeyboardNavigation}>
      <FormattedMessage
        id="58d79d11-4502-4357-b476-766c3fc810ae"
        defaultMessage="Personal Payment"
      />
    </Tab>
  );
}

export function PersonalPayment() {
  useA11y().useDocumentEntitler({
    title: useIntl().formatMessage(useDocumentTitles().settingsProfilePersonalPayments),
  });
  const { data, loading } = useQuery(CustomerProfileQuery);
  const user = data?.viewer.user;
  return (
    <>
      <SettingsTitle>
        <FormattedMessage
          id="27fe26ec-bb42-4430-acd5-2aaba4a13cb2"
          defaultMessage="Personal Details"
        />
      </SettingsTitle>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <PersonalPaymentForm user={user!} />
          <FullPersonalPaymentHistory />
        </>
      )}
    </>
  );
}
