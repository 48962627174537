import { memo } from "react";
import { FormattedMessage } from "react-intl";

import Link from "common/core/link";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import { OrganizationTransactionVariant } from "graphql_globals";

type Notary = {
  email: string | null;
  id: string;
};

type Props = {
  notary: Notary;
  organizationTransaction?: { id: string; transactionVariant: OrganizationTransactionVariant };
};

function AgentGid({ notary, organizationTransaction }: Props) {
  return (
    <DeprecatedDetailGridRow
      title={
        organizationTransaction?.transactionVariant === OrganizationTransactionVariant.PROOF ? (
          <FormattedMessage id="c2751edb-d1e3-4e0c-9406-5e85bb638123" defaultMessage="Agent GID" />
        ) : (
          <FormattedMessage id="04fe601c-f868-4955-9589-da47a26fac8b" defaultMessage="Notary GID" />
        )
      }
    >
      <div className="DetailGrid-data--heading">
        <Link underlined={false} to={`/notary?email=${encodeURIComponent(notary.email!)}`}>
          {notary.id}
        </Link>
      </div>
    </DeprecatedDetailGridRow>
  );
}

export default memo(AgentGid);
