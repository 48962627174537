import { type ReactElement } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import WorkflowModal from "common/modals/workflow_modal";
import { useLogout } from "common/authentication";

type Props = {
  children?: ReactElement;
  viewer: {
    limitedSession: boolean;
  };
};

export default function LimitedSessionWrapper({ viewer, children }: Props) {
  const bundleMatch = useMatch({
    path: "/bundle/:globalID",
    end: false,
  });
  const meetingMatch = useMatch({
    path: "/meeting/:meetingId",
    end: false,
  });
  const esignMatch = useMatch({
    path: "/esign/:globalID",
    end: false,
  });
  const refereeMatch = useMatch({
    path: "referee-meeting/:meetingId",
    end: false,
  });

  const navigate = useNavigate();
  const logout = useLogout();

  if (viewer.limitedSession && !(meetingMatch || bundleMatch || esignMatch || refereeMatch)) {
    return (
      <WorkflowModal
        title={
          <FormattedMessage
            id="ac962e44-6127-4a33-a3b8-461c2117640a"
            defaultMessage="You are leaving your transaction"
          />
        }
        buttons={[
          <Button buttonColor="action" variant="secondary" key="logout" onClick={logout}>
            <FormattedMessage id="e0de9920-95a6-40f1-b18d-b4f5689c5bfa" defaultMessage="Log out" />
          </Button>,
          <Button buttonColor="action" variant="primary" key="go-back" onClick={() => navigate(-1)}>
            <FormattedMessage id="9b6a5817-f2c5-446f-98e0-9b08176fc25f" defaultMessage="Go back" />
          </Button>,
        ]}
        autoFocus
        isSensitive={false}
      >
        <FormattedMessage
          id="2c80569d-a998-4403-90ce-b77f465d52b7"
          defaultMessage="You will be logged out, do you want to go back?"
          tagName="p"
        />
      </WorkflowModal>
    );
  }

  return children || <></>;
}
