import { Tier } from "graphql_globals";
import Apps from "constants/applications";
import AppSubdomains, { filterNonProofPortals } from "constants/app_subdomains";

import { ACCOUNT_TYPES } from "./constants";

type Entry = (typeof Apps)[keyof typeof Apps];

const RANKS = Object.freeze({
  [ACCOUNT_TYPES.LENDER]: 0,
  [ACCOUNT_TYPES.TITLE_AGENCY]: 1,
  [ACCOUNT_TYPES.BUSINESS]: 2,
  [ACCOUNT_TYPES.REALTOR]: 2,
  [ACCOUNT_TYPES.BROKER]: 2,
  [ACCOUNT_TYPES.NOTARY_MARKETPLACE]: 2,
  [ACCOUNT_TYPES.PERSONAL]: 3,
  [ACCOUNT_TYPES.ADMIN]: 4,
  [ACCOUNT_TYPES.NOTARY]: 5,
});

export function computeDefaultType(entry: Entry) {
  switch (entry) {
    case Apps.BUSINESS:
      return ACCOUNT_TYPES.BUSINESS;
    case Apps.ADMIN:
      return ACCOUNT_TYPES.ADMIN;
    case Apps.TITLE_AGENCY:
      return ACCOUNT_TYPES.TITLE_AGENCY;
    case Apps.LENDER:
      return ACCOUNT_TYPES.LENDER;
    case Apps.NOTARY:
      return ACCOUNT_TYPES.NOTARY;
    case Apps.CUSTOMER:
    default:
      return ACCOUNT_TYPES.PERSONAL;
  }
}

export function getTargetAppAccountTypes(entry: Entry | null, userAccountTypes: string[] | null) {
  if (!userAccountTypes?.length) {
    return [entry];
  }
  return userAccountTypes
    .map((accountType) => accountType.toLowerCase() as keyof typeof RANKS)
    .sort((a, b) => {
      if (a === entry) {
        return -1;
      } else if (b === entry) {
        return 1;
      } else if (RANKS[a] < RANKS[b]) {
        return -1;
      }
      return 1;
    });
}

type Application = (typeof Apps)[keyof typeof Apps];
export function getAccessibleSubdomains(entry: Entry | null, userAccountTypes: string[] | null) {
  const accountTypes = getTargetAppAccountTypes(entry, userAccountTypes) as Application[];
  const relatedSubdomains = accountTypes.map((accountType) => AppSubdomains[accountType]);

  // list of accessible subdomains minus app, since it is accessed separately from the rest.
  const accessibleSubdomains = filterNonProofPortals(relatedSubdomains).filter(
    (subdomain) => subdomain !== "app",
  );

  return accessibleSubdomains;
}

export function canLoginWithGoogle(options: { tier: Tier; selectedAccount: Entry }) {
  return options.tier !== Tier.PRO_TRIAL && options.selectedAccount !== Apps.TITLE_AGENCY;
}

export function getLinkForQrStream() {
  const url = new URL(window.location.href);
  url.searchParams.set("qr_handoff", "true");

  return url.toString();
}
