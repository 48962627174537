import "./exception.scss";

import { memo } from "react";
import { FormattedMessage } from "react-intl";
// eslint-disable-next-line no-restricted-imports -- type only import
import type { LocalAudioTrack, LocalVideoTrack } from "twilio-video";

type LocalTrack = LocalVideoTrack | LocalAudioTrack;
export type MediaError = {
  kind: LocalTrack["kind"];
  name: "NotAllowed" | "NotFound" | "NotReadable";
};
type MessageProps = {
  id?: string;
  error: MediaError;
};

export function getMediaErrorNameFromException(error: Error | null): MediaError["name"] | null {
  const { message, name } = error ?? { message: "" };
  if (/^Unexpected error: The .+ cannot be published$/.test(message)) {
    return "NotFound";
  }
  switch (name) {
    case "NotReadableError":
      return "NotReadable";
    case "NotAllowedError":
      return "NotAllowed";
    case "NotFoundError":
      return "NotFound";
    default:
      return null;
  }
}

export const MediaErrorMessage = memo(({ error, id }: MessageProps) => (
  <div id={id} className="MediaDeviceError">
    {error.name === "NotReadable" && (
      <FormattedMessage
        id="00796d85-e6b0-4cb6-85a6-8c2e68f482bc"
        defaultMessage="We cannot access your {kind, select, audio{microphone} other{camera}}. If another application has access to the device, you will need to close them out and refresh your browser."
        values={{ kind: error.kind }}
      />
    )}
    {error.name === "NotFound" && (
      <FormattedMessage
        id="32c78ea7-19d0-4d3e-9922-46d461042687"
        defaultMessage="Your browser cannot find a {kind, select, audio{microphone} other{camera}} device. Please make sure your device is connected and working."
        values={{ kind: error.kind }}
      />
    )}
    {error.name === "NotAllowed" && (
      <FormattedMessage
        id="787988a8-58ab-4e5c-bdb8-c2464b03f79e"
        defaultMessage="Access browser permissions are blocked for {kind, select, audio{microphone} other{camera}} device. Please allow your browser access permissions through browser settings."
        values={{ kind: error.kind }}
      />
    )}
  </div>
));
