import { memo, type ComponentProps } from "react";
import { FormattedMessage } from "react-intl";

import UserFullName from "common/user/user_full_name";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import { OrganizationTransactionVariant } from "graphql_globals";

type User = ComponentProps<typeof UserFullName>["user"];
type Props = {
  meeting: {
    publicNotaryDetails: User;
    organizationTransaction?: { id: string; transactionVariant: OrganizationTransactionVariant };
  };
};

function AgentName({ meeting: { publicNotaryDetails, organizationTransaction } }: Props) {
  return (
    <DeprecatedDetailGridRow
      title={
        organizationTransaction?.transactionVariant === OrganizationTransactionVariant.PROOF ? (
          <FormattedMessage id="b5d3b99b-3c7a-421e-bbaf-02819078fdd2" defaultMessage="Agent Name" />
        ) : (
          <FormattedMessage
            id="0deec2fc-63ae-45fd-ab09-fb37d3dc5ca3"
            defaultMessage="Notary Public"
          />
        )
      }
    >
      <div className="DetailGrid-data--heading">
        <UserFullName user={publicNotaryDetails} />
      </div>
    </DeprecatedDetailGridRow>
  );
}

export default memo(AgentName);
