import {
  Children,
  forwardRef,
  cloneElement,
  createElement,
  type ReactElement,
  type ComponentType,
  type ReactNode,
  type ComponentPropsWithoutRef,
  type Ref,
} from "react";
import classnames from "classnames";

import { useId } from "util/html";
import { Paragraph } from "common/core/typography";

import {
  FormattedFieldError,
  isAriaInvalid,
  type AriaInvalidPropValue,
  useNestedError,
} from "../error";
import { type FieldPath, type UseFormReturn, type FieldValues, type FieldError } from "..";
import Styles from "./index.module.scss";
import RequiredAsterisk from "../required-asterisk";
import type { PresentNode } from "../option";

type LabelProps = ComponentPropsWithoutRef<"label"> & {
  disabled?: boolean;
  invalid?: boolean;
  required?: boolean;
};
type LegendProps = ComponentPropsWithoutRef<"legend"> & {
  label: PresentNode;
  disabled?: boolean;
  invalid?: boolean;
  required?: boolean;
  sublabel?: PresentNode;
};
type RowProps = ComponentPropsWithoutRef<"div">;
type FormRowProps = RowProps & { fullWidth?: boolean };
type RowElementProps = {
  id?: string;
  "aria-invalid": AriaInvalidPropValue;
  // can't enforce props of children so can't just use `never` here
  render?: unknown;
};
type AutoRowProps<FormValues extends FieldValues> = {
  id?: string;
  "aria-describedby"?: string;
  "data-automation-id"?: string;
  fullWidth?: boolean;
  placeholder?: string;
  rowButton?: ReactNode;
  className?: string;
  errorClassname?: string;
  form: Pick<UseFormReturn<FormValues>, "control" | "register">;
  name: FieldPath<FormValues>;
  registerOptions?: Parameters<UseFormReturn<FormValues>["register"]>[1];
  helperText?: { text: ReactNode; placement: "above" | "below" };
  dynamicHeightError?: boolean;
  disabled?: boolean;
  spellCheck?: "true" | "false";
  highlightLabelOnError?: boolean;
} & (
  | {
      children: ReactElement<RowElementProps>;
      as?: never;
    }
  | {
      children?: never;
      as: ComponentType<RowElementProps>;
    }
) &
  // Label types prevents for both label/aria-label & aria-labelledby to be present at the same time
  // requried prop is only present with a label prop
  (| {
        label: ReactNode;
        "aria-label"?: string;
        "aria-labelledby"?: never;
        required?: boolean;
      }
    | {
        label?: ReactNode;
        "aria-label": string;
        "aria-labelledby"?: never;
        required?: boolean;
      }
    | {
        label?: never;
        "aria-label"?: never;
        "aria-labelledby": string;
        required?: boolean;
      }
    | {
        label?: never;
        "aria-label"?: never;
        "aria-labelledby"?: never;
        required?: never;
      }
  );

function Label(
  { className, disabled, children, required, invalid, ...props }: LabelProps,
  ref: Ref<HTMLLabelElement>,
) {
  return (
    <label
      {...props}
      className={classnames(
        Styles.label,
        className,
        disabled && Styles.labelDisabled,
        invalid && Styles.labelInvalid,
      )}
      ref={ref}
    >
      {children}
      {required && <RequiredAsterisk />}
    </label>
  );
}
const LabelWithRef = forwardRef(Label);

function Legend(props: LegendProps, ref: Ref<HTMLLegendElement>) {
  return (
    <>
      <legend
        {...props}
        className={classnames(
          Styles.legend,
          Styles.label,
          props.className,
          props.disabled && Styles.labelDisabled,
          props.invalid && Styles.invalid,
        )}
        ref={ref}
      >
        <span>
          {props.label}
          {props.required && <RequiredAsterisk />}
        </span>
        {props.sublabel && (
          <Paragraph className={Styles.legendSublabel} textColor="subtle">
            {props.sublabel}
          </Paragraph>
        )}
      </legend>
    </>
  );
}
const LegendWithRef = forwardRef(Legend);

export function FormRow({ fullWidth, ...props }: FormRowProps) {
  return (
    <div
      {...props}
      className={classnames(Styles.row, props.className, {
        [Styles.fullWidth]: fullWidth,
      })}
    />
  );
}

export function MultipartFormRow(props: RowProps) {
  return <div {...props} className={classnames(Styles.multiRow, props.className)} />;
}

export function SpacedMultipartFormRow(props: RowProps) {
  return (
    <div {...props} className={classnames(Styles.multiRow, props.className)}>
      {Children.map(
        props.children,
        (child: ReactNode, index) =>
          child && (
            <div key={index} className={Styles.spacedInput}>
              {child}
            </div>
          ),
      )}
    </div>
  );
}

export function AutomaticFormRow<FormValues extends FieldValues>(props: AutoRowProps<FormValues>) {
  const {
    id,
    form,
    name,
    label,
    registerOptions,
    fullWidth,
    children,
    "aria-label": ariaLabel,
    "aria-describedby": ariaDescribedBy,
    "aria-labelledby": ariaLabelledBy,
    "data-automation-id": automationId,
    placeholder,
    rowButton,
    className,
    helperText,
    required,
    disabled,
    errorClassname,
    spellCheck,
    highlightLabelOnError,
  } = props;

  if (props.children?.props.render) {
    throw new Error(
      `AutomaticFormRow does not support children with render props (like Controller)`,
    );
  }

  const error = useNestedError({ control: form.control, name });
  // Sometimes we clone hook form's Controller component and pass it a child with an id, which will not be accessible off props.children.props. In this case we just pass our own id.
  const originalId = id || props.children?.props.id;
  const uniqueId = useId();
  const realId = originalId || uniqueId;
  const autoElementProps = {
    ...form.register(name, registerOptions),
    ...makeAutomaticFormRowElementProps({
      id: realId,
      error,
      ariaLabel,
      ariaLabelledBy,
      ariaDescribedBy,
      automationId,
      placeholder,
      disabled,
    }),
    // spellcheck is false when disabled because we do not want red underline when
    // theres nothing user can do to fix it
    spellCheck: disabled ? "false" : spellCheck,
  };

  const element = children
    ? cloneElement(children, autoElementProps)
    : createElement(props.as, autoElementProps);

  return (
    <AutomaticFormRowLayout
      fullWidth={fullWidth}
      className={className}
      label={label}
      disabled={disabled}
      required={required}
      rowButton={rowButton}
      helperText={helperText}
      name={name}
      errorClassname={errorClassname}
      error={error}
      id={realId}
      highlightLabelOnError={highlightLabelOnError}
    >
      {element}
    </AutomaticFormRowLayout>
  );
}

// to enforce users of AutomaticFormRowLayout to have the same props
export function makeAutomaticFormRowElementProps({
  id,
  error,
  ariaLabel,
  ariaLabelledBy,
  ariaDescribedBy,
  automationId,
  placeholder,
  disabled,
}: {
  id: string;
  error: FieldError | undefined;
  ariaLabel: string | undefined;
  ariaLabelledBy: string | undefined;
  ariaDescribedBy: string | undefined;
  automationId: string | undefined;
  placeholder: string | undefined;
  disabled: boolean | undefined;
}) {
  return {
    id,
    "aria-invalid": isAriaInvalid(error),
    "aria-label": ariaLabel,
    "aria-labelledby": ariaLabelledBy,
    "aria-describedby": ariaDescribedBy,
    "data-automation-id": automationId,
    placeholder,
    disabled,
  };
}

export function AutomaticFormRowLayout<FormValues extends FieldValues>(props: {
  fullWidth?: AutoRowProps<FormValues>["fullWidth"];
  className?: AutoRowProps<FormValues>["className"];
  label?: AutoRowProps<FormValues>["label"];
  disabled?: AutoRowProps<FormValues>["disabled"];
  required?: AutoRowProps<FormValues>["required"];
  highlightLabelOnError?: AutoRowProps<FormValues>["highlightLabelOnError"];
  rowButton?: AutoRowProps<FormValues>["rowButton"];
  helperText?: AutoRowProps<FormValues>["helperText"];
  name: AutoRowProps<FormValues>["name"];
  errorClassname?: AutoRowProps<FormValues>["errorClassname"];
  error: FieldError | undefined;
  id: string;
  children: ReactNode;
}) {
  return (
    <FormRow fullWidth={props.fullWidth} className={props.className}>
      {props.label && (
        <div className={Styles.labelRow}>
          <LabelWithRef
            htmlFor={props.id}
            disabled={props.disabled}
            invalid={Boolean(props.highlightLabelOnError && props.error)}
          >
            {props.label}
            {props.required && <RequiredAsterisk />}
          </LabelWithRef>
          {props.rowButton}
        </div>
      )}
      {props.helperText?.placement === "above" && (
        <p className={Styles.helperText}>{props.helperText.text}</p>
      )}
      {props.children}
      {props.helperText?.placement === "below" && (
        <p className={classnames(Styles.helperText, Styles.helperTextBelow)}>
          {props.helperText.text}
        </p>
      )}
      <FormattedFieldError
        inputName={props.name}
        error={props.error}
        className={props.errorClassname}
      />
    </FormRow>
  );
}

export { LabelWithRef as Label, LegendWithRef as Legend };
