import { memo } from "react";
import { FormattedMessage } from "react-intl";

import { DeprecatedDetailGridRow } from "common/details/grid/row";

type Props = {
  overrideReason: string;
};

function OverrideReason({ overrideReason }: Props) {
  return (
    <DeprecatedDetailGridRow
      title={
        <FormattedMessage
          id="48230b83-cf70-499d-9450-4ce7067977d2"
          defaultMessage="Missing Requirements Override Reason"
        />
      }
    >
      {overrideReason}
    </DeprecatedDetailGridRow>
  );
}

export default memo(OverrideReason);
