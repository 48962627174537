import classnames from "classnames";
import type { ReactNode } from "react";

import Styles from "./index.module.scss";

type Props = {
  settingsView?: boolean;
  ariaLabel?: string;
  children: ReactNode;
  modalView?: boolean;
  className?: string | null;
  enableKeyboardNavigation?: boolean;
  fullWidthTabs?: boolean;
};

type FocusElement = HTMLElement | null | undefined;

export const handleKeyDown = (key: string, target: EventTarget) => {
  const targetEl = target as HTMLElement;
  if (key === "ArrowLeft") {
    const sibling = targetEl.previousSibling;
    const focusElement = (sibling ? sibling : targetEl.parentElement?.lastChild) as FocusElement;
    focusElement?.focus();
  }
  if (key === "ArrowRight") {
    const sibling = targetEl.nextSibling;
    const focusElement = (sibling ? sibling : targetEl.parentElement?.firstChild) as FocusElement;
    focusElement?.focus();
  }
};

// modalView is a boolean indicating whether Tabs is being used in a modal and
// should have the grey borders on the top and bottom
export default function Tabs({
  children,
  modalView = false,
  settingsView = false,
  className = null,
  ariaLabel,
  enableKeyboardNavigation = false,
  fullWidthTabs = false,
}: Props) {
  const cx = classnames(
    className,
    Styles.tabs,
    modalView && Styles.tabsModal,
    settingsView && Styles.tabsSettings,
    fullWidthTabs && Styles.fullWidthTabs,
  );
  return (
    <div
      aria-label={ariaLabel}
      role="tablist"
      className={cx}
      data-automation-id="tabs-container"
      tabIndex={-1}
      {...(enableKeyboardNavigation && {
        onKeyDown: ({ key, target }) => handleKeyDown(key, target),
      })}
    >
      {children}
    </div>
  );
}
