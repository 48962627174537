import "./index.scss";

import { useState } from "react";
import PropTypes from "prop-types";
import { defineMessages, injectIntl } from "react-intl";

import { Payer } from "graphql_globals";
import { captureException } from "util/exception";
import Button from "common/core/button";
import Select from "common/form/inputs/select";
import IllustrationModal from "common/modals/illustration_modal";
import { Mutation } from "util/graphql/mutation";

import UPDATE_ACTIVATED_TRANSACTION_PAYER_MUTATION from "./update_activated_transaction_payer_mutation.graphql";

const messages = defineMessages({
  [Payer.CUSTOMER]: {
    id: "119bd149-0925-4b31-b1af-6b701ed5b8e1",
    description: "customer",
    defaultMessage: "Customer",
  },
  [Payer.ORGANIZATION]: {
    id: "4396d6c4-94ed-4eab-9d73-416a51879beb",
    description: "organization",
    defaultMessage: "Organization",
  },
  [Payer.ORGANIZATION_ACH]: {
    id: "e069be6b-197e-4fc7-b23d-6c186befd5e6",
    description: "organization_ach",
    defaultMessage: "Organization ACH",
  },
  [Payer.NOTARIZE]: {
    id: "e6fce4e7-e2c9-41d6-b12c-e54a4a9b0fe7",
    description: "notarize",
    defaultMessage: "Notarize",
  },
  [Payer.COVERED]: {
    id: "b82ee6c1-90ef-449a-a1ec-2b8823520e3a",
    defaultMessage: "Covered",
  },
  none: {
    id: "45d3bd2d-0de7-46f3-9fc3-ebf6469fd294",
    description: "none",
    defaultMessage: "N/A",
  },
  confirm: {
    id: "255d5046-f1e2-4171-898c-1133d1cda16f",
    description: "confirm",
    defaultMessage: "Confirm Change",
  },
  title: {
    id: "5e26dbec-2087-43fd-9ca8-125db04b0e14",
    description: "title",
    defaultMessage: "Payer Type",
  },
  error: {
    id: "205a2e8d-890c-4ce3-90de-e67295a19ee3",
    description: "error",
    defaultMessage: "An Error Occured",
  },
  ok: {
    id: "a63255dd-e2d3-440a-9089-c06ca5bb514b",
    description: "ok",
    defaultMessage: "Ok",
  },
});

function PayerType(props) {
  const {
    bundle,
    payerType,
    intl,
    canUpdate,
    onUpdate,
    updateActivatedTransactionPayer,
    chargeId,
  } = props;

  return (
    <div>
      {canUpdate && payerType !== Payer.COVERED ? (
        <EditablePayerTypeContainer
          initialPayer={payerType}
          onUpdate={onUpdate}
          updateActivatedTransactionPayer={updateActivatedTransactionPayer}
          transactionId={bundle.transaction?.id}
          chargeId={chargeId}
          intl={intl}
        />
      ) : (
        <StaticPayerType payer={payerType} intl={intl} />
      )}
    </div>
  );
}

PayerType.propTypes = {
  canUpdate: PropTypes.bool.isRequired,
};

function StaticPayerType({ payer, intl }) {
  return (
    <div className="NotarizationDetails--summary-payer-type">
      {intl.formatMessage(messages[payer || "none"])}
    </div>
  );
}

StaticPayerType.propTypes = {
  payer: PropTypes.oneOf(Object.values(Payer)),

  // injectIntl
  intl: PropTypes.object.isRequired,
};

StaticPayerType.defaultProps = {
  payer: null,
};

const SELECTABLE_PAYERS = [
  Payer.CUSTOMER,
  Payer.ORGANIZATION,
  Payer.ORGANIZATION_ACH,
  Payer.NOTARIZE,
];

function EditablePayerType(props) {
  const { initialPayer, transactionId, intl, updateActivatedTransactionPayer, onUpdate, chargeId } =
    props;

  const [payer, setPayer] = useState(initialPayer);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const payerItems = SELECTABLE_PAYERS.map((payerType) => ({
    value: payerType,
    label: intl.formatMessage(messages[payerType]),
  }));

  async function updatePayer() {
    setIsLoading(true);

    try {
      await updateActivatedTransactionPayer({
        variables: {
          mutationInput: {
            transactionId,
            chargeId,
            payer,
          },
        },
      });
      onUpdate();
    } catch (e) {
      captureException(e);
      setError(e.message);
    }

    setIsLoading(false);
  }

  return (
    <div className="PayerType--editable">
      <Select
        automationId="payer-type-select"
        className="PayerType--editable--select"
        items={payerItems}
        onChange={(newPayer) => setPayer(newPayer)}
        value={payer}
        disabled={isLoading}
        placeholder={intl.formatMessage(messages.none)}
      />
      {initialPayer !== payer && (
        <Button
          automationId="payer-type-confirm-button"
          onClick={updatePayer}
          isLoading={isLoading}
          buttonColor="action"
          variant="primary"
        >
          {intl.formatMessage(messages.confirm)}
        </Button>
      )}
      {error && (
        <IllustrationModal
          automationPrefix="payer-type-error-modal"
          title={intl.formatMessage(messages.error)}
          buttons={[
            <Button key="ok" onClick={() => setError(null)} buttonColor="action" variant="primary">
              {intl.formatMessage(messages.ok)}
            </Button>,
          ]}
        >
          {error}
        </IllustrationModal>
      )}
    </div>
  );
}

EditablePayerType.propTypes = {
  initialPayer: PropTypes.oneOf(Object.values(Payer)),
  transactionId: PropTypes.string.isRequired,
  chargeId: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,

  // EditablePayerTypeContainer
  updateActivatedTransactionPayer: PropTypes.func.isRequired,

  // injectIntl
  intl: PropTypes.object.isRequired,
};

EditablePayerType.defaultProps = {
  payer: null,
};

function EditablePayerTypeContainer(props) {
  return (
    <Mutation mutation={UPDATE_ACTIVATED_TRANSACTION_PAYER_MUTATION}>
      {(updateActivatedTransactionPayer) => (
        <EditablePayerType
          {...props}
          updateActivatedTransactionPayer={updateActivatedTransactionPayer}
        />
      )}
    </Mutation>
  );
}

export default injectIntl(PayerType);
