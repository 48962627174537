import { memo, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { segmentTrack, segmentPage } from "util/segment";
import { SETTINGS_PATH } from "util/routes";
import { CURRENT_PORTAL } from "constants/app_subdomains";
import Apps from "constants/applications";
import { readMarketingFlowData } from "util/marketing_session";

// Restrict the pages we track on, so as to not overwhelm our Segment data cap
const TRACKED_PATHS = Object.freeze(
  new Set([
    "/signup/starter",
    "/signup/pro",
    "/signup/premium",
    "/signup/upload",
    "/upload",
    "/onboarding",
    SETTINGS_PATH,
  ]),
);

// To be able to add additional data to the page call, manually call on these pages
const SKIP_PAGE = Object.freeze(new Set(["/signup/upload", "/upload"]));

function LocationAnalytics() {
  const { pathname } = useLocation();
  useEffect(() => {
    if (TRACKED_PATHS.has(pathname)) {
      segmentTrack("Loaded An App Page");
    }

    if (!SKIP_PAGE.has(pathname)) {
      // If in the signer portal, we want to enrich the page call with the flow type value
      const isSignerPortal = CURRENT_PORTAL === Apps.CUSTOMER;
      const flowType = isSignerPortal ? readMarketingFlowData() : null;
      const options = {
        // transaction_type is the field that's historically been used in analytics to identify retail/business/mortage/etc
        // it's been configured in segment + third party integrations for segmentation already, so while unideal naming, we'll maintain
        // to avoid disruption
        ...(Boolean(flowType) && { transaction_type: flowType }),
      };
      segmentPage(options);
    }
  }, [pathname]);
  return null;
}

export default memo(LocationAnalytics);
