import { useState } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import Webcam from "util/react_user_media";
import type { MediaError } from "common/video_conference/exception";
import {
  DESKTOP_VIDEO_HEIGHT,
  DESKTOP_VIDEO_WIDTH,
} from "common/video_conference/audio_video_settings/constraints";
import { Paragraph } from "common/core/typography";

import DevicePicker from "../device_picker";
import Styles from "./webcam.module.scss";

const VIDEO_SETTINGS = {
  height: { ideal: DESKTOP_VIDEO_HEIGHT },
  width: { ideal: DESKTOP_VIDEO_WIDTH },
  facingMode: "user",
};

const MESSAGES = defineMessages({
  webcamPlaceholder: {
    id: "c5db4d0e-ded7-46de-934a-bed9fe63efa9",
    description: "Tech check webcam placeholder text",
    defaultMessage: "Select a webcam",
  },
  webcamLabel: {
    id: "d8d57592-9023-42e9-81b2-5959acce3683",
    description: "Tech check webcam label text",
    defaultMessage: "Webcam",
  },
  webcamRequired: {
    id: "67daf071-ce60-4ec0-92a0-57400a7a65d2",
    defaultMessage: "A camera is required.",
  },
});

type Props = {
  onDeviceMissing: () => void;
  onDeviceSelect: (newId: string | null, properties?: { autoSelected?: boolean }) => void;
  selectedDeviceId: string | null | undefined;
  onDeviceError?: (error: MediaError | null) => void;
  defaultCameraDirection?: "front" | "back";
  autoFocus?: boolean;
};

export function WebcamPicker({
  onDeviceMissing,
  onDeviceError,
  onDeviceSelect,
  selectedDeviceId,
  defaultCameraDirection,
  autoFocus,
}: Props) {
  const intl = useIntl();
  return (
    <DevicePicker
      label={intl.formatMessage(MESSAGES.webcamLabel)}
      placeholder={intl.formatMessage(MESSAGES.webcamPlaceholder)}
      deviceKind="videoinput"
      selectedDeviceId={selectedDeviceId}
      onDeviceError={onDeviceError}
      onDeviceSelect={onDeviceSelect}
      onDeviceMissing={onDeviceMissing}
      requiredMessaging={intl.formatMessage(MESSAGES.webcamRequired)}
      defaultDeviceLabel={defaultCameraDirection}
      autoFocus={autoFocus}
    />
  );
}

export function WebcamPreview({ webcamId }: { webcamId: string | null | undefined }) {
  const [feedFailed, setFeedFailed] = useState(true);

  return (
    <>
      <div className={Styles.preview}>
        <Webcam
          // This must include the "exact" key for Safari!
          video={{
            deviceId: { exact: webcamId },
            ...VIDEO_SETTINGS,
          }}
          audio={false}
          onFailure={() => setFeedFailed(true)}
        />
      </div>
      <Paragraph textAlign="center">
        <FormattedMessage
          id="ecd8ee7b-92b4-47a4-83c4-33990ebab01f"
          defaultMessage="Background effects and images won't show in the preview"
        />
      </Paragraph>
      {feedFailed && (
        <Paragraph textAlign="center">
          <FormattedMessage
            id="19779542-5548-498e-80ae-6b8ac633bd05"
            defaultMessage="If you're using an external device, please ensure it is properly connected before you retry. If the problem persists, please quit your browser and try again, or try another supported browser like Chrome or Firefox."
          />
        </Paragraph>
      )}
    </>
  );
}
