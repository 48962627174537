import "./cert_uploader.scss";

import { memo, useState, useCallback, type ComponentProps } from "react";
import { FormattedMessage } from "react-intl";

import { FormattedDate } from "common/core/format/date";
import { NotaryProfileWizardUploader } from "common/notary/profile_wizard/upload";
import { certUpload } from "util/uploader";

import PassPhraseModal from "./passphrase_modal";

type UploaderProps = ComponentProps<typeof NotaryProfileWizardUploader>;
type Props = {
  onSubmit: (key: string, passphrase: string) => Promise<unknown>;
  notaryProfile: { certExpiry: string | null };
  onCancel: () => void;
};

const CERT_LABEL = (
  <FormattedMessage
    id="79d52f04-d3ad-49c5-a063-36eff270efd5"
    defaultMessage="Digital Certificate File"
  />
);

const uploadCertFn: UploaderProps["uploadFn"] = ({ asset }) => certUpload({ cert: asset });

function NotaryCertUploader({ onSubmit, notaryProfile, onCancel }: Props) {
  const { certExpiry } = notaryProfile;
  const [key, setKey] = useState<string | null>("");
  const [passphrase, setPassphrase] = useState("");
  const [showPassphraseModal, setShowPassphraseModal] = useState(false);
  const handleChangeKey = useCallback((key: string | null) => {
    if (key) {
      setShowPassphraseModal(true);
    }
    setPassphrase("");
    setKey(key);
  }, []);
  const handlePassphraseSubmit = useCallback(
    () =>
      onSubmit(key!, passphrase)
        .then(() => {
          setShowPassphraseModal(false);
        })
        .catch((e) => {
          setPassphrase("");
          return Promise.reject(e);
        }),
    [key, passphrase, onSubmit],
  );
  return (
    <div className="NotaryProfileWizardCertUploader">
      <NotaryProfileWizardUploader
        persistedValue={certExpiry && CERT_LABEL}
        onChange={handleChangeKey}
        uploadFn={uploadCertFn}
      />
      {certExpiry && (
        <div className="NotaryProfileWizardCertUploader--Expires">
          <FormattedMessage
            id="60aa42f1-4a54-459c-87a0-627238707c4c"
            defaultMessage="Expires {expiration}"
            values={{ expiration: <FormattedDate value={certExpiry} /> }}
          />
        </div>
      )}
      {showPassphraseModal && (
        <PassPhraseModal
          onSubmit={handlePassphraseSubmit}
          onChangePassphrase={setPassphrase}
          passphrase={passphrase}
          onClose={() => {
            setShowPassphraseModal(false);
            setKey("");
            onCancel();
          }}
        />
      )}
    </div>
  );
}

export default memo(NotaryCertUploader);
