import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useLocation } from "react-router-dom";

import { useFeatureFlag } from "common/feature_gating";
import { Heading, Paragraph } from "common/core/typography";
import StackedLinks from "common/modals/full_page_responsive_modal/stacked_links";
import {
  ABSOLUTE_PROFILE_PERSONAL_DETAILS_ROUTE,
  ABSOLUTE_PROFILE_PERSONAL_PAYMENTS_ROUTE,
  ABSOLUTE_PROFILE_PRIVACY_ROUTE,
  ABSOLUTE_PROFILE_PROOF_CERTIFICATE_ROUTE,
  ABSOLUTE_PROFILE_USER_ACCOUNT_ROUTE,
} from "common/settingsv2/mobile_user_settings";
import { CONSUMER_CERTIFICATES, USER_SETTINGS_PRIVACY_TAB } from "constants/feature_gates";

import Styles from "./index.module.scss";

const LABELS = defineMessages({
  userAccount: {
    id: "278931a1-e3d8-4c12-a71d-18cf2d5e8570",
    defaultMessage: "User account",
  },
  userAccountSub: {
    id: "1d9d1e69-294f-46dd-b6f2-c147b44a8b6f",
    defaultMessage: "Name, email, password and timezone",
  },
  personalDetails: {
    id: "5b15d192-1af8-4775-a530-17b9aced16ec",
    defaultMessage: "Personal details",
  },
  personalDetailsSub: {
    id: "6e68644b-205a-4406-8cd7-80f6fd544078",
    defaultMessage: "Pronouns, date of birth and address",
  },
  payment: {
    id: "a4738e12-c1c4-4e4b-af0e-cbf1513ec1f2",
    defaultMessage: "Payment",
  },
  paymentSub: {
    id: "44c438e4-4815-4c3b-a72b-3fe07fdef624",
    defaultMessage: "Credit card information",
  },
  privacy: {
    id: "21018481-bb3c-4b27-96dc-7a3ef7290cb5",
    defaultMessage: "Privacy",
  },
  privacySub: {
    id: "c530ba4d-7f07-44d7-a0ef-bbeacbaef982",
    defaultMessage: "Account deletion",
  },
  proofCertificate: {
    id: "eb7d2f94-085c-4c87-945d-611525323dee",
    defaultMessage: "Proof Card",
  },
  proofCertificateSub: {
    id: "0cf9aa5f-6e08-426c-80fa-e0e74b6f4c7a",
    defaultMessage: "Certificate",
  },
});

export default function UserProfile() {
  const intl = useIntl();
  const { search } = useLocation();
  const userSettingsPrivacyTabEnabled = useFeatureFlag(USER_SETTINGS_PRIVACY_TAB);
  const userSettingsProofCertificateTabEnabled = useFeatureFlag(CONSUMER_CERTIFICATES);
  const links = [
    {
      label: intl.formatMessage(LABELS.userAccount),
      sublabel: intl.formatMessage(LABELS.userAccountSub),
      path: ABSOLUTE_PROFILE_USER_ACCOUNT_ROUTE,
      search,
    },
    {
      label: intl.formatMessage(LABELS.personalDetails),
      sublabel: intl.formatMessage(LABELS.personalDetailsSub),
      path: ABSOLUTE_PROFILE_PERSONAL_DETAILS_ROUTE,
      search,
    },
    {
      label: intl.formatMessage(LABELS.payment),
      sublabel: intl.formatMessage(LABELS.paymentSub),
      path: ABSOLUTE_PROFILE_PERSONAL_PAYMENTS_ROUTE,
      search,
    },
  ];

  if (userSettingsPrivacyTabEnabled) {
    links.push({
      label: intl.formatMessage(LABELS.privacy),
      sublabel: intl.formatMessage(LABELS.privacySub),
      path: ABSOLUTE_PROFILE_PRIVACY_ROUTE,
      search,
    });
  }
  if (userSettingsProofCertificateTabEnabled) {
    links.push({
      label: intl.formatMessage(LABELS.proofCertificate),
      sublabel: intl.formatMessage(LABELS.proofCertificateSub),
      path: ABSOLUTE_PROFILE_PROOF_CERTIFICATE_ROUTE,
      search,
    });
  }
  return (
    <>
      <div className={Styles.header}>
        <Heading textStyle="subtitle" level="h1">
          <FormattedMessage
            id="cb891bf0-2d19-486e-ab49-e60aaabc222a"
            defaultMessage="User Profile"
          />
        </Heading>
        <Paragraph>
          <FormattedMessage
            id="3e4f4721-316a-411b-b415-a77af2eeeb56"
            defaultMessage="User details and preferences"
          />
        </Paragraph>
      </div>
      <StackedLinks fullWidth links={links} />
    </>
  );
}
