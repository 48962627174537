import "./mortgage_borrower_input.scss";

import { FormattedMessage } from "react-intl";
import { type ChangeEvent, useState } from "react";
import classnames from "classnames";

import MaskedInput from "common/form/inputs/masked_input";
import { userFullName } from "util/user";
import SROnly from "common/core/screen_reader";
import { useId } from "util/html";
import { IconButton } from "common/core/button/icon_button";

const CX = "MortgageBorrowerInput";
const SSN_LENGTH = 9;

function cleanSsn(dirtySsn: string) {
  return dirtySsn.replace(/\D/g, "");
}

type MortgageBorrower = {
  firstName: string | null;
  hasSsn: boolean;
  id: string;
  lastName: string | null;
  middleName: string | null;
};

type Props = {
  onUpdateMortgageBorrower: (id: string, ssn?: string) => void;
  borrower: MortgageBorrower;
};

function MortgageBorrowerInput({ onUpdateMortgageBorrower, borrower }: Props) {
  const [currentSsn, setCurrentSsn] = useState("");
  const [showError, setShowError] = useState(false);

  const onChangeSsn = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrentSsn(event.target.value);
    setShowError(false);
  };

  const updateMortgageBorrower = () => {
    const cleanedSsn = cleanSsn(currentSsn);
    if (cleanedSsn.length === SSN_LENGTH) {
      onUpdateMortgageBorrower(borrower.id, cleanedSsn);
    } else {
      setShowError(true);
    }
  };

  const clearMortgageBorrowerSsn = () => {
    setCurrentSsn("");
    onUpdateMortgageBorrower(borrower.id, "");
  };

  const borrowerName = userFullName(borrower);

  const borrowerSsnLabel = useId();
  const borrowerSsnDescribedBy = useId();

  return (
    <>
      <div className={CX}>
        <div
          id={borrowerSsnLabel}
          className={classnames(`${CX}--name`, showError && `${CX}--name--error`)}
          data-automation-id="borrower-ssn-name"
        >
          {borrowerName}
        </div>
        <SROnly>
          <p id={borrowerSsnDescribedBy}>
            <FormattedMessage
              id="0675040b-eb82-4454-b83d-8837975231ee"
              defaultMessage="SSN input for {borrowerName}"
              values={{ borrowerName }}
            />
          </p>
        </SROnly>

        {borrower.hasSsn ? (
          <div className={`${CX}--borrower`}>
            <div className={`${CX}--borrower--input`}>
              <div className={`${CX}--borrower--input__filled`}>
                <input
                  type="text"
                  className="Form-input--readonly"
                  value="*** - ** - ****"
                  disabled
                  aria-labelledby={borrowerSsnLabel}
                  aria-describedby={borrowerSsnDescribedBy}
                />
              </div>
              <IconButton
                name="x-small"
                className={`${CX}--borrower--input--icon`}
                onClick={clearMortgageBorrowerSsn}
                automationId="clear-mortgage-borrower-ssn"
                label={
                  <FormattedMessage
                    id="b9a2a375-b166-4795-8c67-f34d6fc8b2fe"
                    defaultMessage="Remove borrower social security number"
                  />
                }
                buttonColor="dark"
                buttonSize="condensed"
                variant="tertiary"
              />
            </div>
          </div>
        ) : (
          <>
            <div className={`${CX}--empty`}>
              <MaskedInput
                value={currentSsn}
                maskType="ssn"
                onChange={onChangeSsn}
                onBlur={updateMortgageBorrower}
                automationId="borrower-ssn-input"
                aria-labelledby={borrowerSsnLabel}
                aria-describedby={borrowerSsnDescribedBy}
                className={showError && `${CX}--input--error`}
              />
            </div>
            {showError && (
              <div className={`${CX}--error validation-message`}>
                <FormattedMessage
                  id="df743a9b-8b26-4418-b5fb-d8471406027c"
                  description="validSsn"
                  defaultMessage="Please enter a valid SSN."
                />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default MortgageBorrowerInput;
