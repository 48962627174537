import { memo } from "react";
import { FormattedMessage } from "react-intl";

import { DeprecatedDetailGridRow } from "common/details/grid/row";

type Props = {
  meeting: {
    publicNotaryDetails: null | { notaryId: string | null };
  };
};

function AgentRegistration({ meeting }: Props) {
  return (
    <DeprecatedDetailGridRow
      title={
        <FormattedMessage
          id="bd46acc1-c879-4648-9604-fb8263b7332c"
          defaultMessage="Notary Commission Number"
        />
      }
    >
      {meeting.publicNotaryDetails?.notaryId}
    </DeprecatedDetailGridRow>
  );
}

export default memo(AgentRegistration);
