/**
 * This module exists as a source for the fonts used for typed signature creation.
 */

export type FontConfig = {
  font: string;
  /**
   * When calculating the max font size used for the signature, the padding is used as an override to
   * decrease the height and width of the canvas to account for odd letter spacing, etc.
   *
   * For example, if you had a canvas height of 60, and the font had a padding of 20, the max font size
   * would fit the font in a height of 40px.
   */
  padding: number;
  onlyAutogeneration?: true;
};

export const FONT_SCALE = 1.5;

/*
  List of fonts used in Auto Assign for hybrids
  these are covered in pre load of fonts in asset_preloader/index.tsx
  Using the SignatureFont import from graphql_globals
    auto_selected_at: 0, font: CastroScript, padding: 0
    auto_selected_at: 1, font: FromSkyler, padding: 0
    auto_selected_at: 2, font: SignaturaMonoline, padding: 0
    auto_selected_at: 3, font: Notera2, padding: 0
    auto_selected_at: 4, font: JustMeAgainDownHere, padding: 0
    auto_selected_at: 5, font: SnowWithVibur, padding: 0
    auto_selected_at: 6, font: CoveredByYourGrace, padding: 0
    auto_selected_at: others, font: CaveatBrush, padding: 0
  Padding is 0 for all currently used fonts, this property can be deprecated
 */

const FONTS: readonly FontConfig[] = Object.freeze([
  {
    font: "FromSkyler",
    padding: 0,
  },
  {
    font: "BethEllen",
    padding: 0,
  },
  {
    font: "Caveat",
    padding: 0,
  },
  {
    font: "CoveredByYourGrace",
    padding: 0,
  },
  {
    font: "CastroScript",
    padding: 0,
  },
]);

export default FONTS;
