import { memo, useEffect, type ReactElement } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import { NetworkStatus } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";
import FormattedPrice from "common/core/format/formatted_price";
import IllustrationModal from "common/modals/illustration_modal";
import { includesOrgAdminRole } from "util/organization";

import TierPayment from "../payment";
import type { ViewerTierBlocker as Viewer } from "./index_fragment.graphql";

type Props = {
  children?: ReactElement;
  viewer: Viewer;
};

const MESSAGES = defineMessages({
  fixTitle: {
    id: "98609acc-c8ec-424b-98be-3a49d7a83470",
    defaultMessage: "Fix Payment",
  },
  addTitle: {
    id: "6d07955a-81f0-48d3-8732-e2acf49efca2",
    defaultMessage: "Add Payment",
  },
  paymentExpired: {
    id: "918fe13d-6d6c-4eb9-be85-f76175f20dda",
    defaultMessage: "Payment Method Expired",
  },
  contactAdmin: {
    id: "e6536bc3-1da4-467a-a468-75802e582ec4",
    defaultMessage:
      "Please contact an administrator in your organization to update payment information",
  },
});

function TierBlockerInner({ viewer, children }: Props) {
  const intl = useIntl();
  const navigate = useNavigate();
  const { organization, roles } = viewer.user!;
  const noPaymentRequired = !organization?.tierRequiresPayment;

  useEffect(() => {
    if (noPaymentRequired && !children) {
      navigate("/", { replace: true });
    }
  }, []);

  if (noPaymentRequired) {
    return children || <LoadingIndicator />;
  }

  if (includesOrgAdminRole(roles)) {
    return (
      <TierPayment
        title={intl.formatMessage(
          organization.defaultPaymentSource === null ? MESSAGES.addTitle : MESSAGES.fixTitle,
        )}
        tierName={organization.activeTier.displayName}
        tierPrice={
          <FormattedPrice
            cents={organization.activeTier.prices.platformSubscription}
            hideFractionDigits
          />
        }
        tierInterval="month" // TODO: query for actual payment interval (RSMB-2606)
        forceNewPayment
      />
    );
  }

  return (
    <IllustrationModal title={intl.formatMessage(MESSAGES.paymentExpired)}>
      {intl.formatMessage(MESSAGES.contactAdmin)}
    </IllustrationModal>
  );
}

function TierBlocker({
  networkStatus,
  viewer,
  children,
}: Props & { networkStatus: NetworkStatus }) {
  return networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.refetch ? (
    <LoadingIndicator />
  ) : (
    <TierBlockerInner viewer={viewer}>{children}</TierBlockerInner>
  );
}

export default memo(TierBlocker);
