import "./index.scss";

import { Component } from "react";
import PropTypes from "prop-types";
import { defineMessages, useIntl } from "react-intl";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";
import { encodeSearchParams, newPathWithPreservedSearchParams } from "util/location";
import LoadingIndicator from "common/core/loading_indicator";
import Button from "common/core/button";
import { isValid } from "common/form/inputs/phone/number";
import { segmentTrack } from "util/segment";
import { saveUserDevicePreferences, getUserDevicePreferences } from "util/device_preferences";
import { grantedPermissions } from "util/permissions";
import { Heading } from "common/core/typography";
import SROnly from "common/core/screen_reader";

import Explainer from "./explainer";
import AudioSetup from "./audio/setup";
import VideoSetup from "./video/setup";
import ConnectionTest from "./connection";
import { EXPLAINER, CONNECTION, AUDIO, VIDEO, BLOCKED_STEP, WARNING_STEP } from "./step";
import { GRANTED_PERM, BLOCKED_PERM, REQUESTED_PERM, UNREQUESTED_PERM } from "./permission";

const messages = defineMessages({
  title: {
    id: "acc401fe-43d9-402c-b920-fec743f4660d",
    defaultMessage: "Set up audio & video",
  },
  back: {
    id: "199bd155-bc1d-47d7-ae62-2fb78e900634",
    defaultMessage: "Back",
  },
  continue: {
    id: "10c6ac45-2bdd-41a3-a547-40ac8cc21070",
    defaultMessage: "Continue",
  },
  toVideoSetup: {
    id: "a2d099aa-abe6-4126-a02d-623b8e40a334",
    defaultMessage: "to video setup",
  },
  toDashboard: {
    id: "afa5ab2c-fed2-44b1-93bd-0c109bb73b44",
    defaultMessage: "to dashboard",
  },
  useSelectedCamera: {
    id: "1105f6e5-7536-4f5a-be8e-835453a44b5c",
    defaultMessage: "Confirm camera settings",
  },
  confirmAudioSettings: {
    id: "da897c9c-917f-4196-a076-149931b9f56d",
    defaultMessage: "Confirm audio settings",
  },
  connectAnyway: {
    id: "e146451b-eeee-4ebb-a92d-120f48f467e0",
    defaultMessage: "Connect anyway",
  },
  allowLocationAccess: {
    id: "1461ba53-8a7a-43ef-b513-0d87347bb8ea",
    defaultMessage: "Allow location access",
  },
});

class TechCheck extends Component {
  state = {
    missingEquipment: {},
    checklistWarning: {},
    permissions: null,
    selectedMicrophoneId: null,
    selectedSpeakerId: null,
    selectedWebcamId: null,
    speakerRequired: true,
    phoneNumber: "",
    isPhoneNumberValid: false,
  };

  redirectToBackPath = () => {
    const { steps, currentStep, basePath, backRoute, exitRoute, navigate } = this.props;
    const currentStepIndex = steps.findIndex((step) => step.name === currentStep);
    const priorStepIndex = currentStepIndex - 1;
    if (priorStepIndex < 0) {
      navigate(backRoute || exitRoute);
    } else {
      const { name } = steps[priorStepIndex];
      const backPath = `${basePath}/${name}`;
      navigate(newPathWithPreservedSearchParams(backPath));
    }
  };

  trackSelectedDevices = () => {
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        function getDeviceLabel(id) {
          const d = devices.find((device) => device.deviceId === id);
          return d ? d.label : "";
        }
        const { selectedMicrophoneId, selectedSpeakerId, selectedWebcamId, phoneNumber } =
          this.state;
        const selectedDevices = {
          webcam: getDeviceLabel(selectedWebcamId),
          microphone: getDeviceLabel(selectedMicrophoneId),
          speaker: getDeviceLabel(selectedSpeakerId),
          phone: phoneNumber || "",
        };
        segmentTrack(`${this.props.roleLabel} Selected devices after Tech Check`, selectedDevices);
      })
      .catch(() => {
        segmentTrack(
          `${this.props.roleLabel} Failed to retrieve selected devices after Tech Check`,
        );
      });
  };

  gotoNextPage = () => {
    const { navigate, nextRoute } = this.props;
    const { selectedMicrophoneId, selectedSpeakerId, selectedWebcamId, phoneNumber } = this.state;
    saveUserDevicePreferences({
      webcamDeviceId: selectedWebcamId,
      microphoneDeviceId: selectedMicrophoneId,
      speakerDeviceId: selectedSpeakerId,
      phoneNumber,
    });
    this.trackSelectedDevices();
    navigate(nextRoute);
  };

  gotoNextStep(type) {
    const { steps, navigate, basePath, searchParams } = this.props;
    const currentStepIndex = steps.findIndex((step) => step.name === type);
    const nextStepIndex = currentStepIndex + 1;
    if (steps.length === nextStepIndex) {
      this.gotoNextPage();
    } else {
      const { name } = steps[nextStepIndex];
      const search = encodeSearchParams(searchParams);
      navigate(`${basePath}/${name}?${search}`);
    }
  }

  setupComponent() {
    const {
      currentStep,
      role,
      onVideoPermissionMount,
      onVideoConfigurationMount,
      onAudioPermissionMount,
      onAudioConfigurationMount,
      onExplainerMount,
      completionRequirements,
      notaryProfile,
    } = this.props;

    const { permissions } = this.state;

    switch (currentStep) {
      case EXPLAINER:
        return (
          <Explainer
            key={currentStep}
            completionRequirements={completionRequirements}
            onMount={onExplainerMount}
          />
        );
      case VIDEO:
        return (
          <VideoSetup
            role={role}
            key={currentStep}
            permissionStatus={permissions[VIDEO]}
            missingEquipment={this.state.missingEquipment[VIDEO]}
            onAccessClick={() => this.handleChangePermission(VIDEO, REQUESTED_PERM)}
            onAccessGranted={() => this.handleEquipmentAccessGranted(VIDEO)}
            onAccessBlocked={() => this.handleChangePermission(VIDEO, BLOCKED_PERM)}
            onWebcamSelection={this.handleWebcamSelection}
            onMissingEquipment={() => this.handleMissingEquipment(VIDEO)}
            selectedWebcamId={this.state.selectedWebcamId}
            onPermissionMount={onVideoPermissionMount}
            onConfigurationMount={onVideoConfigurationMount}
            notaryProfile={notaryProfile}
          />
        );
      case AUDIO:
        return (
          <AudioSetup
            key={currentStep}
            notaryProfile={notaryProfile}
            permissionStatus={permissions[AUDIO]}
            missingEquipment={this.state.missingEquipment[AUDIO]}
            onAccessClick={() => this.handleChangePermission(AUDIO, REQUESTED_PERM)}
            onAccessGranted={() => this.handleEquipmentAccessGranted(AUDIO)}
            onAccessBlocked={() => this.handleChangePermission(AUDIO, BLOCKED_PERM)}
            onSpeakerSelection={this.handleSpeakerSelection}
            onMissingEquipment={() => this.handleMissingEquipment(AUDIO)}
            onMicrophoneSelection={this.handleMicrophoneSelection}
            selectedMicrophoneId={this.state.selectedMicrophoneId}
            selectedSpeakerId={this.state.selectedSpeakerId}
            onPermissionMount={onAudioPermissionMount}
            onConfigurationMount={onAudioConfigurationMount}
            isContinueDisabled={this.isAudioContinueDisabled()}
            onSpeakerSelectionDisabled={this.handleSpeakerDisabled}
          />
        );
      case CONNECTION:
        return (
          <ConnectionTest
            key={currentStep}
            completionRequirements={completionRequirements}
            onStart={this.handleConnectionStart}
            onSuccess={this.handleConnectionSuccess}
            onWarning={this.handleConnectionWarning}
            onFailure={this.handleConnectionFailure}
            onRetry={this.handleConnectionRetry}
            onConnectAnyway={this.handleConnectAnyway}
            onExit={this.handleConnectionExit}
          />
        );
      default:
        throw new Error(`Unknown Tech Check currentStep: ${currentStep}`);
    }
  }

  handleEquipmentAccessGranted = (type) => {
    segmentTrack(`No missing equipment for ${type}.  Granting Access.`);
    this.setState((state) => ({
      missingEquipment: { ...state.missingEquipment, [type]: false },
    }));
    this.handleChangePermission(type, GRANTED_PERM);
  };

  handleChangePermission = (type, newPermission) => {
    segmentTrack(`${this.props.roleLabel} ${type} Permission ${newPermission}`);
    this.setState((state) => ({
      permissions: { ...state.permissions, [type]: newPermission },
    }));
  };

  handleMissingEquipment = (type) => {
    segmentTrack(`${this.props.roleLabel} Missing Equipment for Step ${type} in Tech Check`);
    this.setState((state) => ({
      missingEquipment: { ...state.missingEquipment, [type]: true },
    }));
    this.handleChangePermission(type, BLOCKED_PERM);
  };

  handleSpeakerSelection = (selectedSpeakerId) => {
    this.setState(() => ({ selectedSpeakerId }));
  };

  handleMicrophoneSelection = (selectedMicrophoneId) => {
    this.setState(() => ({ selectedMicrophoneId }));
  };

  handleWebcamSelection = (selectedWebcamId) => {
    this.setState(() => ({ selectedWebcamId }));
  };

  handlePhoneNumberChange = (phoneNumber) => {
    this.setState({ phoneNumber }, this.updateCanSend);
  };

  updateCanSend = () => {
    const { phoneNumber, isPhoneNumberValid: wasPhoneNumberValid } = this.state;

    const isPhoneNumberValid = isValid({ phoneNumber });

    if (isPhoneNumberValid !== wasPhoneNumberValid) {
      this.setState({ isPhoneNumberValid });
    }
  };

  handleAudioConfirmation = (type) => {
    if (this.props.notaryProfile) {
      segmentTrack(`${this.props.roleLabel} Confirmed Step ${type} in Tech Check`);
      this.gotoNextPage();
    } else {
      this.handleConfirmation(type);
    }
  };

  handleConfirmation = (type) => {
    segmentTrack(`${this.props.roleLabel} Confirmed Step ${type} in Tech Check`);
    this.gotoNextStep(type);
  };

  handleConnectionStart = () => {
    segmentTrack(`${this.props.roleLabel} Connection Test Began in Tech Check`);
  };

  handleConnectionSuccess = (speed, shouldContinue) => {
    segmentTrack(
      `${this.props.roleLabel} Connection Test Succeeded in Tech Check with Good Connection`,
      { speed_mb_sec: speed },
    );
    if (shouldContinue) {
      // 2 seconds is an arbitrary time to give a user chance to see test was successful
      setTimeout(() => this.gotoNextPage(), 2000);
    }
  };

  handleConnectionWarning = (speed) => {
    segmentTrack(
      `${this.props.roleLabel} Connection Test Succeeded in Tech Check with Weak Connection`,
      { speed_mb_sec: speed },
    );
    this.setState((state) => ({
      checklistWarning: { ...state.checklistWarning, [CONNECTION]: true },
    }));
  };

  handleConnectionFailure = (speed) => {
    const { roleLabel, onConnectionFailure } = this.props;
    segmentTrack(`${roleLabel} Connection Test Failed in Tech Check`, { speed_mb_sec: speed });

    this.setState((state) => ({
      missingEquipment: { ...state.missingEquipment, [CONNECTION]: true },
    }));
    onConnectionFailure();
  };

  handleConnectionRetry = () => {
    segmentTrack(`${this.props.roleLabel} Retried a Failed Connection Test in Tech Check`);
    this.setState((state) => ({
      checklistWarning: { ...state.checklistWarning, [CONNECTION]: false },
      missingEquipment: { ...state.missingEquipment, [CONNECTION]: false },
    }));
  };

  handleConnectAnyway = () => {
    segmentTrack(`${this.props.roleLabel} Elected to Proceed With Weak Connection in Tech Check`);
    this.gotoNextPage();
  };

  handleConnectionExit = () => {
    const { navigate, exitRoute } = this.props;
    navigate(exitRoute);
  };

  handleSpeakerDisabled = () => {
    this.setState({ speakerRequired: false });
  };

  isAudioContinueDisabled = () => {
    // Firefox disables speaker selection, so we only check that if its around.
    const { currentStep } = this.props;
    const { permissions, selectedMicrophoneId, selectedSpeakerId, speakerRequired } = this.state;
    return Boolean(
      permissions[currentStep] !== GRANTED_PERM ||
        !selectedMicrophoneId ||
        (!selectedSpeakerId && speakerRequired),
    );
  };

  componentDidMount() {
    // Start preload of twilio video so its ready for later call to `canMakeTestCall`.
    import(/* webpackPrefetch: true */ "twilio-video");
    grantedPermissions().then((permissions) => {
      const { webcam, microphone, speaker } = getUserDevicePreferences();
      this.setState(() => ({
        selectedWebcamId: webcam,
        selectedMicrophoneId: microphone,
        selectedSpeakerId: speaker,
        permissions: {
          [VIDEO]: permissions.webcam ? GRANTED_PERM : UNREQUESTED_PERM,
          [AUDIO]: permissions.microphone ? GRANTED_PERM : UNREQUESTED_PERM,
        },
      }));
    });
  }

  renderSteps() {
    const { steps, currentStep } = this.props;
    if (currentStep === EXPLAINER) {
      return null;
    }
    const { missingEquipment, checklistWarning, permissions } = this.state;
    const checkList = steps
      .filter(({ name }) => name !== EXPLAINER)
      .map(({ name, label }, stepIndex) => {
        let status = null;
        if (permissions[name] === BLOCKED_PERM || missingEquipment[name]) {
          status = BLOCKED_STEP;
        } else if (checklistWarning[name]) {
          status = WARNING_STEP;
        }
        return {
          id: stepIndex,
          text: label,
          stepKey: name,
          status,
        };
      });
    return checkList;
  }

  renderContinueButton(currentStep) {
    const { intl } = this.props;
    switch (currentStep) {
      case EXPLAINER:
        return (
          <Button
            buttonColor="action"
            variant="primary"
            automationId="tech-check-continue-button"
            key="continue-explainer"
            onClick={() => this.handleConfirmation(currentStep)}
          >
            {intl.formatMessage(messages.continue)}
            <SROnly>{intl.formatMessage(messages.toVideoSetup)}</SROnly>
          </Button>
        );
      case VIDEO: {
        const disabled =
          this.state.permissions[currentStep] !== GRANTED_PERM || !this.state.selectedWebcamId;
        return (
          <Button
            buttonColor="action"
            variant="primary"
            disabled={disabled}
            automationId="confirm-video-settings-button"
            key="continue-video"
            onClick={() => this.handleConfirmation(currentStep)}
          >
            {intl.formatMessage(messages.useSelectedCamera)}
          </Button>
        );
      }
      case AUDIO: {
        return (
          <Button
            buttonColor="action"
            variant="primary"
            disabled={this.isAudioContinueDisabled()}
            automationId="confirm-audio-settings-button"
            key="continue-audio"
            onClick={() => this.handleAudioConfirmation(currentStep)}
          >
            {intl.formatMessage(messages.confirmAudioSettings)}
          </Button>
        );
      }
      case CONNECTION:
        return null;
      default:
        throw new Error(`Unknown Tech Check currentStep: ${currentStep}`);
    }
  }

  render() {
    if (!this.state.permissions) {
      return <LoadingIndicator />;
    }
    const { currentStep, intl } = this.props;
    const buttonList = [this.renderContinueButton(currentStep)];
    if (currentStep !== EXPLAINER) {
      buttonList.unshift(
        <Button
          buttonColor="action"
          variant="secondary"
          key="back"
          automationId="back-button"
          onClick={this.redirectToBackPath}
        >
          {intl.formatMessage(messages.back)}
          {currentStep === VIDEO && <SROnly>{intl.formatMessage(messages.toDashboard)}</SROnly>}
          {currentStep === AUDIO && <SROnly>{intl.formatMessage(messages.toVideoSetup)}</SROnly>}
        </Button>,
      );
    }

    const buttons = currentStep !== CONNECTION ? buttonList : null;

    return (
      <div className="TechCheck">
        <Heading level="h1" textStyle="headingThree" textAlign="center">
          {intl.formatMessage(messages.title)}
        </Heading>
        <div className="TechCheck--body">{this.setupComponent()}</div>
        <div className="TechCheck--buttons">{buttons}</div>
      </div>
    );
  }
}

TechCheck.propTypes = {
  basePath: PropTypes.string.isRequired,
  nextRoute: PropTypes.string.isRequired,
  backRoute: PropTypes.string,
  exitRoute: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  roleLabel: PropTypes.string.isRequired,
  steps: PropTypes.array.isRequired,
  currentStep: PropTypes.string.isRequired,
  onConnectionFailure: PropTypes.func.isRequired,
  onVideoPermissionMount: PropTypes.func,
  onVideoConfigurationMount: PropTypes.func,
  onAudioPermissionMount: PropTypes.func,
  onAudioConfigurationMount: PropTypes.func,
  onExplainerMount: PropTypes.func,
};

const noop = () => {};

TechCheck.defaultProps = {
  onConnectionFailure: noop,
  onVideoPermissionMount: noop,
  onVideoConfigurationMount: noop,
  onAudioPermissionMount: noop,
  onAudioConfigurationMount: noop,
  onExplainerMount: noop,
};

export default (props) => {
  const intl = useIntl();
  useA11y().useDocumentEntitler({
    title: intl.formatMessage(useDocumentTitles().standaloneTechCheckSetup),
  });
  return (
    <TechCheck
      {...props}
      intl={useIntl()}
      searchParams={useSearchParams()[0]}
      navigate={useNavigate()}
    />
  );
};
