import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { isPast, subDays } from "date-fns";
import { useState } from "react";

import { Heading, Paragraph } from "common/core/typography";
import { CustomFormattedDateTime, FormattedDate } from "common/core/format/date";
import { userFullName } from "util/user";
import CertificateCard from "common/notary/certificate/card";
import AlertMessage from "common/core/alert_message";
import Button from "common/core/button";
import Icon from "common/core/icon";
import PopoutMenu from "common/core/popout_menu";
import PopoutMenuItem from "common/core/popout_menu/item";
import type { RevocationReason } from "graphql_globals";
import RevokeCertModal from "common/notary/certificate_management/revoke_cert_modal";
import { useMutation } from "util/graphql";
import RevokeNotaryCertificateMutation from "common/notary/certificate_management/revoke_notary_proof_cert_mutation.graphql";
import { pushNotification } from "common/core/notification_center/actions";
import ExportExemplarButton from "common/notary/exemplar/export_button";

import { type NotaryProfileWizardProofCertificate as User } from "../index_fragment.graphql";
import Styles from "./index.module.scss";

type Props = {
  user: User;
  onIssueNewCertificate: () => void;
  onRenew: () => void;
  onVerifyIdentity: () => void;
};

const MESSAGES = defineMessages({
  error: {
    id: "63dc42c8-6d98-4848-9911-9f5b892bbbcc",
    defaultMessage: "We encountered an issue. Retry or contact our support team if it continues.",
  },
  success: {
    id: "5dfdf1e0-3b56-4123-b0b2-71476e915a44",
    defaultMessage:
      "Your certificate has been successfully revoked, but your profile is no longer compliant.",
  },
});

function ViewNotaryCertificate({ user, onIssueNewCertificate, onRenew, onVerifyIdentity }: Props) {
  const proofCertificate = user.notaryProfile!.proofCertificate!;
  const validToDate = new Date(proofCertificate.validTo);
  const isCertificateExpired = isPast(validToDate);
  const isCertificateRevoked = Boolean(proofCertificate.revokedAt);
  const canCertificateBeRenewed = subDays(validToDate, 30) <= new Date();
  const isIdentityVerificationInvalid = !user.notaryProfile?.ial2IdentityVerified;
  const [showRevocationModal, setShowRevocationModal] = useState(false);
  const revokeNotaryCertificateMutateFn = useMutation(RevokeNotaryCertificateMutation);
  const intl = useIntl();

  const onRevoke = (reason: RevocationReason) => {
    return revokeNotaryCertificateMutateFn({
      variables: {
        input: {
          notaryProfileId: user.notaryProfile!.id,
          revocationReason: reason,
        },
      },
    })
      .finally(() => {
        setShowRevocationModal(false);
      })
      .then(() => {
        pushNotification({ subtype: "SUCCESS", message: intl.formatMessage(MESSAGES.success) });
      })
      .catch(() => {
        pushNotification({ subtype: "ERROR", message: intl.formatMessage(MESSAGES.error) });
      });
  };

  return (
    <div className={Styles.main}>
      <Heading textStyle="headingTwo" level="h1">
        <FormattedMessage
          id="344645c9-2958-4530-9a2a-fcafb77749ab"
          defaultMessage="Proof Digital Certificate"
        />
      </Heading>
      <div className={Styles.certCardContainer}>
        <CertificateCard user={user} disabled={isCertificateRevoked || isCertificateExpired} />

        {isCertificateRevoked && !isIdentityVerificationInvalid && (
          <AlertMessage>
            <FormattedMessage
              id="637c4e9a-3d39-40e5-b1fe-022821a0022c"
              defaultMessage="This certificate was revoked on {date}"
              values={{
                date: (
                  <CustomFormattedDateTime formatStyle="PPP" value={proofCertificate.revokedAt} />
                ),
              }}
            />
          </AlertMessage>
        )}

        {!isCertificateRevoked && isCertificateExpired && !isIdentityVerificationInvalid && (
          <AlertMessage>
            <FormattedMessage
              id="637c4e9a-3d39-40e5-b1fe-022821a0022c"
              defaultMessage="This certificate expired on {date}"
              values={{
                date: (
                  <CustomFormattedDateTime formatStyle="PPPppp" value={proofCertificate.validTo} />
                ),
              }}
            />
          </AlertMessage>
        )}
        {!isCertificateRevoked &&
          !isCertificateExpired &&
          canCertificateBeRenewed &&
          !isIdentityVerificationInvalid && (
            <AlertMessage kind="info">
              <div className={Styles.renew}>
                <FormattedMessage
                  id="8282c011-cca5-4f2d-9c20-77c556360690"
                  defaultMessage="Your certificate is expiring soon. Automatically renew it to continue using the platform."
                />

                <Button buttonColor="action" variant="primary" onClick={onRenew}>
                  <FormattedMessage
                    id="0bed3326-d05d-417e-8fef-7cb115813ffc"
                    defaultMessage="Renew Certificate"
                  />
                </Button>
              </div>
            </AlertMessage>
          )}
        {(isCertificateExpired || isCertificateRevoked || canCertificateBeRenewed) &&
          isIdentityVerificationInvalid && (
            <AlertMessage kind={isCertificateExpired || isCertificateRevoked ? "danger" : "info"}>
              <div className={Styles.renew}>
                <FormattedMessage
                  id="538037ad-d521-4abf-9313-d4ae447d6622"
                  defaultMessage="Your certificate is {certificateStatus}. Verify your identity to renew your certificate."
                  values={{
                    certificateStatus: isCertificateRevoked ? (
                      <FormattedMessage
                        id="d692e9d1-e6cf-4e88-a854-f521f840304c"
                        defaultMessage="revoked"
                      />
                    ) : isCertificateExpired ? (
                      <FormattedMessage
                        id="78608ee1-4fec-4b71-a297-770f32cf50c2"
                        defaultMessage="expired"
                      />
                    ) : (
                      <FormattedMessage
                        id="005cd614-e007-400c-86ca-0b9a30ceea06"
                        defaultMessage="expiring soon"
                      />
                    ),
                  }}
                />

                <Button
                  buttonColor={isCertificateExpired || isCertificateRevoked ? "danger" : "action"}
                  variant="primary"
                  onClick={onVerifyIdentity}
                >
                  <FormattedMessage
                    id="0480f967-3852-4c2e-ab88-dbfc8d67057d"
                    defaultMessage="Verify Identity"
                  />
                </Button>
              </div>
            </AlertMessage>
          )}
      </div>

      <div className={Styles.notaryInfo}>
        <div className={Styles.infoHeading}>
          <FormattedMessage id="8f046df3-2e73-4ea0-9352-b9601c90f0a2" defaultMessage="Full name" />
          <div>{userFullName(user)}</div>
        </div>

        <div className={Styles.infoHeading}>
          <FormattedMessage
            id="e4174f56-7675-4887-b9f8-8175f91dc9d4"
            defaultMessage="Expiration date"
          />
          <div>
            <FormattedDate value={proofCertificate.validTo} />
          </div>
        </div>
      </div>

      {(isCertificateRevoked || isCertificateExpired) && !isIdentityVerificationInvalid ? (
        <div className={Styles.certificateManagementSection}>
          <Heading textStyle="subtitleSmall" level="h5">
            <FormattedMessage
              id="af887851-fc89-41b3-abc8-d336b826d277"
              defaultMessage="Issue digital certificate"
            />
          </Heading>
          <Paragraph>
            <FormattedMessage
              id="2e647ffb-faa5-468b-9f5b-c18a7d60e3bd"
              defaultMessage="Your certificate to take calls on the platform "
            />
          </Paragraph>
          <Button buttonColor="action" variant="secondary" onClick={onIssueNewCertificate}>
            <FormattedMessage
              id="ea28c5a9-37b3-4d58-be10-03c6f3e67127"
              defaultMessage="Issue new certificate"
            />
          </Button>
        </div>
      ) : (
        <>
          <div className={Styles.certificateManagementSection}>
            <PopoutMenu
              placement="bottomLeft"
              target={(open) => (
                <Button
                  buttonColor="action"
                  variant="secondary"
                  withIcon={{ placement: "left", name: "settings" }}
                >
                  <FormattedMessage
                    id="1d04233f-6d43-4a64-bd16-2f80e6145bb2"
                    defaultMessage="Settings {open}"
                    values={{
                      open: (
                        <Icon name={open ? "caret-up" : "caret-down"} className={Styles.icon} />
                      ),
                    }}
                  />
                </Button>
              )}
              className={Styles.popoutMenu}
            >
              {({ close }) => (
                <div className={Styles.popoutMenu}>
                  <PopoutMenuItem
                    onClick={() => {
                      close();
                      setShowRevocationModal(true);
                    }}
                    danger
                  >
                    <FormattedMessage
                      id="9ad0a36f-069f-49d8-88d3-9f583cc01263"
                      defaultMessage="{icon} Revoke Certificate"
                      values={{
                        icon: <Icon name="x-filled" size="large" className={Styles.menuIcon} />,
                      }}
                    />
                  </PopoutMenuItem>
                </div>
              )}
            </PopoutMenu>
            <ExportExemplarButton />
          </div>
          <Paragraph size="small" textColor="subtle">
            <FormattedMessage
              id="47d2f974-2183-4e75-a6f6-e2376612616b"
              defaultMessage="Not all states require exemplar submission for compliance. No action needed if not applicable to your state."
            />
          </Paragraph>
        </>
      )}
      {showRevocationModal && (
        <RevokeCertModal
          proofCert={proofCertificate}
          onCancel={() => setShowRevocationModal(false)}
          onRevoke={onRevoke}
          certBelongsToCurrentUser
        />
      )}
    </div>
  );
}

export default ViewNotaryCertificate;
