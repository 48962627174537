import { memo } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import { durationString } from "util/meeting";
import VideoSessionId from "common/details/notary_details/items/video_session_id";
import CollapsibleList from "common/core/collapsible_list";
import CollapsibleListItem from "common/core/collapsible_list/item";
import { DeprecatedDetailGrid } from "common/details/grid";
import { DeprecatedDetailGridSection } from "common/details/grid/section";
import Button from "common/core/button";
import { usePermissions } from "common/core/current_user_role";

import Styles from "./index.module.scss";
import ProcessingVideo from "./processing_video";
import type { DetailsVideosVideo as Meeting } from "./video_fragment.graphql";

type Props = {
  meeting: Meeting;
  userRole?: string;
  useWrapperDiv?: boolean;
};

function Video({ meeting, useWrapperDiv }: Props) {
  const { id, canJoin, video, timeFrame, meetingParticipants } = meeting;
  const navigate = useNavigate();
  const { hasPermissionFor } = usePermissions();
  const meetingEnded = Boolean(timeFrame?.endedAt);

  const observerName = meetingParticipants.find(
    (p) => p.__typename === "SpectatorParticipant" && p.isConnected,
  )?.fullName;
  const videoSessionIdGrid = video?.id && (
    <DeprecatedDetailGrid>
      <DeprecatedDetailGridSection>
        <VideoSessionId meeting={meeting} />
      </DeprecatedDetailGridSection>
    </DeprecatedDetailGrid>
  );
  const content = (
    <div>
      {meetingEnded ? (
        <div className={Styles.videoDetails}>
          <ProcessingVideo
            src={video?.recording?.url}
            transcript={video?.transcript?.url}
            transactionName={meeting.organizationTransaction.name}
          />
        </div>
      ) : canJoin ? (
        <div className={Styles.onGoingMeeting}>
          <div className={Styles.header}>
            <FormattedMessage
              id="3e2f9540-eec1-4b45-adee-cdc064c351cd"
              defaultMessage="There is an ongoing meeting, do you want to join?"
            />
          </div>
          <div className={Styles.subheader}>
            <FormattedMessage
              id="2725bc82-2520-4087-86ca-5b9e97a446e6"
              defaultMessage="You will be able to see and hear all meeting participants, but they won't be able to see or hear you."
            />
          </div>
          <Button
            buttonSize="large"
            buttonColor="action"
            variant="primary"
            automationId="join-observer-meeting"
            onClick={() => navigate(`/meeting/${id}`)}
          >
            <FormattedMessage
              id="bb470a4c-786b-4e35-8ad9-fa02bd468d57"
              defaultMessage="Join as Observer"
            />
          </Button>
        </div>
      ) : (
        <div className={Styles.onGoingMeeting}>
          <div className={Styles.header}>
            <FormattedMessage
              id="82482831-c9c9-4061-97a9-61b0175e9d54"
              defaultMessage="There is an ongoing meeting."
            />
          </div>
          {hasPermissionFor("observeMeeting") && observerName && (
            <div className={Styles.subheader}>
              <FormattedMessage
                id="c91fd420-2fe8-4b15-b93a-2d64abfdeb51"
                defaultMessage="Meeting is currently observed by {observerName}."
                values={{ observerName }}
              />
            </div>
          )}
        </div>
      )}
      {hasPermissionFor("videoSessionDetailedIds") && videoSessionIdGrid}
    </div>
  );

  return useWrapperDiv ? (
    <div className={Styles.videoDetails}>
      <CollapsibleList>
        {meetingEnded ? (
          <CollapsibleListItem
            header={
              <FormattedMessage
                id="2253c242-2332-41c9-9e67-11d595ce463c"
                defaultMessage="Meeting - {meetingId}"
                values={{ meetingId: meeting.id }}
              />
            }
            subheader={
              meeting.timeFrame
                ? durationString(meeting as Parameters<typeof durationString>[0])
                : null
            }
            hideToggle
          >
            {content}
          </CollapsibleListItem>
        ) : (
          content
        )}
      </CollapsibleList>
    </div>
  ) : (
    content
  );
}

export default memo(Video);
