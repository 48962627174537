import { useState, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import TransactionDocumentUploader from "common/document/uploader/transaction_uploader";
import { DocumentRequirementEnum, OrganizationTypeEnum } from "graphql_globals";
import { segmentTrack } from "util/segment";
import { EVENT } from "constants/analytics";
import { useWatch } from "common/core/form";
import { useFeatureFlag } from "common/feature_gating";
import { usePermissions } from "common/core/current_user_role";
import { SIGN_AHEAD } from "constants/feature_gates";

import { CONFIGS, type DocumentSectionProps } from ".";
import TransactionCreationFormQuery from "../../query.graphql";
import { SectionErrorMessage, downgradeDisplay, readonlyField } from "../../common";
import { scrollToFirstError } from "../../form";
import type { DocumentsTransaction } from "./transaction_fragment.graphql";
import type { DocumentsOrganization } from "./organization_fragment.graphql";
import type { Config } from "../../config";
import { modifyDocumentsConfigValue } from "./util";

function modifyConfigForPlaceOrder(
  config: DocumentSectionProps["config"],
  placeOrder: boolean,
  perTransactionPlaceOrderEnabled: boolean,
) {
  if (placeOrder && !perTransactionPlaceOrderEnabled) {
    const newConfig = { ...config };
    modifyDocumentsConfigValue(newConfig, "canSetDocRequirements", false);
    modifyDocumentsConfigValue(newConfig, "canSetDocPermissions", false);
    downgradeDisplay(newConfig, "documentUploader", "readonly");
    return newConfig;
  }
  return config;
}

function generateDefaultDocumentRequirements(
  requirement: DocumentRequirementEnum,
  transaction: DocumentsTransaction,
) {
  // TODO: Revisit for new attestation config
  // TODO: [REAL-9562] Revisit when we start doing mortgage flows
  if (transaction.isMortgage) {
    if (!transaction.requiresNsaMeeting) {
      return {
        esign: false,
        notarizationRequired: false,
        proofingRequired: false,
        signingRequiresMeeting: true,
      };
    }
    return {
      esign: false,
      notarizationRequired: false,
      proofingRequired: false,
      signingRequiresMeeting: false,
    };
  }

  if (requirement === DocumentRequirementEnum.ESIGN) {
    return {
      esign: true,
      notarizationRequired: false,
      proofingRequired: false,
      signingRequiresMeeting: false,
    };
  }

  return {
    esign: false,
    notarizationRequired: true,
    proofingRequired: false,
    signingRequiresMeeting: true,
  };
}

function useDefaultDocumentRequirementSpecificProps({
  placeOrderEnabled,
  defaultDocRequirement,
  organization,
  transaction,
}: {
  placeOrderEnabled: boolean;
  defaultDocRequirement: Config["defaultDocRequirement"];
  organization: DocumentsOrganization;
  transaction: DocumentsTransaction;
}) {
  const { hasPermissionFor } = usePermissions();
  const { templates, organizationType } = organization;
  // TODO: [BIZ-9616] Confirm correct props are included for REAL_ESTATE_PROOF and REAL_ESTATE_ESIGN
  const canEditDocs = hasPermissionFor("editOrganizationTransactions");
  const signAheadEnabled = useFeatureFlag(SIGN_AHEAD);

  if (transaction.isMortgage) {
    const allowDownload = hasPermissionFor("viewDownloadOriginalPdf");

    return {
      cannotEditDocs: !canEditDocs,
      openAnnotateModalAfterDocumentsUploaded: !placeOrderEnabled,
      allowDownload,
      canRequireMeeting: signAheadEnabled,
      // we don't support template creation for title agencies right now, but we do allow
      // title agencies to upload templates as docs if they have any (through closing ops)
      disableTemplateUpload: organization.templates.totalCount === 0,
    };
  }

  if (defaultDocRequirement === DocumentRequirementEnum.ESIGN) {
    return {
      openAnnotateModalAfterDocumentsUploaded: true,
      cannotEditDocs: !canEditDocs,
      // we don't support template creation for title/lender right now, but we do allow
      // them to upload templates as docs if they have any (through closing ops)
      // Business orgs can upload templates as docs and create templates, so we don't disable for them
      disableTemplateUpload:
        (organizationType === OrganizationTypeEnum.TITLE_AGENCY ||
          organizationType === OrganizationTypeEnum.LENDER) &&
        templates.totalCount === 0,
    };
  }

  return {
    openAnnotateModalAfterDocumentsUploaded: true,
    cannotEditDocs: !canEditDocs,
    disableTemplateUpload: false,
  };
}

export function DocumentUploader(props: DocumentSectionProps) {
  const { transaction, organization, user, onSave, form, formId } = props;
  const { document_bundle } = transaction;
  const placeOrder = useWatch({ control: props.form.control, name: "placeOrder" });
  const config = modifyConfigForPlaceOrder(
    props.config,
    placeOrder,
    transaction.perTransactionPlaceOrderEnabled,
  );

  const {
    defaultDocRequirement,
    defaultDocPermissions,
    canRequireAttestation,
    canSetDocRequirements,
    splitBookmarkedPdf,
    supportedFileTypes,
    canRequireWitness,
    canSetDocPermissions,
  } = config;

  const [errorMessage, setErrorMessage] = useState<ReactNode | null>(null);
  const documentVariantSpecificProps = useDefaultDocumentRequirementSpecificProps({
    placeOrderEnabled: placeOrder,
    defaultDocRequirement,
    organization,
    transaction,
  });

  const documents = document_bundle?.documents.edges;

  function validateDocumentUpload(): Promise<"saved" | "failed"> {
    // need to manually wrap in promise since handleSubmit just returns Promise<void>
    return new Promise((resolve) => {
      const fn = form.handleSubmit(
        async (formValues) => {
          const result = await onSave({ formValues, shouldExit: false });
          resolve(result?.status === "failed" ? "failed" : "saved");
        },
        async (error) => {
          // document upload only cares about recipient details, hence only fail on recipient errors
          if (error.recipients) {
            scrollToFirstError(formId);
            resolve("failed");
          } else {
            const formValues = form.getValues();
            const result = await onSave({ formValues, shouldExit: false });
            resolve(result?.status === "failed" ? "failed" : "saved");
          }
        },
      );

      fn();
    });
  }

  async function handleOpenAnnotationModal(skipSave = false) {
    if (!skipSave) {
      // We skip saving after uploading a document because handleOpenDocumentsModal already saves it once
      const onSaveResult = await validateDocumentUpload();
      if (onSaveResult === "failed") {
        setErrorMessage(
          <FormattedMessage
            id="195b49b6-9a46-4a6d-ab6d-0e9119ea2d57"
            defaultMessage="Please enter the required information before filling in your {count, plural, one{document} other{documents}}."
            values={{ count: documents?.length }}
          />,
        );
        return Promise.reject();
      }
    }
    setErrorMessage(null);
    return Promise.resolve();
  }

  function handleCloseAnnotationModal() {
    const { id } = transaction;
    segmentTrack(EVENT.ORGANIZATION_TRANSACTION_EDITOR_ANNOTATE_DOCUMENTS_FINISHED, {
      organization_transaction_id: id,
    });
  }

  async function handleOpenDocumentsModal() {
    const onSaveResult = await validateDocumentUpload();
    if (onSaveResult === "failed") {
      setErrorMessage(
        <FormattedMessage
          id="f1dff718-88f6-4c4f-b9d5-31dde3f71781"
          defaultMessage="Please enter the required information before uploading a document."
        />,
      );
      return Promise.reject();
    }
    setErrorMessage(null);
    return Promise.resolve();
  }

  return (
    <>
      <TransactionDocumentUploader
        // *** SHARED ***
        transaction={transaction}
        organization={organization}
        viewer={{ user }}
        transactionQuery={TransactionCreationFormQuery}
        onOpenAnnotateModal={handleOpenAnnotationModal}
        checkCanOpenAddDocumentModal={handleOpenDocumentsModal}
        showAcceptedDocuments
        // ** CONFIG VALUES ***
        defaultDocRequirements={generateDefaultDocumentRequirements(
          defaultDocRequirement,
          transaction,
        )}
        defaultDocPermissions={defaultDocPermissions}
        canSetDocRequirements={canSetDocRequirements.value}
        canSetDocPermissions={canSetDocPermissions.value}
        showWitnessRequired={canRequireWitness.value}
        canRequireProofing={canRequireAttestation.value}
        supportedFileTypes={supportedFileTypes}
        splitBookmarkedPdf={splitBookmarkedPdf.value}
        // *** ESIGN / NOTARIZATION ***
        // TODO: [BIZ-5114] handle annotation modal (can likely be cleaned up here)
        // annotatingByPrompt={isAnnotating}
        onCloseAnnotateModal={handleCloseAnnotationModal}
        readOnly={readonlyField(config, CONFIGS.documentUploader)}
        {...documentVariantSpecificProps}
      />

      {errorMessage && <SectionErrorMessage message={errorMessage} />}
    </>
  );
}
