import { type ComponentProps, memo } from "react";
import { FormattedMessage } from "react-intl";

import { durationString } from "util/meeting";
import { DeprecatedDetailGrid } from "common/details/grid";
import { DeprecatedDetailGridSection } from "common/details/grid/section";
import CollapsibleList from "common/core/collapsible_list";
import CollapsibleListItem from "common/core/collapsible_list/item";
import PaymentStatus from "common/transactions/payment_status";
import { usePermissions } from "common/core/current_user_role";
import { OrganizationTransactionVariant } from "graphql_globals";

import NotarialActs from "./items/notarial_acts";
import AgentGid from "./items/agent_gid";
import AgentName from "./items/agent_name";
import AgentRegistration from "./items/agent_registration";
import MeetingPlatform from "./items/meeting_platform";
import NotarizationStatus from "./items/status";
import TerminationInfo from "./items/termination_info";
import PayoutTransfer from "./items/payout_transfer";
import ReclaimPayout from "./items/reclaim_payout";
import type { MeetingForTransactionDetailsNotaryDetail as Meeting } from "./notary_detail_fragment.graphql";
import Styles from "./notary_details.module.scss";
import OverrideReason from "./items/override_reason";

type Props = {
  meeting: Meeting;
  useWrapperDiv?: boolean;
  renderPriceBreakdown?: ComponentProps<typeof PaymentStatus>["renderPriceBreakdown"];
  onReclaim?: () => void;
  notary?: null | ComponentProps<typeof AgentGid>["notary"];
  showResponsiveView?: boolean;
};

function NotaryDetail({
  onReclaim,
  meeting,
  notary,
  // if using Video component as a singleton, wrap in a div giving proper styles
  // and defining an appropriate header text for a single meeting
  useWrapperDiv,
  renderPriceBreakdown,
  showResponsiveView,
}: Props) {
  const { documentBundle, payoutTransfer, payoutTransferReversal } = meeting;
  const { hasPermissionFor } = usePermissions();
  const showReclaimPayout =
    hasPermissionFor("reclaimPayout") && payoutTransfer && !payoutTransferReversal;

  const responsiveRowCx = showResponsiveView ? Styles.responsiveRow : undefined;
  const view = (
    <div className={showResponsiveView ? Styles.responsiveContent : undefined}>
      {showResponsiveView && <div className={Styles.date}>{durationString(meeting)}</div>}
      <DeprecatedDetailGrid>
        <DeprecatedDetailGridSection>
          {meeting.organizationTransaction.transactionVariant !==
            OrganizationTransactionVariant.PROOF && (
            <span className={responsiveRowCx}>
              <NotarialActs meeting={meeting} />
            </span>
          )}
          <span className={responsiveRowCx}>
            <AgentName meeting={meeting} />
          </span>
          {
            /* The notary infomation presented here won't be available in every context */
            notary && (
              <span className={responsiveRowCx}>
                <AgentGid notary={notary} />
              </span>
            )
          }
          <span className={responsiveRowCx}>
            <AgentRegistration meeting={meeting} />
          </span>
          <span className={responsiveRowCx}>
            <MeetingPlatform meeting={meeting} />
          </span>
          {hasPermissionFor("notaryPaymentInfo") && (
            <>
              <span className={responsiveRowCx}>
                <PaymentStatus
                  bundle={documentBundle!}
                  renderPriceBreakdown={renderPriceBreakdown}
                />
              </span>
              <span className={responsiveRowCx}>
                <PayoutTransfer
                  meeting={meeting}
                  renderStripeLinks={hasPermissionFor("renderStripeLinks")}
                />
              </span>
            </>
          )}
          {showReclaimPayout && (
            <span className={responsiveRowCx}>
              <ReclaimPayout onReclaim={onReclaim} payoutId={payoutTransfer.id} />
            </span>
          )}
          <span className={responsiveRowCx}>
            <NotarizationStatus meeting={meeting} />
          </span>
          {hasPermissionFor("terminationInfo") && (
            <span className={responsiveRowCx}>
              <TerminationInfo meeting={meeting} />
            </span>
          )}
          {hasPermissionFor("terminationInfo") && meeting.overrideReason && (
            <span className={responsiveRowCx}>
              <OverrideReason overrideReason={meeting.overrideReason} />
            </span>
          )}
        </DeprecatedDetailGridSection>
      </DeprecatedDetailGrid>
    </div>
  );

  return useWrapperDiv ? (
    <div className={showResponsiveView ? Styles.responsiveNotarization : Styles.notarization}>
      <CollapsibleList>
        <CollapsibleListItem
          header={
            <FormattedMessage
              id="5ba4a3ce-fbdf-47aa-af56-9df5052a45df"
              defaultMessage="Meeting - {meetingId}"
              values={{ meetingId: meeting.id }}
            />
          }
          hideToggle
          {...(!showResponsiveView && { subheader: durationString(meeting) })}
        >
          {view}
        </CollapsibleListItem>
      </CollapsibleList>
    </div>
  ) : (
    view
  );
}

export default memo(NotaryDetail);
