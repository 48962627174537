import { FormattedMessage, useIntl } from "react-intl";

import { useDocumentTitles } from "util/document_title";
import Tab from "common/core/tabs/tab";
import { useA11y } from "common/accessibility";
import { SettingsTitle } from "common/settingsv2/common";

import { Certificate } from "./certificate";

export const PROFILE_PROOF_CERTIFICATE_ROUTE = "proof-certificate";

export function ProofCertificate() {
  useA11y().useDocumentEntitler({
    title: useIntl().formatMessage(useDocumentTitles().settingsProfileProofCertificate),
  });
  return (
    <>
      <SettingsTitle>
        <FormattedMessage id="f9d6868b-67cf-4528-8ac6-e0a12161ab06" defaultMessage="Proof Card" />
      </SettingsTitle>
      <Certificate />
    </>
  );
}

export function ProofCertificateTab({
  enableKeyboardNavigation,
}: {
  enableKeyboardNavigation?: boolean;
}) {
  return (
    <Tab to={PROFILE_PROOF_CERTIFICATE_ROUTE} enableKeyboardNavigation={enableKeyboardNavigation}>
      <FormattedMessage id="a6bc0b92-0177-4bab-9741-0a4208e2a3b2" defaultMessage="Proof Card" />
    </Tab>
  );
}
