import { saveAll, swapExtension } from "util/archive";
import { RETRIEVAL_PAGE_DISPLAY_NAME } from "constants/document";

import type { SaveAllBundle as DocumentBundle } from "./bundle_fragment.graphql";

function archiveMetadata({ documents, retrieval_page_file: retrievalPage }: DocumentBundle) {
  // if signedWetSign then use s3UploadedAsset, then use final_asset because wet sign docs do not currently generate final assets
  const docInfo = documents.edges.map(({ node }) => ({
    url: node.signedWetSign ? node.s3UploadedAsset!.url! : node.final_asset!.url!,
    name: swapExtension(node.final_asset?.downloadName || node.name) || "",
  }));
  const retrievalPageInfo = retrievalPage
    ? [{ name: RETRIEVAL_PAGE_DISPLAY_NAME, url: retrievalPage.url! }]
    : [];
  return retrievalPageInfo.concat(docInfo);
}

export function asArchive(documentBundle: DocumentBundle) {
  return saveAll({
    archiveName: documentBundle.name || "Archive",
    files: archiveMetadata(documentBundle),
  });
}

export function downloadAllOriginalAssets({ name, documents }: DocumentBundle) {
  return saveAll({
    archiveName: `Original - ${name}`,
    files: documents.edges.map((edge) => {
      const { name, s3UploadedAsset } = edge.node;
      let fileName = name || "";
      if (s3UploadedAsset!.extension === ".docx" && fileName.endsWith(".pdf")) {
        fileName = `${fileName.slice(0, -4)}.docx`;
      }
      return { url: s3UploadedAsset!.url!, name: fileName };
    }),
  });
}
