import { useEffect, useRef } from "react";
import { debounceTime } from "rxjs";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";

import { AddressInput, validateAddressIsUS } from "common/core/form/address";
import { useFormState, useWatch } from "common/core/form";
import { useSubject } from "util/rxjs/hooks";
import { type AddressType } from "graphql_globals";
import { Select } from "common/core/form/select";
import useRecordingLocation from "common/mortgage/transactions/recording_location_service";
import AlertMessage from "common/core/alert_message";
import Link from "common/core/link";
import { PAPER_RECORDING_BLOG_POST } from "constants/marketing";
import { Paragraph } from "common/core/typography";

import type { TransactionDetailsTransaction_recordingLocation as RecordingLocation } from "./transaction_fragment.graphql";
import type { TransactionDetailsSection_viewer_recordingLocationEligibilities as RecordingLocationEligibilities } from "./real_estate_transaction_query.graphql";
import { CONFIGS, FORM_FIELDS, type TransactionDetailsSectionForm } from ".";
import Styles from "./property_address_form.module.scss";
import { ConfiguredField, readonlyField, requiredField } from "../../common";
import type { Config } from "../../config";

const MESSAGES = defineMessages({
  recordingLocationId: {
    id: "1ecf2663-23ed-4115-abd4-d7e25b6f593b",
    defaultMessage: "Recording location",
  },
  recordingLocationPlaceholder: {
    id: "35ca2e14-591e-4f9f-9d4a-e10c03e5e724",
    defaultMessage: "Select a recording location",
  },
  invalidAddressError: {
    id: "0aa0c5f8-acc3-46ad-8828-7463eee665b7",
    // TODO: Confirm wording for error
    defaultMessage: "Please enter valid address",
  },
});

type Props = {
  config: Config;
  form: TransactionDetailsSectionForm;
  initialRecordingLocation: RecordingLocation;
  initialRecordingLocations: RecordingLocationEligibilities[];
  collabEnabled: boolean;
};

export default function PropertyAddressForm({
  config,
  form,
  initialRecordingLocation,
  initialRecordingLocations,
  collabEnabled,
}: Props) {
  const intl = useIntl();

  const initialValues = {
    initialRecordingLocation,
    initialRecordingLocations,
  };
  const {
    onPropertyAddressChange,
    onRecordingLocationChange,
    availableRecordingLocations,
    selectedRecordingLocation,
    fetching,
    // Phasing out nononboarded and treating it as default. Moving forward, assume if
    // collab is on, nononboarded is also on until we  can get it completely removed.
  } = useRecordingLocation({
    initialValues,
    nononboardedEnabled: collabEnabled,
  });

  const propertyAddress = useWatch({
    control: form.control,
    name: FORM_FIELDS.propertyAddress,
  });

  const { errors } = useFormState({ control: form.control, name: FORM_FIELDS.propertyAddress });

  const $address = useSubject<AddressType>();

  const currentAddressChangeCb = useRef(onPropertyAddressChange);

  useEffect(() => {
    currentAddressChangeCb.current = onPropertyAddressChange;
  }, []);

  useEffect(() => {
    if (propertyAddress) {
      $address.next(propertyAddress);
    }
  }, [propertyAddress]);

  useEffect(() => {
    const subscription = $address.pipe(debounceTime(1000)).subscribe((address) => {
      if (!errors.propertyAddress) {
        return currentAddressChangeCb.current(address);
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const paperingOutRequired =
    selectedRecordingLocation && !selectedRecordingLocation.erecordingSupported;

  const disableSelectRecordingLocation = availableRecordingLocations.length < 2 || fetching;

  // TODO: DSP-348 city/state/zip on one line
  return (
    <fieldset>
      <legend className={Styles.title}>
        <Paragraph size="large">
          <FormattedMessage
            id="ffd92b11-9370-4c65-8a09-d82517de53f3"
            defaultMessage="Property address"
          />
        </Paragraph>
      </legend>
      {paperingOutRequired && (
        <AlertMessage kind="info" className={Styles.paperingOutAlert}>
          <FormattedMessage
            id="0be0706b-6453-4513-8e92-9acf03c0a7fb"
            defaultMessage="To complete a fully online closing in a paper recording county, your customers will need a wet-signed Declaration of Authenticity. <link>Learn more</link>"
            values={{ link: (text) => <Link href={PAPER_RECORDING_BLOG_POST}>{text}</Link> }}
          />
        </AlertMessage>
      )}
      <AddressInput
        form={form}
        name={FORM_FIELDS.propertyAddress}
        autocompleteOptions={{ componentRestrictions: { country: "us" } }}
        disabled={readonlyField(config, CONFIGS.propertyAddress)}
        countryValidation={validateAddressIsUS}
        hideCountry
        required={requiredField(config, CONFIGS.propertyAddress)}
        showAddressLookup
      />
      {Boolean(availableRecordingLocations.length) && (
        <ConfiguredField
          config={config}
          configField="recordingLocation"
          form={form}
          disabled={disableSelectRecordingLocation}
          fullWidth
          name="recordingLocationId"
          label={intl.formatMessage(MESSAGES.recordingLocationId)}
          registerOptions={{
            onChange: (recordingLocationId: string) => {
              onRecordingLocationChange(recordingLocationId);
            },
          }}
        >
          <Select
            aria-invalid="false"
            aria-label={intl.formatMessage(MESSAGES.recordingLocationId)}
            data-automation-id="recording-location-id"
            items={availableRecordingLocations.map((rl) => {
              return {
                value: rl.id,
                label: rl.name,
              };
            })}
            value={selectedRecordingLocation?.id ?? ""}
            placeholder={intl.formatMessage(MESSAGES.recordingLocationPlaceholder)}
          />
        </ConfiguredField>
      )}
    </fieldset>
  );
}
