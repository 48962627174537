import { FormattedMessage } from "react-intl";

import {
  OrganizationMembershipActivationState,
  OrganizationMembershipRole,
  UserRole,
} from "graphql_globals";

export function RoleLabel({ role }: { role: OrganizationMembershipRole }) {
  switch (role) {
    case OrganizationMembershipRole.OWNER:
      return <FormattedMessage id="09a67910-ae22-4f52-b657-2f8dff320105" defaultMessage="Owner" />;
    case OrganizationMembershipRole.ADMIN:
      return <FormattedMessage id="594ca78a-2b6c-4ed3-bfd1-bb68acb74897" defaultMessage="Admin" />;
    case OrganizationMembershipRole.EMPLOYEE:
      return (
        <FormattedMessage id="c915bf67-127e-4bda-9bd8-093236b7cb3a" defaultMessage="Team Member" />
      );
    case OrganizationMembershipRole.PARTNER:
      return (
        <FormattedMessage
          id="04f8e6a5-e981-4085-84fc-ee7a79b4bd9c"
          defaultMessage="Mortgage Closing Reviewer"
        />
      );
    case OrganizationMembershipRole.NOTARIZE_CLOSING_OPS:
      return (
        <FormattedMessage id="ed07a95a-4af6-4133-83cd-df9e248c885e" defaultMessage="Proof Staff" />
      );
    case OrganizationMembershipRole.MIGRATED:
      return (
        <FormattedMessage id="7aa70a21-4999-487f-a23b-f3a654432739" defaultMessage="Migrated" />
      );
    default:
      return null;
  }
}

export function statusLabel(activationState: OrganizationMembershipActivationState) {
  switch (activationState) {
    case OrganizationMembershipActivationState.ACTIVE:
      return <FormattedMessage id="217c2157-2b96-4397-94bb-9d0419fcb98a" defaultMessage="Active" />;
    case OrganizationMembershipActivationState.PENDING:
      return (
        <FormattedMessage id="2808c68c-ba60-42f2-99d3-80ab6c16a0b9" defaultMessage="Pending" />
      );
    case OrganizationMembershipActivationState.AWAITING_APPROVAL:
      return (
        <FormattedMessage
          id="ddaa50b8-9f2a-4c5a-95e2-fe478fd617e9"
          defaultMessage="Awaiting owner/admin approval"
        />
      );
    case OrganizationMembershipActivationState.DISABLED:
      return (
        <FormattedMessage id="7f1d0a5b-d00a-4480-af87-99f2377a315c" defaultMessage="Deactivated" />
      );
    default:
      throw new Error(`Unexpected activationState ${activationState}`);
  }
}

function isOrgAdmin(role: UserRole | OrganizationMembershipRole) {
  switch (role) {
    case OrganizationMembershipRole.OWNER:
    case OrganizationMembershipRole.ADMIN:
    case UserRole.ORGANIZATION_MEMBER_OWNER:
    case UserRole.ORGANIZATION_MEMBER_ADMIN:
      return true;
    default:
      return false;
  }
}

export function includesOrgAdminRole(
  roles: (UserRole | OrganizationMembershipRole | null)[] | null,
) {
  return Boolean(roles?.some((role) => role && isOrgAdmin(role)));
}
