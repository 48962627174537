import { useState, type ReactNode } from "react";
import { useIntl, defineMessages } from "react-intl";

import {
  DocumentBundleParticipantActivity,
  DocumentBundleParticipantStatus,
} from "graphql_globals";
import Button from "common/core/button";
import WorkflowModal from "common/modals/workflow_modal";
import { Paragraph } from "common/core/typography";
import { useRetireSignerIdentities } from "common/signer/identity/retire";
import { SIGNER_IDS_SESSION_KEY } from "common/signer/initiate_signing";
import { useFeatureFlag } from "common/feature_gating";
import { segmentTrack } from "util/segment";
import { SEGMENT_EVENTS } from "constants/analytics";

import Styles from "./index.module.scss";

const MESSAGES = defineMessages({
  title: {
    id: "8a9f3cd1-980c-4970-8d07-abb51b560351",
    defaultMessage: "This transaction is still in progress",
  },
  description: {
    id: "91727871-a073-4902-8fc1-10c295ef65fd",
    defaultMessage:
      "Finish the session initiated on a different device or tab to avoid losing your progress.",
  },
  question: {
    id: "a41ddfcb-2afe-4226-a201-c892be381d11",
    defaultMessage: "Are you sure you want to restart? ",
  },
  continue: {
    id: "a566a96e-b297-4eea-a532-3117a9dfb02c",
    defaultMessage: "Yes, I will restart",
  },
  cancel: {
    id: "8f992482-81b1-42b5-8b99-5252e3dfe56d",
    defaultMessage: "No",
  },
});

type RenderProps = {
  handleBlockedAction: (action: () => void) => void;
};

type BaseProps = {
  handleContinue: () => void;
  handleCancel: () => void;
  currentParticipant:
    | {
        userId: string;
      }
    | null
    | undefined;
};

type Props = {
  viewer: { user: null | { id: string } };
  participants:
    | ({
        id: string;
        userId: string;
        signingActivity: DocumentBundleParticipantActivity;
        signingStatus: DocumentBundleParticipantStatus;
      } | null)[]
    | null;
  children: (renderProps: RenderProps) => ReactNode;
};

export const viewerHasActiveSession = ({
  participants,
  viewer,
}: Pick<Props, "participants" | "viewer">) => {
  const userParticipant = participants?.find(
    (participant) => participant?.userId === viewer.user?.id,
  );
  return userParticipant
    ? userParticipant.signingActivity !== DocumentBundleParticipantActivity.NOT_SIGNING &&
        userParticipant.signingStatus !== DocumentBundleParticipantStatus.COMPLETE
    : false;
};

export const ActiveSessionBlockingModal = ({
  handleCancel,
  handleContinue,
  currentParticipant,
}: BaseProps) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const retireSignerIdentities = useRetireSignerIdentities();

  async function onContinue() {
    const sessionIds = window.sessionStorage.getItem(SIGNER_IDS_SESSION_KEY);
    const signerIdentityIds = sessionIds ? sessionIds.split(",") : sessionIds;
    segmentTrack(SEGMENT_EVENTS.ACTIVE_SESSION_BLOCKING_MODAL_CONTINUE_CLICKED, {
      signerIdentityIds,
      userId: currentParticipant?.userId,
    });
    setLoading(true);
    await retireSignerIdentities({ ignoreNoActive: true });
    window.sessionStorage.removeItem(SIGNER_IDS_SESSION_KEY);
    setLoading(false);
    handleContinue();
  }

  function onCancel() {
    const sessionIds = window.sessionStorage.getItem(SIGNER_IDS_SESSION_KEY);
    const signerIdentityIds = sessionIds ? sessionIds.split(",") : sessionIds;
    segmentTrack(SEGMENT_EVENTS.ACTIVE_SESSION_BLOCKING_MODAL_CANCEL_CLICKED, {
      signerIdentityIds,
      userId: currentParticipant?.userId,
    });
    handleCancel();
  }

  return (
    <WorkflowModal
      title={intl.formatMessage(MESSAGES.title)}
      footerSeparator={false}
      className={Styles.modal}
      isSensitive={false}
      buttons={[
        <Button
          key="cancel"
          data-automation-id="active-session-cancel-button"
          buttonColor="action"
          variant="primary"
          onClick={onCancel}
          disabled={loading}
          fullwidth
        >
          {intl.formatMessage(MESSAGES.cancel)}
        </Button>,
        <Button
          data-automation-id="active-session-continue-button"
          key="continue"
          buttonColor="action"
          variant="secondary"
          onClick={onContinue}
          disabled={loading}
          isLoading={loading}
          fullwidth
        >
          {intl.formatMessage(MESSAGES.continue)}
        </Button>,
      ]}
      autoFocus
    >
      {
        <>
          <Paragraph className={Styles.description}>
            {intl.formatMessage(MESSAGES.description)}
          </Paragraph>
          <Paragraph>{intl.formatMessage(MESSAGES.question)}</Paragraph>
        </>
      }
    </WorkflowModal>
  );
};

export const useActiveSessionBlockingEnabled = () => {
  return useFeatureFlag("active-session-blocking-modal");
};

export default function ActiveSessionBlockingModalWrapper({
  viewer,
  participants,
  children,
}: Props) {
  const blockingEnabled = useActiveSessionBlockingEnabled();
  const [blockedAction, setBlockedAction] = useState<(() => void) | undefined>();
  const handleBlockedAction = (action: () => void) => {
    if (blockingEnabled && viewerHasActiveSession({ participants, viewer })) {
      setBlockedAction(() => action);
    } else {
      action();
    }
  };

  return (
    <>
      {blockingEnabled && !!blockedAction && (
        <ActiveSessionBlockingModal
          handleContinue={() => {
            blockedAction();
            setBlockedAction(undefined);
          }}
          handleCancel={() => setBlockedAction(undefined)}
          currentParticipant={participants?.find(
            (participant) => participant?.userId === viewer.user?.id,
          )}
        />
      )}
      {children({ handleBlockedAction })}
    </>
  );
}
