import { memo } from "react";
import { FormattedMessage } from "react-intl";

import FormattedPrice from "common/core/format/formatted_price";
import Link from "common/core/link";
import { DeprecatedDetailGridRow } from "common/details/grid/row";

type Props = {
  meeting: {
    payoutTransfer: null | { amount: number; stripeTransferId: string | null };
    payoutTransferReversal: null | { amount: number; stripeReversalId: string | null };
  };
  renderStripeLinks: boolean;
};

function PayoutTransfer({ meeting, renderStripeLinks }: Props) {
  if (meeting.payoutTransfer === null) {
    return null;
  }
  const stripeTransferId = meeting.payoutTransfer.stripeTransferId;
  const reversed = meeting.payoutTransferReversal !== null;
  const stripeReversalId = meeting.payoutTransferReversal?.stripeReversalId;
  return (
    <DeprecatedDetailGridRow
      title={<FormattedMessage id="f0562ecd-d2d1-4d5f-b067-cc7f5c50b5dd" defaultMessage="Payout" />}
    >
      <FormattedPrice cents={meeting.payoutTransfer.amount} />
      {renderStripeLinks && stripeTransferId && (
        <>
          {" ("}
          <Link href={`https://dashboard.stripe.com/connect/transfers/${stripeTransferId}`}>
            <FormattedMessage
              id="d365cd72-c8cd-4465-a9de-38727500e6f9"
              defaultMessage="Stripe Transfer"
            />
          </Link>
          {")"}
        </>
      )}
      {reversed && (
        <FormattedMessage id="7febe47f-e0e0-42d1-bcba-97a215cc7b0f" defaultMessage=" (Reversed)" />
      )}
      {renderStripeLinks && stripeReversalId && (
        <>
          {" ("}
          <Link href={`https://dashboard.stripe.com/payments/${stripeReversalId}`}>
            <FormattedMessage
              id="302bed83-363f-4f97-8d85-cb2f471b05db"
              defaultMessage="Stripe Reversal"
            />
          </Link>
          {")"}
        </>
      )}
    </DeprecatedDetailGridRow>
  );
}

export default memo(PayoutTransfer);
