import "./index.scss";

import { cloneElement, PureComponent } from "react";
import PropTypes from "prop-types";
import { Subject, fromEvent, takeUntil } from "rxjs";
// eslint-disable-next-line no-restricted-imports
import isEqual from "lodash/isEqual";
import { connect } from "react-redux";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import Link from "common/core/link";
import Icon from "common/core/icon";
import TabRow from "common/core/tabs/tab_button_row";
import TabButton from "common/core/tabs/tab_button_row/tab_button";
import {
  toolClicked,
  deselectTool,
  lockTool,
  unlockTool,
  setSigner,
  resetPdfMenu,
} from "redux/actions/pdf_menu";
import { interactingWithInput } from "common/form/util";
import { CURRENT_PORTAL } from "constants/app_subdomains";
import APPS from "constants/applications";
import { useSelector } from "redux/util";
import { segmentTrack } from "util/segment";
import { Heading, Paragraph } from "common/core/typography";

import { CX } from "./constants";
import DocumentSplits from "./document_splits";
import DocumentFeedback from "./document_feedback";
import { DesignationMenu } from "./designation_menu";
import { AnnotationMenu } from "./annotation_menu";
import { SignerDropdown } from "./signer_dropdown";
import PdfMenuTool from "./tool";
import PdfMenuToolset from "./toolset";

// Matches tab index
const PDF_MENU_TYPES = Object.freeze({
  FEEDBACK: 0,
  TOOLS: 1,
});

const SUPPORTED_ANNOTATIONS = [
  "CheckmarkAnnotation",
  "ImageAnnotation",
  "TextAnnotation",
  "WhiteboxAnnotation",
];

class PdfMenu extends PureComponent {
  constructor(props) {
    super(props);
    this.unmounted$ = new Subject();
    this.state = { currentMenuType: PDF_MENU_TYPES.TOOLS };
  }

  componentDidMount() {
    const domDoc = window.document;
    const untilUnmounted = takeUntil(this.unmounted$);

    fromEvent(domDoc, "keydown").pipe(untilUnmounted).subscribe(this.handleKeyDown);
    fromEvent(domDoc, "keyup").pipe(untilUnmounted).subscribe(this.handleKeyUp);
  }

  componentDidUpdate(prevProps) {
    const { currentToolData, currentSignerNumber, data, dispatch } = this.props;

    if (!data) {
      return;
    }

    let signerNumber = currentSignerNumber;
    if (currentSignerNumber > data.signers.length) {
      signerNumber = 1;
      dispatch(setSigner({ number: signerNumber }));
    }

    const reselectForSignerNumber =
      currentToolData && prevProps.currentSignerNumber !== currentSignerNumber;
    const reselectForChangedToolsets =
      currentToolData && prevProps.data.toolsets.length !== data.toolsets.length;
    const reselectForChangedSigners =
      currentToolData && prevProps.data.signers.length !== data.signers.length;
    if (reselectForSignerNumber || reselectForChangedToolsets || reselectForChangedSigners) {
      dispatch(deselectTool());
      const tool = this.getToolFromData({
        type: currentToolData.type,
        subtype: currentToolData.subtype,
        placementType: currentToolData.placementType,
        signerRole: currentToolData.signerRole,
        signerNumber,
      });
      if (tool) {
        dispatch(toolClicked({ data: tool.data }));
      }
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;

    dispatch(resetPdfMenu());
    this.unmounted$.next();
    this.unmounted$.complete();
  }

  handleKeyDown = (e) => {
    if (interactingWithInput()) {
      return;
    }

    const { dispatch, data, conditionalEditModeDetails } = this.props;

    if (e.key === "Escape") {
      const toggleConditionalEditMode = conditionalEditModeDetails?.toggleConditionalEditMode;
      // Nil safe to account for when the Conditional Rules flag is disabled.
      toggleConditionalEditMode?.(false);
      dispatch(deselectTool());
      return;
    }

    if (e.key === "Shift") {
      dispatch(lockTool());
      return;
    }

    // signer.number doesn't exist when user cannot modify the doc
    const signerNumbers = data.signers.map((signer) => signer.number?.toString());
    if (signerNumbers.includes(e.key)) {
      segmentTrack("[DAS] Shortcut key pressed - signer", {
        key: e.key,
      });
      dispatch(setSigner({ number: parseInt(e.key, 10) }));
      return;
    }

    const tool = this.getToolFromShortcutKey(e.key);
    if (tool) {
      segmentTrack("[DAS] Shortcut key pressed - tool", {
        key: e.key,
      });
      dispatch(toolClicked({ data: tool.data }));
    }
  };

  handleKeyUp = (e) => {
    const { dispatch } = this.props;

    if (e.key === "Shift") {
      dispatch(unlockTool());
    }
  };

  getToolFromShortcutKey(key) {
    const { data, currentSignerNumber } = this.props;
    const { toolsets } = data;

    for (let i = 0; i < toolsets.length; i++) {
      const toolset = toolsets[i];
      if (!toolset.signerNumber || toolset.signerNumber === currentSignerNumber) {
        const tool = toolset.tools.find((tool) => tool.shortcutKey === key);
        if (tool) {
          return tool;
        }
      }
    }
  }

  getInfoFromToolData({ type, subtype, placementType, signerNumber = null, signerRole = null }) {
    const { data, currentSignerNumber } = this.props;
    const { toolsets } = data;

    for (let i = 0; i < toolsets.length; i++) {
      const toolset = toolsets[i];
      for (let j = 0; j < toolset.tools.length; j++) {
        const tool = toolset.tools[j];
        if (
          (!toolset.signerNumber ||
            toolset.signerNumber === (signerNumber || currentSignerNumber)) &&
          tool.data.type === type &&
          tool.data.subtype === subtype &&
          tool.data.placementType === placementType &&
          tool.data.signerRole?.role === signerRole?.role
        ) {
          return { tool, toolset };
        }
      }
    }
  }

  getToolFromData({ type, subtype, placementType, signerNumber = null, signerRole = null }) {
    const info = this.getInfoFromToolData({
      type,
      subtype,
      placementType,
      signerNumber,
      signerRole,
    });
    return info && info.tool;
  }

  getColorHexFromData({ type, subtype, placementType, signerNumber = null, signerRole = null }) {
    const info = this.getInfoFromToolData({
      type,
      subtype,
      placementType,
      signerNumber,
      signerRole,
    });
    return info && info.toolset && info.toolset.colorHex;
  }

  onSelectMenuType = (tabIndex) => {
    this.setState({ currentMenuType: tabIndex });
  };

  renderHeader() {
    const {
      title,
      headerInfo,
      button,
      buttonRequirements,
      splitAndTagDocuments,
      data,
      document,
      selectedAnnotationOrDesignation,
      documentSplitManager,
      hasPermissionFor,
    } = this.props;
    const canViewDownloadOriginalPdf =
      hasPermissionFor && hasPermissionFor("viewDownloadOriginalPdf");
    const canProvideDocumentFeedback =
      hasPermissionFor && hasPermissionFor("provideDocumentFeedback");
    const headerCX = classnames(`${CX}--header`, {
      [`${CX}--header__minimal-padding`]:
        canViewDownloadOriginalPdf || canProvideDocumentFeedback || splitAndTagDocuments,
      [`${CX}--header__no-shadow`]: data && data.toolsets.length === 0,
      [`${CX}--header__no-margin`]: Boolean(selectedAnnotationOrDesignation),
    });

    const sharedButtons = (
      <div className={`${CX}--header--buttons`}>
        {button &&
          cloneElement(button, {
            automationId: "pdf-menu-button",
          })}
      </div>
    );

    if (canViewDownloadOriginalPdf || canProvideDocumentFeedback || splitAndTagDocuments) {
      return (
        <div className={headerCX}>
          {sharedButtons}
          {button && (canViewDownloadOriginalPdf || splitAndTagDocuments) && (
            <div className={`${CX}--header--buttons`}>
              <Button
                variant="secondary"
                buttonColor="dark"
                buttonSize="condensed"
                automationId="original-document-link"
                className={`${CX}--header--buttons--secondaryButton`}
                onClick={() => {
                  if (document?.s3UploadedAsset?.url) {
                    window.open(document.s3UploadedAsset.url, "_blank");
                  }
                }}
              >
                <FormattedMessage
                  id="4ac3494a-b443-4f96-b271-798f87ac6e0b"
                  defaultMessage="View/Download Original PDF"
                />
              </Button>
            </div>
          )}
          {!document.isEnote &&
            (!documentSplitManager || documentSplitManager.splits.length === 0) &&
            (canProvideDocumentFeedback || splitAndTagDocuments) && (
              <div className={`${CX}--header--toggles`}>
                <TabRow
                  initialTabIndex={this.state.currentMenuType || 0}
                  onSelect={this.onSelectMenuType}
                >
                  <TabButton
                    title={
                      <FormattedMessage
                        id="3030367e-9b37-47b6-85f3-3ea8a9a99551"
                        defaultMessage="Feedback"
                      />
                    }
                    automationId="feedback-tab"
                  />
                  <TabButton
                    title={
                      <FormattedMessage
                        id="86408076-0dfc-4047-8fc0-eb094d0bd70f"
                        defaultMessage="Tools"
                      />
                    }
                    automationId="tools-tab"
                  />
                </TabRow>
              </div>
            )}
        </div>
      );
    }

    return (
      <div className={headerCX}>
        {title && (
          <Heading level="h1" textStyle="subtitleSmall" data-automation-id="pdf-menu-header-title">
            {title}
          </Heading>
        )}
        {headerInfo && (
          <Paragraph textColor="subtle" data-automation-id="pdf-menu-header-info">
            {headerInfo}
          </Paragraph>
        )}
        {buttonRequirements && buttonRequirements.length > 0 && (
          <ul
            className={`${CX}--header--buttonRequirements`}
            data-automation-id="button-requirements"
          >
            {buttonRequirements.map((requirement, index) => {
              return <li key={`requirement-${index}`}>{requirement}</li>;
            })}
          </ul>
        )}
        {sharedButtons}
      </div>
    );
  }

  renderTools = () => {
    const {
      data,
      currentToolData,
      currentSignerNumber,
      dispatch,
      footer,
      document,
      signerDropdownOnly,
    } = this.props;
    if (!data) {
      return null;
    }

    const signerDropdown = data.signers.length > 1 && (
      <SignerDropdown
        items={data.signers.map((signer) => ({
          name: signer.name,
          value: signer.number,
          colorHex: signer.colorHex,
        }))}
        onChange={(value) => dispatch(setSigner({ number: value }))}
        value={currentSignerNumber}
        previousState={this.state.previousState}
        setPreviousState={(state) => {
          this.setState({ previousState: state });
        }}
      />
    );

    if (signerDropdownOnly) {
      return <>{signerDropdown}</>;
    }

    const toolsets = data.toolsets.map((toolset, index) => {
      if (toolset.signerNumber && currentSignerNumber !== toolset.signerNumber) {
        return null;
      }

      // we only want to show shortcuts once so we tie it to the first toolset
      const showShortcuts = CURRENT_PORTAL !== APPS.CUSTOMER && index === 0;

      return (
        <PdfMenuToolset
          key={`toolset-${index}`}
          title={toolset.title}
          automationId={toolset.automationId}
          subtitle={toolset.subtitle}
          dropdown={toolset.signerNumber ? signerDropdown : undefined}
          headerAction={
            showShortcuts ? (
              <Link
                href="/shortcut-cheatsheet"
                underlined={false}
                className={`${CX}--shortcut--link`}
              >
                <FormattedMessage
                  id="b18aea64-c597-4c73-a202-b0e9a22369c2"
                  defaultMessage="View shortcuts"
                />
                <Icon className={`${CX}--shortcut--icon`} name="new-window" />
              </Link>
            ) : undefined
          }
        >
          {toolset.tools.map((tool) => (
            <PdfMenuTool
              key={tool.automationId}
              automationId={tool.automationId}
              selected={isEqual(tool.data, currentToolData)}
              shortcutKey={tool.shortcutKey}
              label={tool.label}
              sublabel={tool.sublabel}
              iconName={tool.iconName}
              onToolClick={() => dispatch(toolClicked({ data: tool.data }))}
            />
          ))}
        </PdfMenuToolset>
      );
    });

    return (
      <>
        {toolsets}
        {document.hidden && (
          <div data-automation-id="hidden-premeeting-message" className={`${CX}--footer`}>
            <FormattedMessage
              id="a2d5ec44-f771-4e1e-8f17-6d969532a222"
              defaultMessage="Unhide to add fields."
              tagName="p"
            />
          </div>
        )}
        {footer && (
          <div data-automation-id="pdf-menu-footer" className={`${CX}--footer`}>
            {footer}
          </div>
        )}
      </>
    );
  };

  renderContent() {
    const {
      document,
      selectedAnnotationOrDesignation,
      selectedDesignationGroup,
      documentSplitManager,
      data,
      currentSignerNumber,
      designationHandlers,
      annotationHandlers,
      conditionalEditModeDetails,
      userCanModifyDoc,
      currentToolData,
      showAnchorTextTags,
    } = this.props;
    const { currentMenuType } = this.state;
    const conditionalEditMode = Boolean(conditionalEditModeDetails?.conditionalEditMode);
    const toggleConditionalEditMode = conditionalEditModeDetails?.toggleConditionalEditMode;

    if (!data) {
      return null;
    }
    const conditionalRules = document.conditionalRules || {};
    function canHandleSetConditionalEditMode(designation) {
      const dependentDesignations = Object.values(conditionalRules).flat();
      const isDependent = dependentDesignations.some((dependent) => dependent === designation.id);
      const isNotary = designation.signerRole.index === "notary";

      return !isNotary && !isDependent;
    }

    if (selectedAnnotationOrDesignation?.__typename === "AnnotationDesignation") {
      const showConditionalSidebar = canHandleSetConditionalEditMode(
        selectedAnnotationOrDesignation,
      );
      const newconditionalEditModeDetails = {
        conditionalEditMode,
        toggleConditionalEditMode: showConditionalSidebar ? toggleConditionalEditMode : undefined,
      };
      return userCanModifyDoc === false ? null : (
        <DesignationMenu
          designation={selectedAnnotationOrDesignation}
          designationGroup={selectedDesignationGroup}
          signers={data.signers}
          currentSignerNumber={currentSignerNumber}
          onDelete={designationHandlers.onDelete}
          onDeleteGroup={designationHandlers.onDeleteGroup}
          onAddToGroup={designationHandlers.onAddToGroup}
          onReassign={designationHandlers.onReassign}
          onReassignGroup={designationHandlers.onReassignGroup}
          onUpdateGroupRequirements={designationHandlers.onUpdateGroupRequirements}
          document={document}
          conditionalEditModeDetails={newconditionalEditModeDetails}
          onSetOptional={designationHandlers.onSetOptional}
          toolsets={data.toolsets}
          currentToolData={currentToolData}
          showAnchorTextTags={showAnchorTextTags}
        />
      );
    } else if (SUPPORTED_ANNOTATIONS.includes(selectedAnnotationOrDesignation?.__typename)) {
      return (
        <AnnotationMenu
          annotation={selectedAnnotationOrDesignation}
          onDelete={annotationHandlers.onDelete}
          showAnchorTextTags={showAnchorTextTags}
        />
      );
    }

    if (documentSplitManager && documentSplitManager.splits.length > 0) {
      return <DocumentSplits documentSplitManager={documentSplitManager} document={document} />;
    }

    switch (currentMenuType) {
      case PDF_MENU_TYPES.MANUAL:
        return this.renderTools();
      case PDF_MENU_TYPES.FEEDBACK:
        return <DocumentFeedback documentId={document.id} />;
      default:
        return this.renderTools();
    }
  }

  render() {
    return (
      <div
        className={!this.props.signerDropdownOnly ? CX : undefined}
        data-automation-id="pdf-menu"
      >
        {!this.props.hideHeader && this.renderHeader()}
        {this.renderContent()}
      </div>
    );
  }
}

PdfMenu.propTypes = {
  title: PropTypes.node,
  data: PropTypes.object,
  userCanModifyDoc: PropTypes.bool,
  button: PropTypes.node,
  headerInfo: PropTypes.node,
  footer: PropTypes.node,
  splitAndTagDocuments: PropTypes.bool,
  buttonRequirements: PropTypes.arrayOf(PropTypes.node),
  document: PropTypes.shape({
    id: PropTypes.string,
    hidden: PropTypes.bool,
    s3UploadedAsset: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  selectedAnnotationOrDesignation: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  selectedDesignationGroup: PropTypes.shape({
    id: PropTypes.string.isRequired,
    minimumFulfilled: PropTypes.number,
    maximumFulfilled: PropTypes.number,
    size: PropTypes.number,
  }),
  documentSplitManager: PropTypes.object,
  conditionalEditModeDetails: PropTypes.shape({
    conditionalEditMode: PropTypes.bool,
    toggleConditionalEditMode: PropTypes.func,
  }),
  designationHandlers: PropTypes.shape({
    onDelete: PropTypes.func,
    onReassign: PropTypes.func,
  }),
  annotationHandlers: PropTypes.shape({
    onDelete: PropTypes.func,
  }),
  hideHeader: PropTypes.bool,
  signerDropdownOnly: PropTypes.bool, // You probably don't want to use this, this component needs to be refactored.
  showAnchorTextTags: PropTypes.bool,

  // connect
  dispatch: PropTypes.func.isRequired,
  currentToolData: PropTypes.object,
  currentSignerNumber: PropTypes.number.isRequired,
  hasPermissionFor: PropTypes.func,
};

PdfMenu.defaultProps = {
  title: null,
  button: null,
  footer: null,
  buttonRequirements: [],
};

export default connect(({ pdfMenu }) => ({
  currentToolData: pdfMenu.currentToolData,
  currentSignerNumber: pdfMenu.currentSignerNumber,
}))(PdfMenu);

export function useCurrentToolData() {
  return useSelector(({ pdfMenu }) => pdfMenu.currentToolData);
}
