import { FormattedMessage } from "react-intl";

import Tab from "common/core/tabs/tab";

import { TabIncompleteIcon } from "../incomplete_icon";

const STEP_CONTENT = Object.freeze({
  PersonalInfo: (
    <FormattedMessage
      id="17822465-af46-43a7-ad76-569e8e5f14ef"
      defaultMessage="Personal Information"
    />
  ),
  CommissionAndRonInfo: (
    <FormattedMessage
      id="80a613bb-e593-4ab7-976e-a9f95f51ca4c"
      defaultMessage="Commission Details"
    />
  ),
  InsuranceDetails: (
    <FormattedMessage
      id="085697e9-c9e9-4406-b994-4b80fa1552db"
      defaultMessage="Insurance Details"
    />
  ),
  NNAInfo: <FormattedMessage id="582b84d3-9112-43e5-bbb0-ea42327039f2" defaultMessage="NNA Info" />,
  DigitalCertificate: (
    <FormattedMessage
      id="e969494f-c874-4aa2-b817-7cb56c841cae"
      defaultMessage="Digital Certificate"
    />
  ),
  SignatureAndSeal: (
    <FormattedMessage
      id="cb19ff58-06aa-4b47-a068-d7db1e46a847"
      defaultMessage="Signature and Seal"
    />
  ),
  StateEducation: (
    <FormattedMessage
      id="ad924c22-b107-4614-8bcf-b9cb11427301"
      defaultMessage="State Required Education"
    />
  ),
  PayoutOptions: (
    <FormattedMessage id="7ef9ff7e-f735-46ad-a366-3b3bcacac6d0" defaultMessage="Payment Info" />
  ),
  TermsOfUse: (
    <FormattedMessage
      id="2e9f8f1f-a8c0-4435-b526-407a6fab906b"
      defaultMessage="Terms & Conditions"
    />
  ),
  ProofCertificate: (
    <FormattedMessage
      id="9fe66756-86cf-4bc3-af4c-63c33f55e3cd"
      defaultMessage="Digital Certificate"
    />
  ),
  IdentityVerification: (
    <FormattedMessage id="9d78aa7d-30cb-48f7-b2df-5e7d08afbb07" defaultMessage="Verify Identity" />
  ),
});

type Props = readonly Readonly<{
  id: keyof typeof STEP_CONTENT;
  completed?: boolean;
  route?:
    | "personal-info"
    | "commission"
    | "insurance"
    | "digital-certificate"
    | "signature-and-seal"
    | "payout-options" // If this is changed, stripe connect redirect whitelist must also be updated
    | "education"
    | "proof-certificate"
    | "identity-verification";
}>[];

export function getTabs(sections: Props) {
  // TermsOfUse is a duplicated in user settings, and clean up will be easier
  // if we just filter it out here. It can be removed with the ia-nav flag.
  // Similarly with PayoutOut options, we can clean it out when ia-nav is on.
  return sections
    .filter((section) => section.id !== "TermsOfUse" && section.id !== "PayoutOptions")
    .map(({ completed, id, route }) => {
      return (
        <Tab key={id} automationId={`${id}-tab`} to={`/settings/notary/profile/${route}`}>
          {STEP_CONTENT[id]}
          {!completed && <TabIncompleteIcon />}
        </Tab>
      );
    });
}
