import Env from "config/environment";
import { useFeatureFlag } from "common/feature_gating";
import { getJSONAPIHeaders } from "util/http";

const bufferTimeOut = 500;
const PROOF_EVENTS = "proof-events";
type ExperimentEvent = {
  name: string;
  action: string;
  groupAssignment: string;
  properties?: unknown;
  trackedAt: number;
  posted?: number;
};
type ExperimentEventPost = {
  experiment_name: string;
  action: string;
  group_assignment: unknown;
  properties?: unknown;
};
type ExperimentStore = {
  events: Record<string, ExperimentEvent | undefined>;
  timer?: NodeJS.Timeout;
};
declare const window: Window & { experimentTrack?: ExperimentStore };

function experimentStore(setVal?: ExperimentStore): ExperimentStore {
  const storeInit = { events: {}, timer: undefined };
  const store: ExperimentStore = setVal || storeInit;
  if (setVal) {
    sessionStorage.setItem(PROOF_EVENTS, JSON.stringify(setVal.events));
  } else {
    try {
      const eventsStore = sessionStorage.getItem(PROOF_EVENTS);
      if (eventsStore) {
        const events = JSON.parse(eventsStore) as ExperimentStore["events"];
        store.events = events;
      }
    } catch {
      return storeInit;
    }
  }
  return store;
}

function trackPost() {
  if (window.experimentTrack?.events) {
    const eventPostArray: ExperimentEventPost[] = [];
    // track in order of trackedAt timestamp
    const eventsArray = Object.values(window.experimentTrack.events).sort((a, b) =>
      a && b ? a.trackedAt - b.trackedAt : 0,
    );
    eventsArray.forEach((event) => {
      if (event && !event.posted) {
        eventPostArray.push({
          experiment_name: event.name,
          action: event.action,
          group_assignment: event.groupAssignment,
          ...(event.properties ? { properties: event.properties } : {}),
        });
        event.posted = Date.now();
      }
    });
    experimentStore(window.experimentTrack);

    eventPostArray.length &&
      window.fetch(`${Env.apiHost}/te2`, {
        method: "POST",
        credentials: "include" as RequestCredentials,
        headers: {
          ...getJSONAPIHeaders(),
          "X-Notarize-Event-Payload": JSON.stringify(eventPostArray),
        },
      });
  }
}

function trackExperiment({
  name,
  action,
  groupAssignment,
  properties,
}: Omit<ExperimentEvent, "trackedAt">) {
  if (!window.experimentTrack) {
    window.experimentTrack = experimentStore();
  }
  const current = window.experimentTrack.events[`${name}-${action}`];
  if (!current || current.groupAssignment !== groupAssignment) {
    // store unique name + action for save during debounce call
    window.experimentTrack.events[`${name}-${action}`] = {
      name,
      action,
      groupAssignment,
      properties,
      trackedAt: Date.now(),
      posted: undefined,
    };
    clearTimeout(window.experimentTrack.timer);
    window.experimentTrack.timer = setTimeout(trackPost, bufferTimeOut);
  }
}

const EXPERIMENT_GROUP_DEFAULT = "control";
const EXPERIMENT_OFF = "off";

function cleanValue(val?: string | null) {
  // val could possible be a boolean or obj
  // toString to make sure we get a string representation of the value
  return (val ?? EXPERIMENT_OFF).toString().toLowerCase().trim();
}
function compareValues(featureVal?: string, groupVal?: string) {
  return cleanValue(featureVal) === cleanValue(groupVal);
}

export const CURRENT_EXPERIMENTS = {
  SignerUploadH1: {
    flag: "signer-upload-h1-experiment",
    A: "a-need-a-notary",
    B: "b-notarize-online",
  },
  RetailMeetingComplete: {
    flag: "meeting-completion-expt",
    A: "a-download-and-share",
    B: "b-esign-copy",
  },
};

export type ExperimentGroupsProps = {
  flag: string;
  organization?: { id: string; featureFlags: { key: string; value: string }[] };
  possibleGroups?: string[];
  precedence?: "org" | "ld" | "match";
  skip?: boolean;
};

// https://notarize.atlassian.net/wiki/spaces/EN/pages/3740532973/A+B+Experiments+using+LaunchDarkly+percentage+rollout+and+Metabase+reporting+with+experiment_events
export function useExperimentGroup({
  flag,
  organization,
  possibleGroups,
  precedence = "org",
  skip = false,
}: ExperimentGroupsProps): string | null {
  // get the value from ld default to control
  const ldFeature = String(useFeatureFlag<string>(flag, EXPERIMENT_OFF));
  // find the org feature that matches the flag default to control
  const orgFeature = organization?.featureFlags.find((f) => f.key === flag)?.value;

  let groupAssignment = ldFeature === EXPERIMENT_OFF ? EXPERIMENT_OFF : EXPERIMENT_GROUP_DEFAULT;
  if (skip) {
    return null;
  } else if (precedence === "match" && compareValues(ldFeature, orgFeature)) {
    // if org and ld match return the orgFeature value
    groupAssignment = cleanValue(orgFeature);
  } else if (precedence === "org" && orgFeature !== undefined) {
    // if we have an org feature value and precedence is org use it first
    groupAssignment = cleanValue(orgFeature);
  } else if (precedence !== "match" && ldFeature !== EXPERIMENT_OFF) {
    // if precedence is ld or no org feature use ld feature
    groupAssignment = cleanValue(ldFeature);
  } else if (precedence === "ld" && orgFeature !== undefined) {
    // if precedence is ld and no ld feature but we have org feature use it now
    groupAssignment = cleanValue(orgFeature);
  }
  // if groupAssignment is the "off" value then do not track and return default
  if (groupAssignment === EXPERIMENT_OFF) {
    return EXPERIMENT_GROUP_DEFAULT;
  }
  // if list of possibleGroups is passed in make sure the assignment is within it
  if (possibleGroups && !possibleGroups.includes(groupAssignment)) {
    groupAssignment = EXPERIMENT_GROUP_DEFAULT;
  }
  trackExperiment({
    name: flag,
    action: "experiment-group",
    groupAssignment,
    properties: { precedence, org_id: organization?.id },
  });
  return groupAssignment;
}
