import "./row.scss";
import { useState, type ChangeEvent } from "react";

import RowTitle from "common/document/uploader/manager/row_title";
import { getDocumentIneligibleReason } from "common/banners/ineligible_notarization_banner";

import type { UploadedDocument } from "./multi_uploader";

type Props = {
  documentIndex: number;
  document: UploadedDocument;
  onDocumentDelete: (document: UploadedDocument) => void;
  onStopRenameDocument: (document: UploadedDocument, newName: string | undefined) => void;
  showIneligibleWarning?: boolean;
};

export default function MultiUploadRow({
  document,
  documentIndex,
  onDocumentDelete,
  onStopRenameDocument,
  showIneligibleWarning,
}: Props) {
  const [newName, setNewName] = useState<string | undefined>();
  const { name, status } = document;
  const ineligibleReason = getDocumentIneligibleReason([document]);
  const showIneligibleDocumentBanner = ineligibleReason && showIneligibleWarning;

  return (
    <li
      className={`MultiUploaderRow ${showIneligibleDocumentBanner ? "ineligible-document" : ""}`}
      data-document-id={document.id}
    >
      <RowTitle
        name={name}
        index={documentIndex}
        status={status}
        workingTitle={newName}
        onDeleteDocument={() => onDocumentDelete(document)}
        onStartRename={() => {
          const lastIndex = name.lastIndexOf(".") > 0 ? name.lastIndexOf(".") : name.length;
          const newnewName = name.slice(0, lastIndex);
          setNewName(newnewName);
        }}
        onStopRename={() => {
          onStopRenameDocument(document, newName);
          setNewName(undefined);
        }}
        onCancelRename={() => {
          setNewName(undefined);
        }}
        onChangeTitle={(event: ChangeEvent<HTMLInputElement>) => setNewName(event.target.value)}
        onResubmitDocument={() => {}}
        cxName="MultiRowTitle"
        truncateName={false}
        ineligibleReason={showIneligibleDocumentBanner ? ineligibleReason : undefined}
        disableClick
        allowRename
        allowDelete
      />
    </li>
  );
}
