import { useEffect, useRef, useState } from "react";
import type ScanbotInstance from "scanbot-web-sdk";
import type { DocumentDetectionResult } from "scanbot-web-sdk/@types/model/document/document-detection-result";
import { defineMessages, useIntl } from "react-intl";

import { grantedPermissions } from "util/permissions";
import { captureException } from "util/exception";
import { isiOSDevice } from "util/support";

const LICENSE_KEY =
  "pcWO3ZbMOLKilBQ70MW5aAxzSpZCD+rPcaawW3toSVjAQfG2B5JJv2PX6m1NyV3jzHexijWoVqZICsqz0lYy5eLk0zElpWAdZtCYAA1BAGCQDv+V8r2YwM7NFzz83c8y3q0PY2UcyHa1O84n8aAyEZE2gUYrid/bGmJoTtVw6Xyqpahex18stS3WOdHGl2lO7H7MLwll7ZO06D99jnywYuC+KnD2pQH3BhAbIGsE/K8SUGxjapW2D/2f/0Mo5Rh+jOzn5qJIBmZ2RN1io1VShAAcmMQ6nUdWJK4ltlp6OaB1tQ7scwrNde/bFwMVrBHRfRPAc1rDIc9s+QiFY0Zc1Q==\nU2NhbmJvdFNESwoqcHJvb2YuY29tfCpwcm9vZi5jb2FjaHwqbm90YXJpemUucm9ja3N8bG9jYWxob3N0CjE3Mzk3NTAzOTkKNTkwCjg=\n";

function useLazyScanbot() {
  const [instance, setInstance] = useState<ScanbotInstance>();
  useEffect(() => {
    import("scanbot-web-sdk")
      .then((module) => {
        return module.default.initialize({
          licenseKey: LICENSE_KEY,
          engine: "/scanbot-sdk-resources/",
        });
      })
      .then(setInstance);
  }, []);
  return instance;
}

// Cannot directly import enum from "scanbot-web-sdk/@types/model/document/detection-status"
// since its in a .d.ts so it won't have a compiled output. Copying over to have our own declaration
export enum DetectionStatus {
  NotAcquired = "NotAcquired",
  OK = "OK",
  /* eslint-disable @typescript-eslint/naming-convention */
  OK_SmallSize = "OK_SmallSize",
  OK_BadAngles = "OK_BadAngles",
  OK_BadAspectRatio = "OK_BadAspectRatio",
  OK_OffCenter = "OK_OffCenter",
  Error_NothingDetected = "Error_NothingDetected",
  Error_Brightness = "Error_Brightness",
  Error_Noise = "Error_Noise",
  /* eslint-enable @typescript-eslint/naming-convention */
}

export type DocumentScannerError = "media_permission_error" | "unknown_error";

export type CaptureResult = { imageBuffer: ArrayBuffer; dataUrl: string };

type DocScannerCaptureState =
  | { state: "loading" }
  | { state: "error"; error: DocumentScannerError }
  | { state: "ready" };
type OnCapture = (
  result:
    | ({ success: true } & CaptureResult)
    | { success: false; detectionStatus: DetectionStatus },
) => void;
export type UseDocScannerCapture = (args: {
  containerId: string;
  onCapture: OnCapture;
}) => DocScannerCaptureState;

type RotateImage = (captureResult: CaptureResult) => Promise<CaptureResult>;
type GeneratePdf = (
  captureResults: CaptureResult[],
) => Promise<{ blob: BlobPart; filename: string }>;

type DocScannerState =
  | { state: "loading" }
  | { state: "error" }
  | {
      state: "ready";
      useDocScannerCapture: UseDocScannerCapture;
      rotateImage: RotateImage;
      generatePdf: GeneratePdf;
    };

const messages = defineMessages({
  OK: {
    id: "190b41af-cb29-439e-ab13-721020f70964",
    defaultMessage: "Capturing your document... Please do not move the camera.",
  },
  OK_SmallSize: {
    id: "665b7e2f-95bb-4c8a-a6d3-ee76c78568d2",
    defaultMessage: "The document is too small. Try moving closer.",
  },
  OK_BadAngles: {
    id: "1dcbc271-d0bc-46cd-8c3c-2ccb4e09357a",
    defaultMessage: "This is a bad camera angle. Hold the device straight over the document.",
  },
  OK_BadAspectRatio: {
    id: "fc0fde8f-72e0-4b95-accd-48e0cbf5c1a0",
    defaultMessage: "Rotate the device sideways, so that the document fits better into the screen.",
  },
  OK_OffCenter: {
    id: "159738ba-c280-4a23-9c0e-954518ffa3b5",
    defaultMessage: "Try holding the device at the center of the document.",
  },
  Error_NothingDetected: {
    id: "02ab81a4-5fb8-4512-8a30-8d50833cef08",
    defaultMessage: "Please hold the device over a document to start scanning.",
  },
  Error_Brightness: {
    id: "ab1aea3a-8a32-497b-9c08-353ff4991af1",
    defaultMessage: "It is too dark. Try turning on a light.",
  },
  Error_Noise: {
    id: "9e556d17-3de5-4095-8f49-22146c8ce859",
    defaultMessage:
      "Place your document on a solid, non-reflective background in a well-lit space.",
  },
});

export function useDocScanner() {
  const scanbotInstance = useLazyScanbot();
  const [scannerState, setScannerState] = useState<DocScannerState>({ state: "loading" });

  useEffect(() => {
    if (scanbotInstance) {
      scanbotInstance
        .getLicenseInfo()
        .then((licenseInfo) => {
          if (!licenseInfo.isValid()) {
            setScannerState({ state: "error" });
            return;
          }

          const useDocScannerCapture = ({
            containerId,
            onCapture,
          }: {
            containerId: string;
            onCapture: OnCapture;
          }) => {
            const intl = useIntl();
            const scannerHandle = useRef<{ dispose: () => void }>();
            const [captureState, setCaptureState] = useState<DocScannerCaptureState>({
              state: "loading",
            });

            const checkGrantedPermissions = () => {
              grantedPermissions().then((permissions) => {
                const { webcam } = permissions;
                if (!webcam) {
                  setCaptureState({ state: "error", error: "media_permission_error" });
                }
              });
            };

            // permissions.query is not available on all versions of Safari
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (navigator?.permissions?.query) {
              navigator.permissions
                .query({ name: "camera" as unknown as PermissionName })
                .then((status) => {
                  // onchange not (yet) available on safari so can only rely on rerender when shutter button clicked
                  if (isiOSDevice()) {
                    if (status.state !== "granted") {
                      setCaptureState({ state: "error", error: "media_permission_error" });
                    }
                  } else {
                    status.onchange = () => {
                      if (status.state !== "granted") {
                        setCaptureState({ state: "error", error: "media_permission_error" });
                      }
                    };
                  }
                })
                .catch(() => {
                  checkGrantedPermissions();
                });
            } else {
              checkGrantedPermissions();
            }

            useEffect(() => {
              scanbotInstance
                .createDocumentScanner({
                  onError: captureException,
                  containerId,
                  autoCaptureEnabled: true,
                  async onDocumentDetected({ cropped, detectionStatus }: DocumentDetectionResult) {
                    if (!cropped || detectionStatus !== DetectionStatus.OK) {
                      return;
                    }
                    scanbotInstance.utils.flash();
                    onCapture({
                      success: true,
                      imageBuffer: cropped,
                      dataUrl: await scanbotInstance.toDataUrl(cropped),
                    });
                  },
                  text: {
                    hint: {
                      OK: intl.formatMessage(messages.OK),
                      OK_SmallSize: intl.formatMessage(messages.OK_SmallSize),
                      OK_BadAngles: intl.formatMessage(messages.OK_BadAngles),
                      OK_BadAspectRatio: intl.formatMessage(messages.OK_BadAspectRatio),
                      OK_OffCenter: intl.formatMessage(messages.OK_OffCenter),
                      Error_NothingDetected: intl.formatMessage(messages.Error_NothingDetected),
                      Error_Brightness: intl.formatMessage(messages.Error_Brightness),
                      Error_Noise: intl.formatMessage(messages.Error_Noise),
                    },
                  },
                })
                .then((handle) => {
                  scannerHandle.current = handle;
                  setCaptureState({ state: "ready" });
                })
                .catch((error) => {
                  if (error.name === "MediaPermissionError") {
                    setCaptureState({ state: "error", error: "media_permission_error" });
                  } else {
                    setCaptureState({ state: "error", error: "unknown_error" });
                  }
                  captureException(error);
                });

              return () => {
                scannerHandle.current?.dispose();
              };
            }, []);

            return captureState;
          };

          const rotateImage: RotateImage = async (captureResult) => {
            const rotatedImageBuffer = await scanbotInstance.rotateImageCcw(
              captureResult.imageBuffer,
              3,
            );
            return {
              imageBuffer: rotatedImageBuffer,
              dataUrl: await scanbotInstance.toDataUrl(rotatedImageBuffer),
            };
          };

          const generatePdf: GeneratePdf = async (captureResults) => {
            const pdfGenerator = await scanbotInstance.beginPdf({ standardPaperSize: "LETTER" });
            await Promise.all(
              captureResults.map(async (captureResult) => {
                await pdfGenerator.addPage(captureResult.imageBuffer);
              }),
            );
            return {
              blob: await pdfGenerator.complete(),
              filename: `scanned ${new Date().toLocaleString()}.pdf`,
            };
          };

          setScannerState({
            state: "ready",
            useDocScannerCapture,
            rotateImage,
            generatePdf,
          });
        })
        .catch((error) => {
          captureException(error);
          setScannerState({ state: "error" });
        });
    }
  }, [scanbotInstance]);

  return scannerState;
}
