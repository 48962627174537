import { useState, type ReactNode } from "react";
import { CardNumberElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";

import Button from "common/core/button";
import { useMutation } from "util/graphql";
import { invalidCard } from "errors/credit_card";
import { getLazyStripe, StripeCardElementsUIForReactHookForm } from "common/settings/payment/util";
import Icon from "common/core/icon";
import { useForm } from "common/core/form";
import LegalCopy from "common/settings/payment/legal_copy";

import Styles from "./payment_form.module.scss";
import CreateCardMutation from "./create_card_mutation.graphql";

type ContainerProps = {
  onSuccess: () => void;
  onError: () => void;
  buttonLabel?: ReactNode;
  formTitle?: string;
  formNotice?: string;
};
type FormProps = {
  buttonLabel?: ReactNode;
  formTitle?: string;
  formNotice?: string;
  onSuccess: () => void;
  onError: () => void;
};

function PaymentForm({ buttonLabel, onSuccess, onError }: FormProps) {
  const stripe = useStripe();
  const elements = useElements();
  const createCard = useMutation(CreateCardMutation);
  const defaultValues = {
    cardName: "",
    cardNumber: "",
    cardExpiry: "",
    cardCvc: "",
  };
  const form = useForm({
    defaultValues,
  });
  const {
    handleSubmit,
    reset,
    formState: { isValid, isSubmitting },
  } = form;
  const save = (values: { cardName: string }) => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const { cardName } = values;
    const cardElement = elements.getElement(CardNumberElement);
    return new Promise(() => {
      stripe
        .createToken(cardElement!, { name: cardName })
        .then((result) => {
          return createCard({
            variables: {
              mutationInput: { cardToken: result.token!.id },
            },
          })
            .then(() => {
              reset(defaultValues);
            })
            .catch((error) => {
              onError();
              return Promise.reject(invalidCard({ message: error?.graphQLErrors?.[0]?.specifics }));
            });
        })
        .then(onSuccess);
    });
  };

  const [cardNumberComplete, setCardNumberComplete] = useState(false);
  const [cardExpiryComplete, setCardExpiryComplete] = useState(false);
  const [cardCvcComplete, setCardCvcComplete] = useState(false);
  const cardComplete = cardNumberComplete && cardExpiryComplete && cardCvcComplete;
  const changeCardElement = (evt: { elementType: string; complete: boolean }) => {
    switch (evt.elementType) {
      case "cardNumber":
        setCardNumberComplete(evt.complete);
        break;
      case "cardExpiry":
        setCardExpiryComplete(evt.complete);
        break;
      case "cardCvc":
        setCardCvcComplete(evt.complete);
        break;
      default:
        return null;
    }
  };

  return (
    <form
      className={Styles.form}
      onSubmit={handleSubmit(save)}
      data-automation-id="customer-payment-form"
    >
      <StripeCardElementsUIForReactHookForm
        changeCardElement={changeCardElement}
        form={form}
        showLabels
      />
      <LegalCopy className={Styles.legalCopy} />
      <Button
        isLoading={isSubmitting}
        disabled={!stripe || !cardComplete || !isValid}
        type="submit"
        buttonSize="large"
        buttonColor="action"
        variant="primary"
        fullwidth
        automationId="save-changes-button"
      >
        <Icon name="lock" /> {buttonLabel}
      </Button>
    </form>
  );
}

export default function StripePaymentFormContainer(props: ContainerProps) {
  return (
    <Elements stripe={getLazyStripe()}>
      <PaymentForm {...props} />
    </Elements>
  );
}
