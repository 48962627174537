import { useEffect, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import { Paragraph } from "common/core/typography";
import { RadioGroup, RadioInput, RadioLabel } from "common/core/form/option";
import { CurrencyInput } from "common/core/form/text";
import { Feature, Payer } from "graphql_globals";
import { useWatch } from "common/core/form";
import SROnly from "common/core/screen_reader";
import FormattedPrice from "common/core/format/formatted_price";
import {
  notaryMustCollectExternalPayment,
  ONLY_EXTERNAL_PAYMENT_NOTICE,
} from "common/notary/payment";
import { useId } from "util/html";
import { Label } from "common/core/form/layout";

import { type SectionContract, type SectionComponentProps } from "../../form";
import {
  Card,
  ConfiguredField,
  ConfiguredRadioGroup,
  downgradeDisplay,
  readonlyField,
  SectionHeader,
} from "../../common";
import type { NstAccountPricing } from "./nst_account_pricing_fragment.graphql";
import type { NstPricing } from "./transaction_fragment.graphql";
import type { NstUser } from "./nst_user_fragment.graphql";
import Styles from "./index.module.scss";

const MESSAGES = defineMessages({
  payment: {
    id: "d3bc4d04-2059-470c-addc-b979616c3d90",
    defaultMessage: "Payment",
  },
  notarizationFee: {
    id: "92961ff2-23b9-427d-9d74-b017d83ca3d9",
    defaultMessage: "Notarization fees",
  },
  additionalSeal: {
    id: "8d53b163-07cf-42e9-b3b0-6bea2329311e",
    defaultMessage: "Additional seal",
  },
  feeInputError: {
    id: "3991a153-6f9f-471b-83ee-f39dc0fb1716",
    defaultMessage: "Value equal to or greater than 0 is required",
  },
});

const CONFIGS = {
  nstPayer: "nstPayer",
  customFees: "customFees",
  canCollectNotaryPayment: "canCollectNotaryPayment",
} as const;

const FORM_FIELDS = {
  nstPayer: "nstPayer",
  notarizationFee: "notarizationFee",
  additionalSealFee: "additionalSealFee",
} as const;

export type NstPaymentFormValues = {
  [FORM_FIELDS.nstPayer]: Payer;
  [FORM_FIELDS.notarizationFee]: number;
  [FORM_FIELDS.additionalSealFee]: number;
};

export type CustomFeesType = {
  [FORM_FIELDS.notarizationFee]?: number;
  [FORM_FIELDS.additionalSealFee]?: number;
};

type NstPaymentSubmitData = {
  payer: Payer;
  notaverseFirstSeal: number;
  notaverseAdditionalSeal: number;
};

function NstPaymentSection({
  form,
  organization,
  config,
}: SectionComponentProps<NstPricing, NstAccountPricing, NstUser>) {
  const { control, setValue } = form;

  const { notarizationFee, additionalSealFee } = form.getValues();
  const { notaverseFirstSeal, notaverseAdditionalSeal } = organization.activeTier.prices;
  const accountNotaverseFirstSeal = notaverseFirstSeal / 100;
  const accountNotaverseAdditionalSeal = notaverseAdditionalSeal / 100;

  const defaultFeeState =
    (notarizationFee && notarizationFee !== accountNotaverseFirstSeal) ||
    (additionalSealFee && additionalSealFee !== accountNotaverseAdditionalSeal)
      ? "custom"
      : "default";

  const [fees, setFees] = useState<"default" | "custom">(defaultFeeState);
  const payer = useWatch({ control, name: "nstPayer" });

  const intl = useIntl();
  const notarizationFeeLabelId = useId();
  const additionalSealLabelId = useId();

  useEffect(() => {
    if (fees === "default") {
      setValue("notarizationFee", accountNotaverseFirstSeal);
      setValue("additionalSealFee", accountNotaverseAdditionalSeal);
    }
    if (payer === Payer.ORGANIZATION) {
      setValue("notarizationFee", 0);
      setValue("additionalSealFee", 0);
    }
  }, [payer, fees]);

  return (
    <>
      <SectionHeader iconName="card">{intl.formatMessage(MESSAGES.payment)}</SectionHeader>
      <Card title={intl.formatMessage(MESSAGES.payment)}>
        {!config.canCollectNotaryPayment ? (
          <Paragraph>{ONLY_EXTERNAL_PAYMENT_NOTICE}</Paragraph>
        ) : (
          <>
            <ConfiguredRadioGroup
              radioGroupLabel={
                <FormattedMessage
                  id="dbae916e-26bf-4ddd-b952-337368955f82"
                  defaultMessage="How will you bill the signer for this transaction?"
                />
              }
              config={config}
              configField={CONFIGS.nstPayer}
              form={form}
              className={Styles.radioGroup}
              name={FORM_FIELDS.nstPayer}
              radioButtons={[
                {
                  label: (
                    <FormattedMessage
                      id="812c4657-9a39-4b0a-a867-92fc074de906"
                      defaultMessage="Pay on Proof"
                    />
                  ),
                  value: Payer.CUSTOMER,
                  "data-automation-id": "payer-signer-radio-button",
                },
                {
                  label: (
                    <FormattedMessage
                      id="5dc60f13-4d89-4791-9e2a-9573efc81424"
                      defaultMessage="Pay outside of Proof"
                    />
                  ),
                  value: Payer.ORGANIZATION,
                  "data-automation-id": "payer-sender-radio-button",
                },
              ]}
            />
            {payer === Payer.CUSTOMER && (
              <div className={Styles.transactionFeesGroup}>
                <RadioGroup
                  label={
                    <FormattedMessage
                      id="de52f685-2e9a-4986-9a6b-5d2a287260d6"
                      defaultMessage="Set your notarization and additional seal fees for this transaction"
                    />
                  }
                  className={Styles.radioGroup}
                >
                  <RadioLabel
                    label={
                      <FormattedMessage
                        id="de3b9361-13e3-4d07-a815-063ff1430184"
                        defaultMessage="Default fees ({firstSeal} per meeting, {additionalSeal} per add’l seal)"
                        values={{
                          firstSeal: (
                            <FormattedPrice dollars={accountNotaverseFirstSeal.toString()} />
                          ),
                          additionalSeal: (
                            <FormattedPrice dollars={accountNotaverseAdditionalSeal.toString()} />
                          ),
                        }}
                      />
                    }
                    radio={
                      <RadioInput
                        checked={fees === "default"}
                        value="default"
                        onChange={() => setFees("default")}
                        disabled={readonlyField(config, CONFIGS.customFees)}
                      />
                    }
                  />
                  <RadioLabel
                    label={
                      <FormattedMessage
                        id="6eaeda37-a115-4aa0-b87c-aced6199bf6a"
                        defaultMessage="Custom fees (cannot be below $0)"
                      />
                    }
                    radio={
                      <RadioInput
                        checked={fees === "custom"}
                        value="custom"
                        onChange={() => setFees("custom")}
                        disabled={readonlyField(config, CONFIGS.customFees)}
                      />
                    }
                  />
                </RadioGroup>
                <fieldset>
                  <SROnly>
                    <legend>
                      <FormattedMessage
                        id="3b659e6b-c965-49c4-a16a-84b69e990498"
                        defaultMessage="Enter custom values for notarization and additional seal fees"
                      />
                    </legend>
                  </SROnly>

                  {fees === "custom" && (
                    <div className={Styles.customFeeInputGroup}>
                      <div className={Styles.customFeeInput}>
                        <Label id={notarizationFeeLabelId}>
                          {intl.formatMessage(MESSAGES.notarizationFee)}
                        </Label>
                        <ConfiguredField
                          form={form}
                          config={config}
                          configField={CONFIGS.customFees}
                          name={FORM_FIELDS.notarizationFee}
                          aria-labelledby={notarizationFeeLabelId}
                          registerOptions={{
                            valueAsNumber: true,
                            validate: (value) =>
                              (typeof value === "number" && value >= 0) ||
                              intl.formatMessage(MESSAGES.feeInputError),
                          }}
                          as={CurrencyInput}
                        />
                      </div>
                      <div className={Styles.customFeeInput}>
                        <Label id={additionalSealLabelId}>
                          {intl.formatMessage(MESSAGES.additionalSeal)}
                        </Label>
                        <ConfiguredField
                          form={form}
                          config={config}
                          configField={CONFIGS.customFees}
                          name={FORM_FIELDS.additionalSealFee}
                          aria-labelledby={additionalSealLabelId}
                          registerOptions={{
                            valueAsNumber: true,
                            validate: (value) =>
                              (typeof value === "number" && value >= 0) ||
                              intl.formatMessage(MESSAGES.feeInputError),
                          }}
                          as={CurrencyInput}
                        />
                      </div>
                    </div>
                  )}
                </fieldset>
              </div>
            )}
          </>
        )}
      </Card>
    </>
  );
}

export const NST_PAYMENT_SECTION = {
  Component: NstPaymentSection,
  configs: CONFIGS,
  getDefaultFormValues(transaction) {
    const transactionPricing = transaction.businessMembership?.pricingPlan;
    return {
      nstPayer: transaction.payer,
      notarizationFee: transactionPricing
        ? transactionPricing.prices.notaverse_first_seal / 100
        : 0,
      additionalSealFee: transactionPricing
        ? transactionPricing.prices.notaverse_additional_seal / 100
        : 0,
    };
  },
  getSubmitData({ sectionFormValues }) {
    const { nstPayer, notarizationFee, additionalSealFee } = sectionFormValues;
    const orgPays = nstPayer === Payer.ORGANIZATION;
    return {
      payer: nstPayer,
      notaverseFirstSeal: orgPays ? 0 : notarizationFee * 100,
      notaverseAdditionalSeal: orgPays ? 0 : additionalSealFee * 100,
    };
  },
  modifyConfig({ sectionConfig, organization, user }) {
    const { notaryProfile } = user;
    const modifiedConfig = { ...sectionConfig };

    // All NSTs will have this feature
    if (!organization.featureList.includes(Feature.CUSTOM_ACCOUNT_PRICING)) {
      downgradeDisplay(modifiedConfig, "nstPayer", "hidden");
      downgradeDisplay(modifiedConfig, "customFees", "hidden");
    } else if (notaryMustCollectExternalPayment(notaryProfile)) {
      // For notaries that are also on demand
      modifiedConfig.canCollectNotaryPayment = false;
    }

    return modifiedConfig;
  },
} satisfies SectionContract<
  NstPaymentFormValues,
  NstPaymentSubmitData,
  NstPricing,
  NstAccountPricing,
  NstUser,
  typeof CONFIGS
>;
