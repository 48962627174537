import {
  SIGNATURE_COLOR_HEX,
  MIN_SIGNATURE_FONT_SIZE,
  MAX_SIGNATURE_FONT_SIZE,
} from "constants/globals";
import { segmentTrack } from "util/segment";

export class InvalidPixelsError extends Error {
  constructor(message, analyticsData) {
    super(message);
    this.analyticsData = analyticsData || {};
  }
}

export class MissingFontError extends Error {
  constructor(message, analyticsData) {
    super(message);
    this.analyticsData = analyticsData || {};
  }
}

/**
 * Returns the input canvas that has been modified by removing
 * all bounds around the text that has been drawn inside the canvas.
 * @param {HTMLCanvasElement} canvas canvas with text already drawn inside
 * @param [fillTransparency] whether to take any pixel with an alpha value of 0 and set it to 255
 * @returns {HTMLCanvasElement} canvas with white space removed
 */
export function cropCanvas(canvas, fillTransparency = false) {
  const ctx = canvas.getContext("2d");

  // adapted from https://gist.github.com/remy/784508
  // finds the bounds of the signature to crop transparent pixels
  const pixels = ctx.getImageData(0, 0, canvas.width, canvas.height);
  const bounds = { top: null, left: null, right: null, bottom: null };

  // ctx.getImageData returns an Uint8ClampedArray representing a one-dimensional array
  // containing the data in the RGBA order, with integer values between 0 and 255 (included).
  // That means one pixel is represented by 4 bytes [red, green, blue, alpha],
  // the last one being the opacity value, 0 for transparent and 1 for opaque.
  const pixelData = pixels.data;
  const pixelDataLength = pixelData.length;
  for (let i = 0; i < pixelDataLength; i += 4) {
    if (pixelData[i + 3] !== 0) {
      const pixelNumber = i / 4;
      const x = pixelNumber % canvas.width;
      // ~~ is a shortcut for Math.floor, also faster
      const y = ~~(pixelNumber / canvas.width); // eslint-disable-line no-bitwise

      bounds.top = bounds.top === null ? y : bounds.top;
      bounds.left = bounds.left === null || x < bounds.left ? x : bounds.left;
      bounds.right = bounds.right === null || bounds.right < x ? x : bounds.right;
      bounds.bottom = bounds.bottom === null || bounds.bottom < y ? y : bounds.bottom;
    } else if (fillTransparency) {
      pixelData[i] = pixelData[i + 1] = pixelData[i + 2] = pixelData[i + 3] = 255;
    }
  }
  if (fillTransparency) {
    for (let i = 3; i < pixelDataLength; i += 4) {
      if (pixelData[i] === 0) {
        throw new InvalidPixelsError("Unexpected transparent pixel", {
          pixelNumber: Math.floor(i / 4),
          boundsTop: bounds.top,
          boundsLeft: bounds.left,
          boundsRight: bounds.right,
          boundsBottom: bounds.bottom,
        });
      }
    }
  }

  ctx.putImageData(pixels, 0, 0);

  const textCanvas = document.createElement("canvas");
  // Need to add 1 because x = 0 / y = 0 are considered valid row / column of pixels
  textCanvas.width = bounds.right - bounds.left + 1;
  textCanvas.height = bounds.bottom - bounds.top + 1;

  const textCtx = textCanvas.getContext("2d");
  const croppedSignatureData = ctx.getImageData(
    bounds.left,
    bounds.top,
    textCanvas.width,
    textCanvas.height,
  );
  textCtx.putImageData(croppedSignatureData, 0, 0);
  return textCanvas;
}

/**
 * Downloads an image as PNG format
 * @param {HTMLImageElement} image image element
 * @param {String} imageName name of image file
 * @param {number} width set width size of image to download
 * @param {number} height set height size of image to download
 * @returns {void}
 */
export function downloadImageAsPNG(image, imageName, width, height) {
  const canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext("2d");
  ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

  const imgURI = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
  const anchor = document.createElement("a");
  anchor.href = imgURI;
  anchor.target = "_blank";
  anchor.download = `${imageName}.png`;

  anchor.click();
  ctx.clearRect(0, 0, canvas.width, canvas.height);
}

/**
 * Downloads an SVG image
 * @param {String} svgDataUrl svg data url source (format: data:image/svg+xml; ...)
 * @param {String} imageName name of image file
 * @returns {void}
 */
export function downloadSvgImage(svgDataUrl, imageName) {
  const anchor = document.createElement("a");
  anchor.href = svgDataUrl;
  anchor.target = "_blank";
  anchor.download = `${imageName}.svg`;
  anchor.click();
}

/**
 * Using an input text and font, returns a promise that will resolve to a blob of a PNG image that is cropped to its content
 * @param {String} text text to be drawn in the SVG
 * @param {String} font font that the text will be drawn in
 * @returns {Promise<{
 *   blob: Blob,
 *   originalCanvas: HTMLCanvasElement,
 *   maxFontSize: number,
 * }>} resolves to an object containing the SVG, and original canvases used to generate the SVG
 */
export async function generatePNGBlobFromText(text, font) {
  await detectFontAvailable(font);
  const ratio = Math.max(window.devicePixelRatio, 1);

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const [height, width] = [Math.ceil(175 * ratio), Math.ceil(350 * ratio)];

  canvas.width = width;
  canvas.height = height;

  const maxFontSize = findMaxFontSize(text, font, canvas, 0, MAX_SIGNATURE_FONT_SIZE * ratio);

  ctx.textAlign = "center";
  ctx.textBaseline = "middle";
  ctx.font = `normal ${maxFontSize}px ${font}`;
  ctx.fillStyle = SIGNATURE_COLOR_HEX;
  ctx.strokeStyle = SIGNATURE_COLOR_HEX;
  ctx.lineWidth = ratio;

  ctx.strokeText(text, width / 2, height / 2);
  ctx.fillText(text, width / 2, height / 2);

  const topLeft = {
    x: canvas.width,
    y: canvas.height,
    update(x, y) {
      this.x = Math.min(this.x, x);
      this.y = Math.min(this.y, y);
    },
  };

  const bottomRight = {
    x: 0,
    y: 0,
    update(x, y) {
      this.x = Math.max(this.x, x);
      this.y = Math.max(this.y, y);
    },
  };

  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

  // We do some manipulation of the canvas to crop to content by grabbing the top left and
  // bottom right corner of the bounds that have non-zero alpha values
  // See: https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Pixel_manipulation_with_canvas
  for (let x = 0; x < canvas.width; x++) {
    for (let y = 0; y < canvas.height; y++) {
      const hasTextPixel = coordHasVisiblePixel(x, y, imageData.data, canvas.width);
      if (hasTextPixel) {
        topLeft.update(x, y);
        bottomRight.update(x, y);
      }
    }
  }

  const newWidth = bottomRight.x - topLeft.x;
  const newHeight = bottomRight.y - topLeft.y;

  const croppedCanvas = ctx.getImageData(topLeft.x, topLeft.y, width, height);
  canvas.width = newWidth;
  canvas.height = newHeight;
  ctx.putImageData(croppedCanvas, 0, 0);

  const resource = await fetch(canvas.toDataURL("image/png"));
  const blob = await resource.blob();

  return {
    blob,
    maxFontSize,
    originalCanvas: canvas,
  };
}

/**
 * Align text to the left and in the vertical center on a given canvas
 * @param {string} text
 * @param {canvas} canvas
 * @param {CanvasRenderingContext2D} context
 * @param [strokeText] boolean
 */
export function centerAndAlignText(text, canvas, context, strokeText) {
  // Grab height and width of input canvas
  const { height, width } = canvas;

  // Measure text values from the canvas while the text is centered
  const {
    firstPixelY: firstPixelYCenter,
    textHeight: textHeightCenter,
    calculatedWidth: calculatedWidthCenter,
  } = measureFont(text, canvas, context, true);

  // Left align the text
  context.textAlign = "left";
  context.clearRect(0, 0, width, height);
  context.fillText(text, 0, height / 2);

  // Measure the new width after left-aligning and calculate the difference.
  // This is to make sure text as not cut off on the left side when aligning the
  // text to the left. We use this value to shift in the +x direction.
  const { firstPixelX, calculatedWidth: calculatedWidthLeft } = measureFont(text, canvas, context);
  const differenceWidth = calculatedWidthCenter - calculatedWidthLeft;

  // We get the amount we need to shift the text right by checking pixel data.
  // If the first pixel in the X axis is larger than 0, we move that value
  // further to the left so it is positioned at exactly 0. Otherwise, we move
  // the text to the right by how much text was cut off.
  let x;
  if (firstPixelX > 0) {
    x = -firstPixelX;
  } else {
    x = differenceWidth;
  }

  // Here we calculate the y offset we need to make the center of the measured
  // text be drawn at the center of the canvas since the baseline doesn't perfectly
  // consider realistic fonts with odd text spacing.
  // amountAboveBaseline is the amount of pixels above the horizontal center of
  // the canvas.
  // differenceHeight is the amount of text below the baseline.
  // The y value is calculated by taking half of the canvas height, subtracting
  // the amount below the baseline and adding half of the text's height.
  const amountAboveBaseline = -(firstPixelYCenter - height / 2);
  const differenceHeight = textHeightCenter - amountAboveBaseline;
  const y = height / 2 - differenceHeight + textHeightCenter / 2;

  context.clearRect(0, 0, width, height);
  context.fillText(text, x, y);
  if (strokeText) {
    context.strokeText(text, x, y);
  }
}

/**
 * Find maximum font size that fits the given text within a canvas taking into account
 * padding
 * @param {string} text
 * @param {string} fontFamily - Font family of text to be drawn
 * @param {canvas} canvas
 * @param {padding} padding - Amount to decrease test height by
 * @param [maxFontSizeOverride] - Override for maximum font size
 * @returns {number} Maximum font size
 */
function findMaxFontSize(text, fontFamily, canvas, padding, maxFontSizeOverride) {
  let { height, width } = canvas;
  height -= padding;
  width -= padding;

  const tempCanvas = document.createElement("canvas");
  const testHeight = height * 2;
  const testWidth = width * 2;
  tempCanvas.height = testHeight;
  tempCanvas.width = testWidth;
  const tempContext = tempCanvas.getContext("2d", { willReadFrequently: true });
  tempContext.textAlign = "center";
  tempContext.textBaseline = "middle";

  return measureTextBinaryMethod(
    text,
    fontFamily,
    tempCanvas,
    tempContext,
    MIN_SIGNATURE_FONT_SIZE,
    maxFontSizeOverride || MAX_SIGNATURE_FONT_SIZE,
    height,
    width,
  );
}

/**
 * Find maximum font size in rems that fits the given text within a canvas taking into account
 * padding
 * @param {string} text
 * @param {string} fontFamily - Font family of text to be drawn
 * @param {canvas} canvas
 * @param {padding} padding - Amount to decrease test height by
 * @param [maxFontSizeOverride] - Override for maximum font size
 * @returns {number} Maximum font size in rem
 */
export function findMaxRemSize(text, fontFamily, canvas, padding = 0, maxFontSizeOverride) {
  const { height: canvasHeight, width: canvasWidth } = canvas;
  // 1 rem is 16px
  const SIZE = 16;
  const adjustedCanvasHeight = canvasHeight - padding;
  const adjustedCanvasWidth = canvasWidth - padding;
  // create div to add text to
  const el = document.createElement("div");
  el.style.display = "inline-block";
  el.style.whiteSpace = "nowrap";
  el.style.lineHeight = "1.3";
  el.style.padding = "3px";
  el.style.fontFamily = fontFamily;
  el.style.opacity = "0";
  el.innerHTML = text;
  document.body.appendChild(el);
  // get width/height of div which contains the text
  const { height, width } = el.getBoundingClientRect();
  document.body.removeChild(el);
  const heightBasedRem = adjustedCanvasHeight / height;
  const widthBasedRem = adjustedCanvasWidth / width;
  // return appropriate rem size based on text element's orientation
  const computedRem = widthBasedRem < heightBasedRem ? widthBasedRem : heightBasedRem;
  // probably should also make sure font size isnt smaller than MIN_SIGNATURE_FONT_SIZE
  const computedSize = computedRem * SIZE;

  const rem =
    maxFontSizeOverride && maxFontSizeOverride < computedSize // is computed px size greater than max size override?
      ? maxFontSizeOverride / SIZE
      : computedSize < MIN_SIGNATURE_FONT_SIZE // is computed px size less than minimum size?
        ? MIN_SIGNATURE_FONT_SIZE / SIZE
        : computedRem;

  return rem;
}

async function detectFontAvailable(fontName) {
  await document.fonts.ready;
  if (!document.fonts.check(`16px ${fontName}`)) {
    const { userAgent } = window.navigator;
    segmentTrack("[REAL] Unable to find font", {
      fontName,
      userAgent,
    });
    throw new MissingFontError("Unable to find font");
  }
}

/**
 * Binary search for the maximum font size to fit in a canvas
 * @param {string} text
 * @param {string} fontFamily - Font family of text to be drawn
 * @param {canvas} tempCanvas - Canvas to be used for measuring text
 * @param {CanvasRenderingContext2D} tempContext
 * @param {number} minFontSize
 * @param {number} maxFontSize
 * @param {number} maxHeight - Maximum height the font height cannot exceed
 * @param {number} maxWidth - Maximum width the font width cannot exceed
 */
function measureTextBinaryMethod(
  text,
  fontFamily,
  tempCanvas,
  tempContext,
  minFontSize,
  maxFontSize,
  maxHeight,
  maxWidth,
) {
  if (maxFontSize - minFontSize < 1) {
    return minFontSize;
  }

  const { height, width } = tempCanvas;

  const testSize = minFontSize + (maxFontSize - minFontSize) / 2;

  tempContext.clearRect(0, 0, width, height);
  tempContext.font = `normal ${testSize}px ${fontFamily}`;
  tempContext.fillText(text, width / 2, height / 2);
  tempContext.fillStyle = "#ffffff";
  tempContext.strokeStyle = "#ffffff";
  const measurementDataNew = measureFont(text, tempCanvas, tempContext);

  const { calculatedWidth, textHeight, firstPixelX, firstPixelY, lastPixelX, lastPixelY } =
    measurementDataNew;

  let found;

  // Determine if the new font size fits within the original canvas size.
  // The calculated width and height cannot be larger than the maximums defined.
  // Since the test canvas is twice the size of our desired canvas size,
  // we also make sure that the first and last pixels in x and y are within the
  // ranges of where the desired canvas would be centered inside the test canvas.
  if (
    calculatedWidth > maxWidth ||
    textHeight > maxHeight ||
    firstPixelX <= width / 4 ||
    firstPixelY <= height / 4 ||
    lastPixelX >= (3 * width) / 4 ||
    lastPixelY >= (3 * height) / 4
  ) {
    found = measureTextBinaryMethod(
      text,
      fontFamily,
      tempCanvas,
      tempContext,
      minFontSize,
      testSize,
      maxHeight,
      maxWidth,
    );
  } else {
    found = measureTextBinaryMethod(
      text,
      fontFamily,
      tempCanvas,
      tempContext,
      testSize,
      maxFontSize,
      maxHeight,
      maxWidth,
    );
  }

  return found;
}

// Returns the RGB sum of a specific pixel at coordinates (x, y) on a canvas
// with a width of canvasWidth.
function getRGBSumOfPixelCoord(x, y, imageData, canvasWidth) {
  return (
    imageData[(canvasWidth * y + x) * 4] +
    imageData[(canvasWidth * y + x) * 4 + 1] +
    imageData[(canvasWidth * y + x) * 4 + 2]
  );
}

// Returns whether or not a specific pixel at coordinates (x, y) on a canvas
// has a 'visible' pixel. For our purposes, a pixel is 'visible' if it has a
// non-zero RGB sum, to indicate that this pixel is a part of the rendred text/signature.
// It assumes the background color is transparent with a value of r:0, g:0, b:0, a:0.
function coordHasVisiblePixel(x, y, imageData, canvasWidth) {
  // Browsers like Brave and Samsung Internet poison the data from context.getImageData
  // to protect against device canvas fingerprinting by adding +/- 1 to the real value, randomly.

  // This poisoning is impercetible to a human looking at a rendered canvas,
  // but it means we can't just check for a value > 0 to know if a pixel is part of the text,
  // and need to add in a tolerance of 3 to account for worst case of 3 0's being replaced with 1's.
  return getRGBSumOfPixelCoord(x, y, imageData, canvasWidth) > 3;
}

/**
 * Measure text manually by traversing the canvas for RGB values of each pixel
 * @param {string} text - Text to measure in the canvas
 * @param {canvas} canvas
 * @param {CanvasRenderingContext2D} context
 * @param {testAdjacentPixels} boolean - should this also test the two adjacent pixels to account for poisoned pixel values
 * @returns {object} Object containing calculated heights and widths and pixel data
 */
function measureFont(text, canvas, context, testAdjacentPixels = false) {
  const sourceWidth = canvas.width;
  const sourceHeight = canvas.height;

  // returns an array containing the sum of all pixels in a canvas
  // * 4 (red, green, blue, alpha)
  // [pixel1Red, pixel1Green, pixel1Blue, pixel1Alpha, pixel2Red ...]
  const data = context.getImageData(0, 0, sourceWidth, sourceHeight).data;

  let firstY = -1;
  let lastY = -1;
  let firstX = -1;
  let lastX = -1;

  for (let x = 0; x <= sourceWidth - 1; x++) {
    for (let y = 0; y <= sourceHeight - 1; y++) {
      const hasAdjacentX = x + 1 <= sourceWidth; // if we are going to test the adjacent pixels we need to make sure the adjacent value is within the canvas
      const hasAdjacentY = y + 1 <= sourceHeight; // this will stop us from checking poisoned values on the edge of the canvas

      const hasTextPixel = testAdjacentPixels
        ? coordHasVisiblePixel(x, y, data, sourceWidth) &&
          ((hasAdjacentX && coordHasVisiblePixel(x + 1, y, data, sourceWidth)) ||
            (hasAdjacentY && coordHasVisiblePixel(x, y + 1, data, sourceWidth)))
        : coordHasVisiblePixel(x, y, data, sourceWidth);

      if (hasTextPixel) {
        firstX = x;
        break;
      }
    }
    if (firstX >= 0) {
      break;
    }
  }

  for (let x = sourceWidth - 1; x >= 0; x--) {
    for (let y = 0; y <= sourceHeight - 1; y++) {
      const hasAdjacentX = x - 1 >= 0;
      const hasAdjacentY = y - 1 >= sourceHeight;

      const hasTextPixel = testAdjacentPixels
        ? coordHasVisiblePixel(x, y, data, sourceWidth) &&
          ((hasAdjacentX && coordHasVisiblePixel(x - 1, y, data, sourceWidth)) ||
            (hasAdjacentY && coordHasVisiblePixel(x, y - 1, data, sourceWidth)))
        : coordHasVisiblePixel(x, y, data, sourceWidth);
      if (hasTextPixel) {
        lastX = x;
        break;
      }
    }
    if (lastX >= 0) {
      break;
    }
  }

  for (let y = 0; y <= sourceHeight - 1; y++) {
    for (let x = 0; x <= sourceWidth - 1; x++) {
      const hasAdjacentX = x + 1 <= sourceWidth;
      const hasAdjacentY = y + 1 <= sourceHeight;

      const hasTextPixel = testAdjacentPixels
        ? coordHasVisiblePixel(x, y, data, sourceWidth) &&
          ((hasAdjacentY && coordHasVisiblePixel(x, y + 1, data, sourceWidth)) ||
            (hasAdjacentX && coordHasVisiblePixel(x + 1, y, data, sourceWidth)))
        : coordHasVisiblePixel(x, y, data, sourceWidth);

      if (hasTextPixel) {
        firstY = y;
        break;
      }
    }
    if (firstY >= 0) {
      break;
    }
  }

  for (let y = sourceHeight - 1; y >= 0; y--) {
    for (let x = 0; x <= sourceWidth - 1; x++) {
      const hasAdjacentX = x - 1 >= 0;
      const hasAdjacentY = y - 1 >= 0;

      const hasTextPixel = testAdjacentPixels
        ? coordHasVisiblePixel(x, y, data, sourceWidth) &&
          ((hasAdjacentY && coordHasVisiblePixel(x, y - 1, data, sourceWidth)) ||
            (hasAdjacentX && coordHasVisiblePixel(x - 1, y, data, sourceWidth)))
        : coordHasVisiblePixel(x, y, data, sourceWidth);
      if (hasTextPixel) {
        lastY = y;
        break;
      }
    }
    if (lastY >= 0) {
      break;
    }
  }

  return {
    // The actual height
    textHeight: lastY - firstY,

    textWidth: context.measureText(text).width,

    firstPixelX: firstX,

    // The first pixel (Y)
    firstPixelY: firstY,

    // The last pixel (Y)
    lastPixelY: lastY,

    lastPixelX: lastX,

    // The actual width
    calculatedWidth: lastX - firstX,
  };
}

export function canvasToBlob(canvas, mimeType) {
  return new Promise((resolve) => {
    canvas.toBlob(
      (blob) => {
        resolve(blob);
      },
      mimeType,
      1,
    );
  });
}
