import { useState, type ComponentProps } from "react";
import { FormattedMessage } from "react-intl";

import AlertMessage from "common/core/alert_message";
import Button from "common/core/button";
import Link from "common/core/link";
import { useTagUserMutation } from "common/user/tag";
import WorkflowModal from "common/modals/workflow_modal";
import {
  TRANSACTION_CREATION_V3_OPT_OUT_TAG,
  useRawTransactionCreationV3,
} from "common/transaction_creation/v3/detection";
import { useTrack } from "common/transaction_creation/v3/common";
import { CURRENT_PORTAL } from "constants/app_subdomains";

import Styles from "./index.module.scss";

function getBannerMessage(optedOut: boolean) {
  if (optedOut) {
    if (CURRENT_PORTAL === "business") {
      return (
        <FormattedMessage
          id="1605ed16-a3c7-49c1-be87-9f7d9b03c665"
          defaultMessage="On June 3rd, 2024, we will no longer support this version of the form. Try out the new form now!"
        />
      );
    }

    return (
      <FormattedMessage
        id="1605ed16-a3c7-49c1-be87-9f7d9b03c665"
        defaultMessage="We've updated our transaction creation form! Test it out before the updates become permanent."
      />
    );
  }
  return (
    <>
      <FormattedMessage
        id="ee1be2f2-aa73-4ed7-a258-c1f02b1c67b5"
        defaultMessage="Welcome to our new transaction creation form! We've made updates to improve your experience."
      />{" "}
      <Link href="https://www.proof.com/blog/new-transaction-form" black>
        <FormattedMessage id="9f02fa2b-31c4-4774-a0b1-2214ebcf7ad1" defaultMessage="Learn more" />
      </Link>
    </>
  );
}

function BannerContent(props: {
  transactionId: string;
  userId: string;
  optedOut: boolean;
  save: (() => Promise<"saved" | "failed">) | false;
}) {
  const track = useTrack();
  const tagUserMutateFn = useTagUserMutation();
  const [tagging, setTagging] = useState(false);
  const [saveState, setSaveState] = useState<null | {
    saving: boolean;
    onSave: () => void;
    onSkip: () => void;
  }>(null);

  async function opt(value: "in" | "out") {
    const { save } = props;
    if (save) {
      const promptResult = await new Promise<"continue" | "cancel">((resolve) => {
        setSaveState({
          saving: false,
          onSave: async () => {
            setSaveState((saveState) => ({
              ...saveState!,
              saving: true,
            }));
            const saveResult = await save();
            setSaveState(null);
            resolve(saveResult === "saved" ? "continue" : "cancel");
          },
          onSkip: () => {
            setSaveState(null);
            resolve("continue");
          },
        });
      });

      if (promptResult === "cancel") {
        return;
      }
    }

    track(`opted ${value}`);

    // no need to manually navigate, the transaction pages will navigate on their own when tags update
    setTagging(true);
    await tagUserMutateFn({
      variables: {
        input: {
          userId: props.userId,
          tag: TRANSACTION_CREATION_V3_OPT_OUT_TAG,
          untag: value === "in",
        },
        userTagList: [TRANSACTION_CREATION_V3_OPT_OUT_TAG],
      },
    });
  }

  return (
    <div className={Styles.content}>
      <div>{getBannerMessage(props.optedOut)}</div>

      <Button
        variant={props.optedOut ? "secondary" : "tertiary"}
        buttonColor="action"
        className={props.optedOut ? Styles.buttonSecondary : Styles.buttonTertiary}
        onClick={() => opt(props.optedOut ? "in" : "out")}
        isLoading={tagging}
        withIcon={{ name: "refresh", placement: "left" }}
        buttonSize="condensed"
      >
        {props.optedOut ? (
          <FormattedMessage
            id="af23a375-4153-40ce-a30b-3ed259d071db"
            defaultMessage="Switch to new form"
          />
        ) : (
          <FormattedMessage
            id="09b6c188-b5d1-4688-928d-80298956d321"
            defaultMessage="Switch to old form"
          />
        )}
      </Button>

      {saveState && (
        <WorkflowModal
          title={
            <FormattedMessage
              id="a7fe92aa-2a34-4b1f-9217-249093debf9f"
              defaultMessage="You have unsaved changes"
            />
          }
          buttons={[
            <Button
              key="continue"
              buttonColor="action"
              variant="secondary"
              onClick={saveState.onSkip}
              disabled={saveState.saving}
            >
              <FormattedMessage
                id="66085cbb-f69a-454d-99d6-0f054e0ab830"
                defaultMessage="Continue without saving"
              />
            </Button>,
            <Button
              key="save"
              buttonColor="action"
              variant="primary"
              onClick={saveState.onSave}
              isLoading={saveState.saving}
            >
              <FormattedMessage id="6b8131bd-8fea-458a-9d78-9c254a614e5d" defaultMessage="Save" />
            </Button>,
          ]}
          autoFocus
          isSensitive={false}
        >
          <FormattedMessage
            id="b52667db-4e73-4e00-8635-17547dd79324"
            defaultMessage="Required fields must be filled in before saving. You can continue without saving, but you will lose your progress."
          />
        </WorkflowModal>
      )}
    </div>
  );
}

export function TransactionCreationV3Banner(props: ComponentProps<typeof BannerContent>) {
  const v3 = useRawTransactionCreationV3();

  if (v3 !== "opt-in") {
    return null;
  }

  return (
    <AlertMessage
      banner
      kind={props.optedOut ? "info" : "success"}
      customEmoji={props.optedOut ? "" : "🎉"}
      centerText
    >
      <BannerContent {...props} />
    </AlertMessage>
  );
}
