import { PageTypes, NotarialActs } from "graphql_globals";

export function notarialActLabel(notarialAct: NotarialActs): string {
  switch (notarialAct) {
    case NotarialActs.ATTESTATION:
      return "Attestation";
    case NotarialActs.ACKNOWLEDGEMENT:
      return "Acknowledgement";
    case NotarialActs.AFFIDAVIT:
      return "Affidavit";
    case NotarialActs.COPY_CERTIFICATION:
      return "Copy Certification";
    case NotarialActs.VERIFICATION_OF_FACT:
    case NotarialActs.VERIFICATION_OF_FACT_PS1583:
      return "Verification of Fact";
    case NotarialActs.OATH_AFFIRMATION:
      return "Oath / Affirmation";
    case NotarialActs.JURAT:
      return "Jurat";
    default:
      throw new Error(`Unknown act ${notarialAct}`);
  }
}

export function looseLeafPageLabel(llp: PageTypes): string | undefined {
  switch (llp) {
    case PageTypes.CERTIFICATE_OF_ACKNOWLEDGEMENT:
      return "Certificate of Acknowledgement";
    case PageTypes.AFFIDAVIT:
      return "Affidavit";
    case PageTypes.JURAT:
      return "Jurat";
    case PageTypes.COPY_CERTIFICATION:
      return "Copy Certification";
    case PageTypes.VERIFICATION_OF_FACT_PS1583:
      return "Verification of Fact";
    case PageTypes.ATTESTATION:
      return "Attestation";
    default:
      return undefined;
  }
}
