import { memo } from "react";
import { FormattedMessage } from "react-intl";

import { DeprecatedDetailGridRow } from "common/details/grid/row";

type Props = {
  meeting: { platform: string | null };
};

function MeetingPlatform({ meeting: { platform } }: Props) {
  return (
    <DeprecatedDetailGridRow
      title={
        <FormattedMessage id="ce89c673-a9dd-421f-b59a-5a9286bb5bbf" defaultMessage="Platform" />
      }
    >
      {platform}
    </DeprecatedDetailGridRow>
  );
}

export default memo(MeetingPlatform);
