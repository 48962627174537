import {
  TRANSACTION_CREATION_V3_BIZ,
  TRANSACTION_CREATION_V3_TITLE,
  TRANSACTION_CREATION_V3_LENDER,
} from "constants/feature_gates";
import { CURRENT_PORTAL } from "constants/app_subdomains";
import { useFeatureFlag } from "common/feature_gating";

type TagsUser = { tags: null | ({ tag: string } | null)[] };

export const TRANSACTION_CREATION_V3_OPT_OUT_TAG = "transaction-creation-v3-opt-out";
const RAW_FEATURE_FLAG =
  CURRENT_PORTAL === "lender"
    ? TRANSACTION_CREATION_V3_LENDER
    : CURRENT_PORTAL === "title_agency"
      ? TRANSACTION_CREATION_V3_TITLE
      : TRANSACTION_CREATION_V3_BIZ;

export function useRawTransactionCreationV3() {
  return useFeatureFlag<"off" | "on" | "opt-in">(RAW_FEATURE_FLAG, "off");
}

export function optedOutOfTransactionCreationV3(user: TagsUser): boolean {
  return user.tags!.some((tag) => tag?.tag === TRANSACTION_CREATION_V3_OPT_OUT_TAG);
}

export function useTransactionCreationV3(user: TagsUser): boolean {
  const raw = useRawTransactionCreationV3();
  if (raw === "off") {
    return false;
  }
  return raw === "on" || !optedOutOfTransactionCreationV3(user);
}
