import { type ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { Heading, Paragraph } from "common/core/typography";

import Styles from "./index.module.scss";
import { SectionContainer } from "../common";

function Stat({ heading, description }: { heading: ReactNode; description: ReactNode }) {
  return (
    <div className={Styles.stat}>
      <Heading level="h2" textStyle="headingOne" textAlign="center">
        {heading}
      </Heading>
      <Paragraph size="large" textAlign="center">
        {description}
      </Paragraph>
    </div>
  );
}

export const STATS = Object.freeze({
  states: (
    <Stat
      key="states"
      heading={
        <FormattedMessage id="9aa33eca-e81e-4629-a2a9-a3a4b34bbd0c" defaultMessage="50 states" />
      }
      description={
        <FormattedMessage
          id="69f90553-bc27-480e-b195-206b7c45e49d"
          defaultMessage="Documents accepted nationwide"
        />
      }
    />
  ),
  onlineNotarizations: (
    <Stat
      key="notarizations"
      heading={
        <FormattedMessage id="77399bbc-9e64-4a60-aca9-64047bf3a600" defaultMessage="+2 million" />
      }
      description={
        <FormattedMessage
          id="8f8a873b-63a1-4161-8641-410f37ac7ff9"
          defaultMessage="Online notarizations completed"
        />
      }
    />
  ),
  trustPilot: (
    <Stat
      key="rating"
      heading={
        <FormattedMessage id="7c5eef7a-c0ee-487b-a5e7-a73fea9b0e7d" defaultMessage="4.7/5" />
      }
      description={
        <FormattedMessage
          id="69f90553-bc27-480e-b195-206b7c45e49d"
          defaultMessage="Based on 20,000+ Trustpilot reviews"
        />
      }
    />
  ),
  meetingCompletionBusinessRon: (
    <Stat
      key="completionBiz"
      heading={
        <FormattedMessage id="9c06fb1a-85d3-421f-a583-7b2165695910" defaultMessage="<5 min" />
      }
      description={
        <FormattedMessage
          id="db5675af-01b3-4faf-882b-7d0798259f2d"
          defaultMessage="Meeting completion average"
        />
      }
    />
  ),
  meetingCompletionMortgageRon: (
    <Stat
      key="completionMortgage"
      heading={
        <FormattedMessage id="b739052e-abd7-41fa-8f35-665ba0619d03" defaultMessage="<15 min" />
      }
      description={
        <FormattedMessage
          id="a919d999-b00a-4d75-8891-4f3e9186327c"
          defaultMessage="Meeting completion average"
        />
      }
    />
  ),
  closings: (
    <Stat
      key="closings"
      heading={
        <FormattedMessage id="0142bc42-f540-4a56-8763-54ccbdbaf5a4" defaultMessage="$347B" />
      }
      description={
        <FormattedMessage
          id="d49dd41e-91d3-4f7a-b9d8-3eababa487d4"
          defaultMessage="Worth of real estate closings secured by Proof"
        />
      }
    />
  ),
});

export function Stats(props: { stats: (typeof STATS)[keyof typeof STATS][] }) {
  return (
    <div className={Styles.statsWrapper}>
      <SectionContainer>
        <div className={Styles.stats}>{props.stats}</div>
      </SectionContainer>
    </div>
  );
}
