import { useEffect, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { reduxForm, type InjectedFormProps, SubmissionError } from "redux-form";

import {
  NotaryDocumentTypes,
  ValidationRequirements,
  NotaryProfileInvalidFields as InvalidFields,
} from "graphql_globals";
import { composeValidators } from "util/form";
import { normalizeToNumber } from "util/normalize";
import { splitDate } from "util/date";
import { validatePresence, validateFutureDay } from "validators/form";
import FormGroup from "common/form/group";
import FormGroupErrors from "common/form/group_errors";
import MultipartColumn from "common/form/inputs/multipart/column";
import MultipartRow from "common/form/inputs/multipart/row";
import TextField from "common/form/fields/text";
import MonthField from "common/form/fields/month";
import TipWell from "common/core/tip_well";
import SubForm from "common/form/sub_form";
import SubFormSection from "common/form/sub_form/section";
import FormRow from "common/form/elements/row";
import { isNotaryODN } from "common/notary/capacity";
import { NotaryProfileWizardAssetUploader } from "common/notary/profile_wizard/upload";
import { customMessage } from "errors/form";
import type { FormError } from "errors/util";

import type {
  NotaryProfileWizardInsuranceDetails as User,
  NotaryProfileWizardInsuranceDetails_notaryProfile as NotaryProfile,
} from "./index_fragment.graphql";
import {
  findDocumentLabel,
  initializeNotaryDocumentField,
  useChangeFileCallback,
  type FileInfo,
  type SubmitType,
} from "../section_utils";
import Styles from "./insurance_details.module.scss";

export const INSURANCE_PATH = "insurance";

const FIELDS = [
  InvalidFields.INVALID_INSURER,
  InvalidFields.INVALID_POLICY_NUMBER,
  InvalidFields.INVALID_POLICY_DOCUMENT,
  InvalidFields.INVALID_POLICY_EXPIRY,
];

type FormValues = {
  insurer: string | undefined;
  policyExpiryDay: string | undefined;
  policyExpiryMonth: string | undefined;
  policyExpiryYear: string | undefined;
  policyKey: FileInfo | undefined;
  policyAmount: number | string;
};
type Input = {
  insurer: string;
  policyExpiry: string;
  notaryDocuments:
    | { keys: string[]; name: string | undefined; documentType: NotaryDocumentTypes }[]
    | null;
  policyAmountInCents: number;
};
type Props = {
  user: User;
  onNext: (input: Input) => void;
  renderFooter: (handleSubmit: () => SubmitType) => ReactNode;
};
type InnerProps = InjectedFormProps<FormValues, Props> & Props;

const EO_POLICY_LABEL = (
  <FormattedMessage
    id="a4536a93-223e-48b5-b720-3f98ee5de25e"
    defaultMessage="E&O Policy Document"
  />
);

type InsuranceDetailsType =
  | { id: "InsuranceDetails"; completed: boolean; route: typeof INSURANCE_PATH }
  | false;

function fieldsRequired(notaryProfile: NotaryProfile) {
  return notaryProfile.validation.invalidFields.some(
    (field) => field !== null && FIELDS.includes(field),
  );
}

export function insuranceDetailsSection(
  lookup: Set<ValidationRequirements>,
  notaryProfile: NotaryProfile,
): InsuranceDetailsType {
  const completed = !FIELDS.some((field) => notaryProfile.validation.invalidFields.includes(field));
  return (
    lookup.has(ValidationRequirements.POLICY_DETAILS) && {
      id: "InsuranceDetails",
      completed,
      route: INSURANCE_PATH,
    }
  );
}
function formatCents(value: string, precision: number = 2): string {
  return (Number(value) / 100).toFixed(precision);
}

function InsuranceDetails({
  user,
  initialize,
  change,
  handleSubmit,
  onNext,
  renderFooter,
}: InnerProps) {
  const notaryProfile = user.notaryProfile!;
  const serializeForm = (fv: FormValues) => {
    const policyAmount = fv.policyAmount;
    if ((policyAmount || policyAmount === 0) && Number(policyAmount) < 25_000) {
      throw new SubmissionError<FormValues, FormError>({
        policyAmount: customMessage({
          message: "This insurance amount does not meet the requirements stated above.",
        }),
      });
    }
    return onNext({
      insurer: fv.insurer ?? "",
      policyExpiry:
        fv.policyExpiryYear && fv.policyExpiryMonth && fv.policyExpiryDay
          ? `${fv.policyExpiryYear}-${fv.policyExpiryMonth}-${fv.policyExpiryDay}`
          : "",
      notaryDocuments:
        typeof fv.policyKey?.key === "string"
          ? [
              {
                keys: [fv.policyKey.key],
                name: fv.policyKey.file?.name,
                documentType: NotaryDocumentTypes.POLICY,
              },
            ]
          : null,
      policyAmountInCents: Number(policyAmount) * 100,
    });
  };
  const handlePolicyKeyChange = useChangeFileCallback("policyKey", change);

  useEffect(() => {
    const { notaryDocuments, insurer, policyExpiry, policyAmountInCents } = notaryProfile;
    const [policyExpiryYear, policyExpiryMonth, policyExpiryDay] = splitDate(policyExpiry);
    const policyAmount = policyAmountInCents ? formatCents(policyAmountInCents.toString(), 0) : "";

    initialize({
      insurer: insurer || "",
      policyExpiryDay,
      policyExpiryMonth,
      policyExpiryYear,
      policyKey: initializeNotaryDocumentField(notaryDocuments, NotaryDocumentTypes.POLICY),
      policyAmount,
    });
  }, []);

  return (
    <>
      <div>
        <FormattedMessage
          id="00608f21-74b6-4194-80ac-1c38a2bb1e0c"
          defaultMessage="Insurance Details{isRequired, select, true{} other{ (Optional)}}"
          tagName="h3"
          values={{ isRequired: isNotaryODN(notaryProfile) }}
        />

        {isNotaryODN(notaryProfile) ? (
          <FormattedMessage
            id="c6a02614-d1a8-45d2-8b55-8148b89581fa"
            defaultMessage="Enter your Errors & Omissions (E&O) policy information and upload a copy. You must be insured for at least $25,000."
            tagName="p"
          />
        ) : (
          <FormattedMessage
            id="f8e13308-2ccc-4409-ba1b-5d91f61b87ea"
            defaultMessage="Errors & Omissions (E&O) insurance should cover at least $25,000. Upload a copy of your policy. This is optional, but recommended."
            tagName="p"
          />
        )}

        <SubForm>
          <SubFormSection
            tipWell={
              <TipWell
                heading={
                  <FormattedMessage
                    id="0c08ba8a-2cd9-49f4-aa53-b674eefaa4b6"
                    defaultMessage="Note"
                  />
                }
              >
                <FormattedMessage
                  id="00608f21-74b6-4194-80ac-1c38a2bb1e0c"
                  defaultMessage="If you are applying as an independent notary,{isRequired, select, true{ you must have a personal E&O policy that is not} other{ your E&O policy should not be}} paid for by an employer"
                  tagName="p"
                  values={{ isRequired: isNotaryODN(notaryProfile) }}
                />
                <FormattedMessage
                  id="d5af8b8f-fa20-480b-9ad8-dab23f08dd0c"
                  defaultMessage="If you are applying under a corporate account to serve your employer's transactions, you may supply an E&O policy provided by your employer."
                  tagName="p"
                />
              </TipWell>
            }
          >
            <FormRow>
              <FormattedMessage
                id="cd380c13-4817-44e7-be43-a19939a8c44a"
                defaultMessage="E&O insurance provider"
                tagName="label"
              />
              <TextField id="insurer" automationId="insurer" name="insurer" useStyledInput />
              <FormGroupErrors fields={["insurer"]} />
            </FormRow>
            <FormRow>
              <FormattedMessage
                id="c52bff4f-e90f-48f1-b3e4-9e09453b9c17"
                defaultMessage="Insurance amount"
                tagName="label"
              />
              <div className={Styles.dollar}>
                <div className={Styles.symbol}>$</div>
                <TextField
                  id="policyAmount"
                  name="policyAmount"
                  automationId="policyAmount"
                  useStyledInput
                  normalize={normalizeToNumber}
                />
              </div>
              <FormGroupErrors fields={["policyAmount"]} />
            </FormRow>
            <FormGroup
              disableFormRowStyle
              fields={["policyExpiryYear", "policyExpiryMonth", "policyExpiryDay"]}
            >
              <FormattedMessage
                id="7ef85f7e-efa2-4e42-92d9-790a1880a178"
                defaultMessage="Policy expiration date"
                tagName="label"
              />
              <MultipartRow>
                <MultipartColumn width={6}>
                  <MonthField
                    name="policyExpiryMonth"
                    automationId="policyExpiryMonth"
                    useStyledInput
                    searchable={false}
                    clearable={false}
                  />
                </MultipartColumn>
                <MultipartColumn width={2}>
                  <TextField
                    name="policyExpiryDay"
                    automationId="policyExpiryDay"
                    placeholder="DD"
                    normalize={normalizeToNumber}
                    maxLength="2"
                    useStyledInput
                  />
                </MultipartColumn>
                <MultipartColumn width={4}>
                  <TextField
                    name="policyExpiryYear"
                    automationId="policyExpiryYear"
                    placeholder="YYYY"
                    normalize={normalizeToNumber}
                    maxLength="4"
                    useStyledInput
                  />
                </MultipartColumn>
              </MultipartRow>
              <FormGroupErrors
                fields={["policyExpiryDay", "policyExpiryMonth", "policyExpiryYear"]}
              />
            </FormGroup>
          </SubFormSection>

          <SubFormSection>
            <FormattedMessage
              id="309a4569-686b-4bd2-905c-dd3c18387a30"
              defaultMessage="Upload a copy of your E&O Policy"
              tagName="p"
            />
            <NotaryProfileWizardAssetUploader
              persistedValue={findDocumentLabel(
                notaryProfile.notaryDocuments,
                NotaryDocumentTypes.POLICY,
                EO_POLICY_LABEL,
              )}
              onChange={handlePolicyKeyChange}
            />
            <FormGroupErrors fields={["policyKey"]} />
          </SubFormSection>
        </SubForm>
      </div>
      {renderFooter(handleSubmit(serializeForm))}
    </>
  );
}

const detailsRequiredValidation = composeValidators(
  validatePresence({ field: "insurer", label: "E&O Insurer" }),
  validatePresence({ field: "policyAmount", label: "Insurance amount" }),
  validatePresence({ field: "policyExpiryDay", label: "Policy expiration day" }),
  validatePresence({ field: "policyAmount", label: "Insurance amount" }),
  validatePresence({ field: "policyExpiryDay", label: "Policy expiration day" }),
  validatePresence({ field: "policyExpiryMonth", label: "Policy expiration month" }),
  validatePresence({ field: "policyExpiryYear", label: "Policy expiration year" }),
  validatePresence({ field: "policyKey", label: "Proof of E&O policy" }),
  validateFutureDay({
    field: "policyExpiryYear",
    label: "Policy expiration",
    monthField: "policyExpiryMonth",
    dayField: "policyExpiryDay",
    yearField: "policyExpiryYear",
  }),
);

export default reduxForm<FormValues, Props>({
  form: "notaryProfileWizardInsuranceDetails",
  validate: (values, props) => {
    return fieldsRequired(props.user.notaryProfile!) ? detailsRequiredValidation(values) : {};
  },
})(InsuranceDetails);
