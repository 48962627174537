import { FormattedMessage, useIntl } from "react-intl";

import Tab from "common/core/tabs/tab";
import { Feature } from "graphql_globals";
import { useQuery } from "util/graphql";
import { useActiveOrganization } from "common/account/active_organization";
import { SettingsTitle } from "common/settingsv2/common";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";

import { FullAccountPaymentHistory } from "./full_payments";
import SettingsOrganizationPropertiesQuery, {
  type SettingsOrganizationProperties_node_Organization as Organization,
} from "./organization_query.graphql";

export const BILLING_RECENT_PAYMENTS_ROUTE = "recent-payments";

export function RecentPaymentsTab() {
  const [activeOrganizationId] = useActiveOrganization();
  const { data } = useQuery(SettingsOrganizationPropertiesQuery, {
    variables: { organizationId: activeOrganizationId! },
  });
  const organization = data?.node as Organization | null;

  return (
    <Tab to={BILLING_RECENT_PAYMENTS_ROUTE}>
      {organization?.featureList.includes(Feature.CUSTOM_ACCOUNT_PRICING) ? (
        <FormattedMessage id="314bddb5-905a-41dd-83a4-788e5cc5f46a" defaultMessage="Recent Fees" />
      ) : (
        <FormattedMessage
          id="4f05a3f6-0f91-4cb5-a73a-d7af3d635834"
          defaultMessage="Recent Payments"
        />
      )}
    </Tab>
  );
}

export function RecentPayments() {
  useA11y().useDocumentEntitler({
    title: useIntl().formatMessage(useDocumentTitles().settingsBillingRecentPayments),
  });
  const [activeOrganizationId] = useActiveOrganization();
  const { loading, data } = useQuery(SettingsOrganizationPropertiesQuery, {
    variables: { organizationId: activeOrganizationId! },
  });
  const organization = data?.node as Organization | null;
  const showCoveredFor = organization?.hasCoveredCharges;
  const hasCustomFees = organization?.featureList.includes(Feature.CUSTOM_ACCOUNT_PRICING);
  return (
    <>
      <SettingsTitle>
        {!loading && hasCustomFees ? (
          <FormattedMessage
            id="029430ce-6a06-47a1-80ed-93ee077fc425"
            defaultMessage="Recent Platform & Transaction Fees"
          />
        ) : (
          <FormattedMessage
            id="8056f406-1eea-43a6-b040-5ebdb679280d"
            defaultMessage="Recent Payments"
          />
        )}
      </SettingsTitle>
      <FullAccountPaymentHistory hasCustomFees showCoveredFor={showCoveredFor} />
    </>
  );
}
