import { interval, map, switchMap, distinctUntilChanged, NEVER, type Observable } from "rxjs";
import { useEffect } from "react";

import { UNAUTHORIZED_ERROR } from "errors/server";
import { captureException } from "util/exception";
import request from "util/request";
import { useBehaviorSubject } from "util/rxjs/hooks";

import { getEncodedLoginUrlWithSearchParams, useLogout } from "..";

type TimeoutAction =
  | { action: "logout" }
  | { action: "timeout_warning" | "reconnect_warning"; secondsLeft: number };
type TimeoutResponse = {
  ttl: number;
  max_ttl: number;
};
type Props = {
  isAuthenticated: boolean;
};

const INTERVAL = 30_000; // 30 seconds
const TIMEOUT_MARK = 300; // 5 minutes
const RECONNECT_MARK = 14_400; // 4 hours

function makeRequest(): Promise<TimeoutResponse> {
  return request("get", "oauth/timeout").catch((err) => {
    if (err?.type === UNAUTHORIZED_ERROR) {
      return { ttl: 0, max_ttl: 0 };
    }
    console.error(`timeout poller ${err}`); // eslint-disable-line no-console
    return NEVER;
  });
}

function makeTimeoutAction(data: TimeoutResponse): TimeoutAction | null {
  if (data.ttl === 0 || data.max_ttl === 0) {
    return { action: "logout" };
  } else if (data.max_ttl <= RECONNECT_MARK) {
    return { action: "reconnect_warning", secondsLeft: data.max_ttl };
  } else if (data.ttl <= TIMEOUT_MARK) {
    return { action: "timeout_warning", secondsLeft: data.ttl };
  }
  return null;
}

function startOrPausePoller(isAuthenticated: boolean): Observable<TimeoutResponse> {
  return isAuthenticated ? interval(INTERVAL).pipe(switchMap(makeRequest)) : NEVER;
}

export default function Timeout({ isAuthenticated }: Props) {
  const loginUrl = getEncodedLoginUrlWithSearchParams({
    redirectUrl: window.location.href.replace(window.location.origin, ""),
  });
  const logout = useLogout({ redirectUrl: loginUrl });
  const isAuthenticated$ = useBehaviorSubject(isAuthenticated);

  useEffect(() => {
    const timeouts$ = isAuthenticated$.pipe(
      distinctUntilChanged(),
      switchMap(startOrPausePoller),
      map(makeTimeoutAction),
      distinctUntilChanged((previous, current) => previous?.action === current?.action),
    );
    const sub = timeouts$.subscribe((data) => {
      if (data?.action === "logout") {
        logout().catch(captureException);
      } else {
        // TODO PLAT-4732 add timeout modal
        // TODO PLAT-4733 add reconnect modal
        console.warn(`Timeout poller data: ${data?.action} ${data?.secondsLeft}`); // eslint-disable-line no-console
      }
    });
    return () => sub.unsubscribe();
  }, []);
  useEffect(() => {
    isAuthenticated$.next(isAuthenticated);
  });

  return null;
}
