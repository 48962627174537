import { useState } from "react";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import { addYears, subDays } from "date-fns";

import { Heading, Paragraph } from "common/core/typography";
import Button from "common/core/button";
import { useForm } from "common/core/form";
import { AutomaticFormRow } from "common/core/form/layout";
import { TextInput } from "common/core/form/text";
import { isAriaInvalid, defaultRequiredMessage } from "common/core/form/error";
import { FormattedDate } from "common/core/format/date";
import { userFullName } from "util/user";
import { b } from "util/html";
import CertificateCard from "common/notary/certificate/card";

import Styles from "./index.module.scss";
import type { NotaryProfileWizardProofCertificate as User } from "../index_fragment.graphql";

type FormValues = {
  pin: string;
  pinVerify: string;
};
type Props = {
  user: User;
  onGenerateCertificate: (pin: string) => Promise<void>;
};

const MESSAGES = defineMessages({
  confirmError: {
    id: "8b14519c-16ca-45ec-a5fd-eb5c6c8a2650",
    defaultMessage: "Pins do not match",
  },
  sequentialDigitError: {
    id: "8489823f-5f90-4a44-8355-2d9443942c8c",
    defaultMessage: "Must not be sequential digits",
  },
  sameDigitError: {
    id: "702de747-d7e0-4dc3-9389-f5b8f770755f",
    defaultMessage: "Must not consist of all same digits",
  },
  fourDigitError: {
    id: "f7d2dcb7-581e-4623-bc40-70e3bd12ed2d",
    defaultMessage: "Must be four digits",
  },
  numericalError: {
    id: "dac8a73c-da1a-49ef-8ff2-2c32ad1fea65",
    defaultMessage: "Must be numerical",
  },
});

function repeatingDigitsValidation(value: string) {
  return value.length < 4 || new Set(value.split("")).size !== 1;
}

function sequentialDigitsValidation(value: string) {
  const pattern = "0123456789";
  return value.length < 4 || !pattern.includes(value);
}

function CertificateDisplay(props: { user: User; issueDate: Date }) {
  const { user } = props;
  return (
    <div className={Styles.certCardContainer}>
      <CertificateCard user={user} />
    </div>
  );
}

function NotaryCertificateCreation({ user, onGenerateCertificate }: Props) {
  const issueDate = new Date();
  const expiryDate = subDays(addYears(issueDate, 1), 1);

  const intl = useIntl();
  const form = useForm<FormValues>();

  const [isLoading, setIsLoading] = useState(false);

  const handleGenerate = async (values: FormValues) => {
    setIsLoading(true);
    await onGenerateCertificate(values.pin);
    setIsLoading(false);
  };
  return (
    <form onSubmit={form.handleSubmit(handleGenerate)} className={Styles.main}>
      <Heading textStyle="headingTwo" level="h1">
        <FormattedMessage
          id="44d77a69-80cb-4576-86ee-2de9684bd936"
          defaultMessage="Digital Certificate"
        />
      </Heading>
      <Paragraph>
        <FormattedMessage
          id="13c1a518-de7f-4dca-9d43-f60671a90c44"
          defaultMessage="Your Proof Digital Certificate is used to tamper seal documents you notarize on the platform"
        />
      </Paragraph>
      <CertificateDisplay user={user} issueDate={issueDate} />
      <Heading textStyle="headingFive" level="h2" className={Styles.noBreak}>
        <FormattedMessage
          id="8ee55768-6fb6-4c4f-94af-3c6aeb26e101"
          defaultMessage="Proof Digital Certificate"
          tagName="strong"
        />
      </Heading>
      <div className={Styles.notaryInfo}>
        <div className={Styles.infoHeading}>
          <FormattedMessage id="47de3f5a-b573-4c72-957a-8a32a90c8c13" defaultMessage="Full name" />
          <div>{userFullName(user)}</div>
        </div>

        <div className={Styles.infoHeading}>
          <FormattedMessage
            id="8f046df3-2e73-4ea0-9352-b9601c90f0a2"
            defaultMessage="Certificate type"
          />
          <div>
            <FormattedMessage id="5f99717c-e1d9-442f-9b47-c5b53935afa8" defaultMessage="Proof" />
          </div>
        </div>

        <div className={Styles.infoHeading}>
          <FormattedMessage id="598c10de-10a4-4586-b082-30e4f4952a3c" defaultMessage="Issue date" />
          <div>
            <FormattedDate value={issueDate} />
          </div>
        </div>

        <div className={Styles.infoHeading}>
          <FormattedMessage
            id="e4174f56-7675-4887-b9f8-8175f91dc9d4"
            defaultMessage="Expiration date"
          />
          <div>
            <FormattedDate value={expiryDate} />
          </div>
        </div>
      </div>

      <Heading textStyle="headingFive" level="h2">
        <FormattedMessage
          id="e654be8a-4a6f-4df0-95b1-8068da43fe73"
          defaultMessage="Create backup verification pin"
        />
      </Heading>
      <Paragraph>
        <FormattedMessage
          id="97d3226d-ca1a-4237-b7ce-35bfcd3232ac"
          defaultMessage="Create your 4-digit personal pin number. This pin may be required to manage your certificate. Make sure you don't misplace or forget your personal pin."
        />
      </Paragraph>
      <AutomaticFormRow
        className={Styles.break}
        fullWidth
        required
        label={
          <FormattedMessage
            id="86ff3e50-ff9b-451d-8d06-f13ced43b815"
            defaultMessage="Enter 4-digit pin number"
          />
        }
        helperText={{
          text: (
            <FormattedMessage
              id="d278422f-9852-477f-80ac-00e3712963b3"
              defaultMessage="Choose a pin that is easy to remember"
            />
          ),
          placement: "above",
        }}
        form={form}
        name="pin"
        registerOptions={{
          required: defaultRequiredMessage(intl),
          maxLength: 4,
          validate: {
            numerical: (value) =>
              /^\d+$/.test(value) || intl.formatMessage(MESSAGES.numericalError),
            fourDigits: (value) =>
              value.length === 4 || intl.formatMessage(MESSAGES.fourDigitError),
            sameDigits: (value) =>
              repeatingDigitsValidation(value) || intl.formatMessage(MESSAGES.sameDigitError),
            sequentialDigits: (value) =>
              sequentialDigitsValidation(value) ||
              intl.formatMessage(MESSAGES.sequentialDigitError),
          },
        }}
      >
        <TextInput
          type="password"
          inputMode="decimal"
          maxLength={4}
          aria-invalid={isAriaInvalid(form.formState.errors.pin)}
        />
      </AutomaticFormRow>
      <div className={Styles.pinInstructions}>
        <div>
          <FormattedMessage
            id="7b126f78-02ee-4cd7-acbe-a38bba634789"
            defaultMessage="Must not consist of all same digits, ex: 1111"
          />
        </div>
        <div>
          <FormattedMessage
            id="6e09bcca-dc82-4434-a6d6-27ae90b7e678"
            defaultMessage="Must not be sequential digits, ex: 1234"
          />
        </div>
      </div>
      <AutomaticFormRow
        fullWidth
        required
        label={
          <FormattedMessage
            id="4b1948f2-ce82-4da8-9cc7-1bad08ef21c1"
            defaultMessage="Confirm 4-digit pin number"
          />
        }
        form={form}
        name="pinVerify"
        registerOptions={{
          required: defaultRequiredMessage(intl),
          maxLength: 4,
          validate: (value, formValues) => {
            return value === formValues.pin || intl.formatMessage(MESSAGES.confirmError);
          },
        }}
      >
        <TextInput
          type="password"
          inputMode="decimal"
          maxLength={4}
          aria-invalid={isAriaInvalid(form.formState.errors.pinVerify)}
        />
      </AutomaticFormRow>

      <Heading textStyle="headingFive" level="h2">
        <FormattedMessage
          id="eabcef40-e30c-4579-babc-8f78fc34138c"
          defaultMessage="Payment method"
        />
      </Heading>
      <Paragraph>
        <FormattedMessage
          id="74836204-7d1a-45b3-a366-1ed15d8a953f"
          defaultMessage="Your first 12 months of a Proof Digital Certificate are free. Upon renewal, your credit card on file will be used. This can be edited in settings."
        />
      </Paragraph>

      <h3 className={Styles.paymentHeader}>
        <FormattedMessage
          id="62787e4c-f74c-4e20-8e40-6bd32699f97f"
          defaultMessage="Cost of certificate"
        />
      </h3>
      <Paragraph>
        <FormattedMessage
          id="249bac90-5350-4942-a556-83f5e13d356a"
          defaultMessage="<b>$0</b> 12 months -- expires {expiryDate}"
          values={{
            b,
            expiryDate: <FormattedDate value={expiryDate} />,
          }}
        />
      </Paragraph>

      <div className={Styles.footer}>
        <Button
          type="submit"
          buttonColor="action"
          variant="primary"
          disabled={!form.formState.isValid}
          isLoading={isLoading}
        >
          <FormattedMessage
            id="ae872354-87d2-43c7-a7ad-e1b97475a59a"
            defaultMessage="Generate certificate"
          />
        </Button>
      </div>
    </form>
  );
}

export default NotaryCertificateCreation;
